import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { IntegrationSummaryRecord } from './IntegrationSummaryRecord';

@JsonObject('IntegrationSummaryResponse')
export class IntegrationSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [IntegrationSummaryRecord])
  entries?: IntegrationSummaryRecord[] = [];
}
