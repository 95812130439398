import { getComponentOverrides } from './../_common/components/index';
import { createTheme } from '@mui/material';
import orangeColors from './palette';
import { TPaletteOverrides } from '../branding.types';
import siscaleColors from '../siscale/palette';

const orangePalette: TPaletteOverrides = {
  primary: {
    light: orangeColors.main.turquoise[300],
    main: orangeColors.main.turquoise[400],
    dark: orangeColors.main.turquoise[600],
    contrastText: orangeColors.main.navyBlue[800],
    ...orangeColors.main.turquoise
  },
  secondary: {
    light: orangeColors.main.navyBlue[700],
    main: orangeColors.main.navyBlue[800],
    dark: orangeColors.main.navyBlue[1000],
    contrastText: orangeColors.main.navyBlue[200],
    ...orangeColors.main.navyBlue
  },
  error: {
    light: orangeColors.utilitarian.red[500],
    main: orangeColors.utilitarian.red[600],
    dark: orangeColors.utilitarian.red[800],
    contrastText: orangeColors.main.navyBlue[0],
    ...orangeColors.utilitarian.red
  },
  warning: {
    light: '',
    main: '',
    dark: '',
    contrastText: '',
    ...orangeColors.utilitarian.orange
  },
  info: {
    light: '',
    main: '',
    dark: '',
    contrastText: '',
    ...orangeColors.utilitarian.blue
  },
  success: {
    light: orangeColors.utilitarian.green[400],
    main: orangeColors.utilitarian.green[500],
    dark: orangeColors.utilitarian.green[700],
    contrastText: '',
    ...orangeColors.utilitarian.green
  },
  grey: {
    A100: orangeColors.main.navyBlue[100],
    A200: orangeColors.main.navyBlue[200],
    A400: orangeColors.main.navyBlue[400],
    A700: orangeColors.main.navyBlue[700],
    50: orangeColors.main.navyBlue[100],
    ...orangeColors.main.navyBlue
  },
  background: {
    default: orangeColors.main.navyBlue[1000],
    paper: orangeColors.main.navyBlue[900]
  },
  tertiary: {
    light: siscaleColors.main.ceruleanBlue[500],
    main: siscaleColors.main.ceruleanBlue[600],
    dark: siscaleColors.main.ceruleanBlue[800],
    contrastText: siscaleColors.main.ceruleanBlue[100],
    ...siscaleColors.main.ceruleanBlue
  }
};

const muiTheme = createTheme({
  palette: orangePalette,
  typography: {
    fontFamily: 'Helvetica Neue, Arial'
  },
  components: {
    ...getComponentOverrides(orangePalette),
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Helvetica Neue";
          font-weight: 700;
          src: url("../../assets/fonts/HelveticaNeueBd.ttf") format("truetype");
        }

        @font-face {
          font-family: "Helvetica Neue";
          font-weight: 500;
          src: url("../../assets/fonts/HelveticaNeueMedium.ttf") format("truetype");
        }

        @font-face {
          font-family: "Helvetica Neue";
          font-weight: 400;
          src: url("../../assets/fonts/HelveticaNeueLight.ttf") format("truetype");
        }

        @font-face {
          font-family: "Helvetica Neue";
          font-weight: 300;
          src: url("../../assets/fonts/HelveticaNeue.ttf") format("truetype");
        }
      `
    }
  }
});

export default muiTheme;
