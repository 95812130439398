import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeedbackBulkContext } from '@arcanna/pages/Feedback/FeedbackBulkContext/useFeedbackBulkContext';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import Button from 'src/_srcMUI/stories/generic/Button/Button';
import { Stack, Tooltip } from '@mui/material';
import CircledCheckmark from 'src/components/shared/icons/CircledCheckmark';
import BackspaceIcon from 'src/components/shared/icons/BackspaceIcon';
import { showInfoNotification } from 'src/components/shared/utilities/notification';

type SelectFeedbackActionsProps = {
  editLabelsCount: number;
  selectedLabel: string;
  prepareConfirm: boolean;
  prepareClear: boolean;
  onFinish: () => void;
};

export default function FeedbackActions({
  editLabelsCount,
  selectedLabel,
  prepareConfirm,
  prepareClear,
  onFinish
}: SelectFeedbackActionsProps) {
  const { t } = useTranslation(['common', 'feedback']);
  const {
    handleCancelBulkFeedback,
    handleSaveBulkFeedback: handleConfirmBulkFeedback,
    canConfirmLabels,
    canClearLabels,
    handleLabelsClear,
    handleLabelsConfirm
  } = useFeedbackBulkContext();

  const handleSave = () => {
    handleConfirmBulkFeedback();
    onFinish();
  };

  const handleCancel = () => {
    handleCancelBulkFeedback();
    onFinish();
  };

  const handleConfirm = () => {
    handleLabelsConfirm();
    showInfoNotification(t('feedback:confirmSelected'), t('feedback:confirmSelectedInfo'));
  };

  const handleClear = () => {
    handleLabelsClear();
    showInfoNotification(t('feedback:clearSelected'), t('feedback:clearSelectedInfo'));
  };

  const disabled = (editLabelsCount === 0 || (editLabelsCount > 0 && selectedLabel == '')) && !prepareConfirm && !prepareClear;

  // eslint-disable-next-line
  const handleKeyDown = (event: KeyboardEvent) => {
    const confirmKey = (event.ctrlKey && event.key === 's') || event.key === 'Enter';
    const cancelKey = event.ctrlKey && event.key === 'x';

    if (!disabled && confirmKey) {
      handleSave();
      event.preventDefault();
    } else if (!disabled && cancelKey) {
      handleCancel();
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, [handleKeyDown]);

  return (
    <Stack direction="row" gap="60px" flexGrow={1} sx={{ marginLeft: '40px' }}>
      <Stack direction="row" gap="40px">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled={!canConfirmLabels()}
          endIcon={<CircledCheckmark />}
          onClick={() => handleConfirm()}
        >
          {t('feedback:confirmDecisionsUnified')}
        </Button>
        <Button
          size="small"
          variant="tertiary"
          endIcon={<BackspaceIcon />}
          onClick={() => handleClear()}
          disabled={!canClearLabels()}
        >
          {t('feedback:clearMyFeedback')}
        </Button>
      </Stack>

      <Stack direction="row" display="flex" gap="8px" flexGrow={1} justifyContent="start">
        <PermissionsCheck testId={'save'}>
          <Tooltip title={t('feedback:ctrlS')} placement="top">
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
              data-test-id="save-feedback-button"
            >
              <span>
                {t('common:save')} ({editLabelsCount})
              </span>
            </Button>
          </Tooltip>
        </PermissionsCheck>

        <PermissionsCheck testId={'cancel'}>
          <Tooltip title={t('feedback:ctrlX')} placement="top">
            <Button
              variant="outlined"
              disabled={disabled}
              color="secondary"
              size="small"
              onClick={handleCancel}
              data-test-id="cancel-feedback-button"
            >
              <span>{t('common:cancel')}</span>
            </Button>
          </Tooltip>
        </PermissionsCheck>
      </Stack>
    </Stack>
  );
}
