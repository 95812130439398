import useRollbackInProgress from 'src/components/pages/Main/Jobs/OverviewRevamp/JobSummary/useRollbackInProgress';
import InlineNotification from '../InlineNotification';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

function RollbackInProgressNotification({ jobId }: { jobId: number }) {
  const { isRollbackInProgress, progress } = useRollbackInProgress({ jobId });

  const { t } = useTranslation(['job']);

  const text = progress === 100 ? t('job:notifications.jobRollbackAlmostFinished') : t('job:notifications.jobRollbackInProgress');

  if (isRollbackInProgress) {
    return (
      <InlineNotification type="spinPercent" text={text} percent={progress}>
        <Typography.Text className="color-nb-300">{t('job:notifications.stayTuned')}</Typography.Text>
      </InlineNotification>
    );
  }
  return null;
}

export default RollbackInProgressNotification;
