import { SVGProps } from 'react';

export type XOutlinedIconProps = SVGProps<SVGSVGElement>;

export default function XOutlinedIcon({ ...props }: XOutlinedIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M15.8743 16.4891H15.8766C16.2558 16.4891 16.5641 16.1808 16.5641 15.8016C16.5641 15.6309 16.5007 15.4808 16.41 15.3673L16.4101 15.3673L16.4026 15.3584L13.6252 12.0469L16.4003 8.73769L16.4004 8.73778L16.4076 8.72875C16.4984 8.61527 16.5617 8.46516 16.5617 8.29453C16.5617 7.90885 16.2494 7.60703 15.8742 7.60703L15.8719 7.60704L14.3251 7.61407L14.0932 7.61512L13.9442 7.79276L12.0002 10.1108L10.0559 7.79058L9.9069 7.61278L9.67493 7.61172L8.12571 7.60469V7.60469H8.12344C7.74417 7.60469 7.43594 7.91292 7.43594 8.29219H7.43591L7.43597 8.29776C7.43774 8.45642 7.49374 8.6097 7.59468 8.73213L7.59467 8.73215L7.5976 8.73564L10.3768 12.0446L7.59749 15.3559L7.59739 15.3558L7.59003 15.365C7.49926 15.4785 7.43594 15.6286 7.43594 15.7992C7.43594 16.1849 7.74826 16.4867 8.12344 16.4867L8.12571 16.4867L9.67493 16.4797L9.90677 16.4786L10.0558 16.301L12 13.9827L13.9466 16.3034L14.0956 16.481L14.3274 16.482L15.8743 16.4891ZM2 12C2 6.47771 6.47771 2 12 2C17.5223 2 22 6.47771 22 12C22 17.5223 17.5223 22 12 22C6.47771 22 2 17.5223 2 12Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}
