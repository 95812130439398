import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('IntegrationSummaryRecord')
export class IntegrationSummaryRecord {
  @JsonProperty('id', Number)
  id: number | undefined;

  @JsonProperty('title', String)
  title: string | undefined;

  @JsonProperty('category_id', Number)
  category_id: number | undefined;

  @JsonProperty('category_caption', String)
  category_title: string | undefined;

  @JsonProperty('subcategory_id', Number)
  subcategory_id: number | undefined;

  @JsonProperty('subcategory_caption', String)
  subcategory_title: string | undefined;

  @JsonProperty('logo_base64', String)
  logo_base64: string | undefined;

  @JsonProperty('is_valid', Boolean)
  is_valid: boolean | undefined;
}
