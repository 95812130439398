/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { TFeedbackContext } from './FeedbackContext.type';
import { FeedbackQuickFilters } from 'src/components/shared/models/feedback/FeedbackQuickFilters';

export const FeedbackContext = createContext<TFeedbackContext>({
  tableSlice: {
    batchBucketDynamicColumns: [],
    paginationState: { pageIndex: 1, pageSize: 100 },
    sortingState: [
      {
        id: 'timestamp',
        desc: true
      }
    ],
    rowSelectionState: {},
    isBucketsLoading: false,
    isBucketsTableLoaded: false,
    batchBucketRows: [],
    setPageIndex: () => {},
    setPageSize: () => {},
    totalRecordsCount: 0,
    setSortingState: () => [],
    setRowSelectionState: () => {},
    triggerBucketsReload: () => {}
  },
  expandedBucketSlice: {
    expandedBucket: { data: undefined, index: undefined },
    setExpandedBucketByIndex: () => {},
    setExpandedBucketById: () => {},
    clearExpandedBucket: () => {}
  },
  filtersSlice: {
    startDate: null,
    endDate: null,
    quickFilters: new FeedbackQuickFilters(),
    decisionFilters: [],
    consensusFilters: [],
    excludeProcessed: false,
    includeProcessed: false,
    advancedFilters: {
      fieldOptions: [],
      operatorOptionsMap: {},
      addAdvancedFilter: () => {},
      activeFilters: [],
      removeAdvancedFilter: () => {},
      clearAllAdvancedFilters: () => {},
      getValueOptions: () => Promise.resolve([])
    },
    additionalFilters: [],
    setQuickFilters: () => {},
    clearQuickFilters: () => {},
    setDecisionFilters: () => {},
    clearDecisionFilters: () => {},
    setConsensusFilters: () => {},
    clearConsensusFilters: () => {},
    clearAdditionalFilters: () => {},
    handleFeedbackTimeSelection: () => {},
    filterFields: []
  },
  jobInfoSlice: {
    jobId: 0,
    jobTitle: '',
    jobInfoCustomLabels: [],
    isDIJob: false
  }
});
