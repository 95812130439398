import { Switch } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRollbackInProgress from 'src/components/pages/Main/Jobs/OverviewRevamp/JobSummary/useRollbackInProgress';
import { JobStatusAction, useJobChangeStatus } from '../../../../data-access/job/useJobChangeStatus';
import Styles from './jobStatusToggle.module.css';
import { StartedState } from '../JobState/JobState';
import { enumFromValue } from '../../utilities/enumFromValue';
import PermissionsCheck from '../Permissions/Permissions';
import { permissions } from '../../static/ComponentsPermissions';
import { useJobInfo } from '../../../../data-access';
import { JobStateEnum } from '../../models';
import { EJobTrainingState } from '../../models/job/EJobTrainingState';

type JobStatusToggleProps = {
  jobId: number;
  flexDirection?: 'column' | 'row';
  titleStyle?: React.CSSProperties;
};

export function JobStatusToggle({ jobId, flexDirection = 'row', titleStyle }: JobStatusToggleProps) {
  const jobChangeStatus = useJobChangeStatus(jobId);
  const [loading, setLoading] = useState<boolean>(true);
  const [checked, setChecked] = useState(false);
  const { isRollbackInProgress } = useRollbackInProgress({ jobId });

  const { t } = useTranslation(['job']);

  const jobInfoQuery = useJobInfo(jobId);

  useEffect(() => {
    if (jobInfoQuery.data?.info?.status?.state) {
      setLoading(false);
      setChecked(StartedState.includes(enumFromValue(jobInfoQuery.data?.info?.status?.state, JobStateEnum)));
    }
  }, [setLoading, jobInfoQuery.data?.info?.status?.state, jobInfoQuery.data?.info?.status?.retrain_state, isRollbackInProgress]);

  const toggle = useCallback(() => {
    const newValue = !checked;
    setChecked(newValue);

    if (newValue) {
      jobChangeStatus.mutateAsync(JobStatusAction.START, {
        onError: () => {
          setChecked(false);
        }
      });
      return;
    }

    jobChangeStatus.mutateAsync(JobStatusAction.STOP, {
      onError: () => {
        setChecked(true);
      }
    });
    // eslint-disable-next-line
  }, [checked, setChecked]);

  const renderStatus = useCallback(() => {
    if (checked) {
      return t('job:activeStatus');
    }
    return t('job:inactiveStatus');
    // eslint-disable-next-line
  }, [checked]);

  return (
    <div style={{ flexDirection: flexDirection }} className={Styles.jobStatusToggleContainer}>
      <span style={{ ...titleStyle }}>{t('job:statusLabel')}</span>
      <span>
        <PermissionsCheck permissions={[permissions.jobAction]} action={permissions.action.disabledAction}>
          <Switch
            checked={checked}
            onChange={toggle}
            loading={loading}
            disabled={
              jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.AUTOMATIC_RETRAIN || isRollbackInProgress
            }
            data-test-id="job-status-toggle"
          />
        </PermissionsCheck>
        <span style={{ marginLeft: 8 }} {...(checked && { className: Styles.checked })}>
          {renderStatus()}
        </span>
      </span>
    </div>
  );
}

export default JobStatusToggle;
