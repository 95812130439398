import { ReactNode, useMemo } from 'react';
import { BucketDrawerContext } from './BucketDrawerContext';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { TUseAdvancedFilters } from '@arcanna/components';

type TBucketDrawerContextProviderProps = {
  children: ReactNode;
  bucketContextValue: {
    filterFields: FeedbackFiltersFieldsRecord[];
    advancedFilters: TUseAdvancedFilters;
    triggerBucketsReload: () => void;
    expandedBucketIndex: number | undefined;
    bucketRowsCount: number;
    setExpandedBucketByIndex: (bucketIndex: number) => void;
  };
};

function BucketDrawerContextProvider({ children, bucketContextValue }: TBucketDrawerContextProviderProps) {
  const value = useMemo(() => bucketContextValue, [bucketContextValue]);

  return <BucketDrawerContext.Provider value={value}>{children}</BucketDrawerContext.Provider>;
}

export default BucketDrawerContextProvider;
