export enum IntegrationTypes {
  INPUT = 'input',
  PROCESSOR = 'processor',
  OUTPUT = 'output',
  ENRICHMENT = 'enrichment',
  CONTEXT_ENRICHMENT = 'context_enrichment',
  CASE_CREATION = 'case_creation',
  POST_DECISION = 'post_decision',
  ALERTING = 'alerting'
}
