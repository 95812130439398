import { Stack, StackProps, alpha, styled } from '@mui/material';

const StyledSpinner = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'isOverlay';
  }
})<StackProps & { isOverlay?: boolean }>(({ theme, isOverlay }) => ({
  ...(isOverlay
    ? {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        backgroundColor: alpha(theme.palette.secondary[1000] ?? theme.palette.secondary[900], 0.7),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5
      }
    : {}),

  '& .spinner__content': {
    position: 'relative',
    padding: '12px'
  },

  '& .spinner__content:before': {
    content: `""`,
    position: 'absolute',
    inset: 0,
    borderRadius: '50px',
    padding: '4px',
    background: `conic-gradient(from 180deg at 50%, rgba(100, 112, 131, 0) 0deg, ${theme.palette.secondary[400]} 360deg)`,
    mask: `linear-gradient(${theme.palette.common.white} 0 0) content-box,
      linear-gradient(${theme.palette.common.white} 0 0)`,
    maskComposite: 'exclude',
    animationName: 'spin',
    animationDuration: '800ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  },

  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  }
}));

export default StyledSpinner;
