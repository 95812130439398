import styles from './styles.module.css';
import cx from 'classnames';
import ExclamationOutlinedIcon from '../../../../themes/icons/ExclamationOutlinedIcon';
import XOutlinedIcon from '../../../../themes/icons/XOutlinedIcon';
import { Typography } from 'antd';
import { ReactNode, useState } from 'react';
import { JobCollectingDataSpinner } from '../JobState/Tags/JobCollectingDataSpinner/JobCollectingDataSpinner';
import SuccessOutlinedIcon from '../../../../themes/icons/SuccessOutlinedIcon';
import { CloseOutlined } from '@ant-design/icons';

export type NotificationColor = 'red' | 'blue' | 'green';

export type NotificationType = 'info' | 'error' | 'spin' | 'success' | 'spinPercent';

export type NotificationDirectionType = 'row' | 'column';

type InlineNotificationProps = {
  color?: NotificationColor;
  type?: NotificationType;
  text: string;
  children?: ReactNode;
  percent?: number;
  flexDirection?: NotificationDirectionType;
  closable?: boolean;
};

export default function InlineNotification({
  color = 'blue',
  type = 'info',
  text,
  children,
  percent,
  flexDirection = 'row',
  closable = false
}: InlineNotificationProps) {
  const typeIcon = {
    info: <ExclamationOutlinedIcon />,
    error: <XOutlinedIcon />,
    spin: <JobCollectingDataSpinner centerStyle={{ width: '12px', height: '12px' }} />,
    success: <SuccessOutlinedIcon width={24} height={24} />,
    spinPercent: (
      <>
        <JobCollectingDataSpinner centerStyle={{ width: '12px', height: '12px' }} />
        <span className="color-nb-100">{percent}%</span>
      </>
    )
  };
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const onClose = () => {
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && (
        <div
          className={cx(
            styles[`notif--${color}`],
            styles.inlineNotif,
            styles[`inlineNotif--${flexDirection}`],
            styles[`notif-border--${color}`]
          )}
        >
          <div className={styles[`wrapper--${flexDirection}`]}>
            {typeIcon[type]}
            <div className={cx(styles[`notifHeader--${flexDirection}`])}>
              <Typography.Text className={styles.notifText}>{text}</Typography.Text>
              {children}
            </div>
          </div>
          {closable && <CloseOutlined className={styles.closeIcon} onClick={() => onClose()} />}
        </div>
      )}
    </>
  );
}
