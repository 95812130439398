import { Stack, StackProps, styled } from '@mui/material';

const StyledCollapsible = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'size' && propName !== 'hasBorder' && propName !== 'isExpanded';
  }
})<
  StackProps & {
    size: 'small' | 'large';
    hasBorder: boolean;
    isExpanded: boolean;
  }
>(({ size, hasBorder, isExpanded, theme }) => ({
  ...(hasBorder
    ? {
        border: `1px solid ${theme.palette.secondary[700]}`,

        '& .collapsible-heading': {
          borderBottom: `1px solid ${theme.palette.secondary[700]}`,
          marginBottom: isExpanded ? '0' : '-1px'
        }
      }
    : {}),

  '& > .collapsible-heading > .collapsible-trigger': {
    minWidth: 'unset',
    padding: 0,
    ...(size === 'small'
      ? {
          height: '20px',
          width: '20px'
        }
      : {
          height: '24px',
          width: '24px'
        }),

    ...(hasBorder
      ? {
          marginLeft: '8px'
        }
      : {}),

    '& > svg': {
      ...(size === 'small'
        ? {
            height: '14px',
            width: '14px'
          }
        : {
            height: '16px',
            width: '16px'
          })
    }
  }
}));

export default StyledCollapsible;
