import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { JobStatusChangeRequest } from '../../components/shared/models/job/JobStatusChangeRequest';
import axios, { AxiosResponse } from 'axios';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { config } from '../../config';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';

export function useStopJob() {
  const { t } = useTranslation(['job', 'common']);
  const queryClient = useQueryClient();
  return useMutation(
    (payload: JobStatusChangeRequest) => axios.post<{ resource: { request: CommonResponse } }>(config.api.stopJob, payload),
    {
      onSuccess: (result) => {
        if (!result.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('job:stoppedError'));
          return;
        }
        showSuccessNotification(t('common:actionCompleted'), t('job:stoppedSuccessfully'));
      },
      onError: (error: { response: AxiosResponse<{ resource: { request: CommonResponse } }> }) => {
        showErrorNotification(t('common:actionCompleted'), t('job:stoppedError') + error.response.data.resource.request.reason);
      },
      onSettled: () => {
        queryClient.invalidateQueries([config.api.jobsSummary]);
      }
    }
  );
}
