import { CustomLabel } from '@arcanna/components';
import { Space, Row, Col } from 'antd';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { getColumnValue } from 'src/components/shared/utilities/table';
import { noDecisionResult, undecidedConsensus } from '../../../helper';
import { CustomLabel as CustomLabelType } from './../../../../../../shared/models';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { Stack } from '@mui/material';
import BucketTrainingStatusTag from '../BucketTrainingStatusTag';
import { enumFromValue } from 'src/components/shared/utilities/enumFromValue';
import { BucketTrainingStatus } from 'src/constants/BucketTrainingStatus';
import { ExpandIcon, UnexpandIcon } from 'src/themes/icons';
import { RequireAttention } from '@arcanna/components';

function findLabelIdInJobs(jobInfosData: JobInfoResponse[], labelId: string | undefined) {
  for (const jobInfoData of jobInfosData) {
    const label = jobInfoData.info?.advancedSettings.customLabels.find(
      (customLabel: CustomLabelType) => customLabel.id === labelId
    );
    if (label !== undefined) {
      return label;
    }
  }
  return null;
}

export function labelRender(
  jobInfosData: JobInfoResponse[],
  editLabels: Map<string, string>,
  selectedRowKeys?: string[],
  prepareConfirm?: boolean,
  prepareClear?: boolean
) {
  return (item: FeedbackBatchBucketRow) => {
    const isSelectedRow = item.row_id != null ? selectedRowKeys?.includes(item.row_id) : false;
    const selectedLabelId = item.row_id != null ? editLabels.get(item.row_id) : undefined;
    const labelId = getColumnValue(item, 'label');
    const consensus = getColumnValue(item, 'consensus');

    // Determine newLabel
    let newLabel: string | undefined;
    if (prepareConfirm && isSelectedRow) {
      newLabel = labelId;
    } else if (prepareClear && isSelectedRow) {
      newLabel = undefined;
    } else {
      newLabel = selectedLabelId || consensus;
    }

    const hasConsensusOverwritten = item.consensusOverwritten?.consensusOverwrittenBy != null;

    let oldValue: CustomLabelType | null = findLabelIdInJobs(jobInfosData, labelId);

    if (oldValue == null) {
      oldValue = noDecisionResult;
    }

    let newValue: CustomLabelType | null;
    if (newLabel === undecidedConsensus.id) {
      newValue = undecidedConsensus;
    } else {
      newValue = findLabelIdInJobs(jobInfosData, newLabel);
    }

    const hasNewValue = newValue && newValue !== oldValue && newValue !== undefined && newValue !== null;

    const isFeedbackSelected = (Boolean(newValue) && newValue !== oldValue) || (prepareConfirm && selectedLabelId !== undefined);

    return oldValue ? (
      <div className="flex flex-col" style={{ height: '100%', alignItems: 'flex-start' }}>
        <Space direction={'vertical'}>
          <Row>
            <Col>
              <CustomLabel
                name={oldValue.name}
                dataTestId="label-column-value"
                hexColor={oldValue.hexColor}
                disabled={isFeedbackSelected}
                strike={isFeedbackSelected}
                fontSize="12px"
                disableColorCircle={oldValue === noDecisionResult || oldValue.name === undecidedConsensus.name}
                isConfirmed={newValue === oldValue}
                hasConsensusOverwritten={hasConsensusOverwritten && newValue == oldValue}
              />
            </Col>
          </Row>
          {hasNewValue ? (
            <CustomLabel
              name={newValue?.name || ''}
              dataTestId="new-label-column-value"
              disableColorCircle={newValue === undecidedConsensus}
              hexColor={newValue?.hexColor || ''}
              fontSize="12px"
              hasConsensusOverwritten={hasConsensusOverwritten}
            />
          ) : null}
        </Space>
      </div>
    ) : null;
  };
}

export function trainingStatusRender(jobInfos: JobInfoResponse[]) {
  return (item: FeedbackBatchBucketRow) => {
    if (jobInfos.length == 0) {
      return <></>;
    }
    const columnPairs: Map<string, string> = new Map<string, string>();
    if (item.columns != undefined) {
      for (const column of item.columns) {
        if (column.name != undefined && column.value != undefined) {
          columnPairs.set(column.name, column.value);
        }
      }
    }
    const currentJobInfo: JobInfoResponse =
      jobInfos.find((jobInfo) => jobInfo.info?.id == columnPairs.get('job_id')) || jobInfos[0];
    const trainingStatus: string = columnPairs.get('training_status') || BucketTrainingStatus.NEW.toString();
    const trainingLabelId = columnPairs.get('training_label');
    const confidenceScore = columnPairs.get('confidence_score');
    const outlier = columnPairs.get('outlier');
    const lowConsensusScore = columnPairs.get('low_consensus_score');
    const consensusFlipping = columnPairs.get('consensus_flipping');
    const consensusChangedFromLastRetrain = columnPairs.get('consensus_changed_from_last_retrain');

    return (
      <Space>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap="2px">
          <BucketTrainingStatusTag
            trainingStatus={trainingStatus ? enumFromValue(trainingStatus, BucketTrainingStatus) : null}
            trainedWith={
              currentJobInfo.info?.advancedSettings.customLabels.find((customLabel) => customLabel.id === trainingLabelId)
                ?.name || ''
            }
            style={{ marginRight: 0 }}
          />
          {trainingStatus && enumFromValue(trainingStatus, BucketTrainingStatus) === BucketTrainingStatus.NEW && (
            <RequireAttention
              confidenceScore={Math.round(Number(confidenceScore))}
              isOutlier={outlier === 'true'}
              isConsensusScoreLow={lowConsensusScore === 'True'}
              hasConsensusChangedFromLastRetrain={consensusChangedFromLastRetrain === 'True'}
              isConsensusFlipping={consensusFlipping === 'True'}
            />
          )}
        </Stack>
      </Space>
    );
  };
}

export function expandColumnRender(
  expandedBucketRow: FeedbackBatchBucketRow | undefined,
  setExpandedBucketRow: (bucketRow: FeedbackBatchBucketRow | undefined) => void
) {
  return (bucketRow: FeedbackBatchBucketRow) => (
    <span
      className="body-1 pointer"
      onClick={() =>
        expandedBucketRow?.row_id === bucketRow.row_id ? setExpandedBucketRow(undefined) : setExpandedBucketRow(bucketRow)
      }
    >
      {bucketRow.row_id === expandedBucketRow?.row_id ? (
        <UnexpandIcon width={20} height={20} />
      ) : (
        <ExpandIcon width={20} height={20} />
      )}
    </span>
  );
}
