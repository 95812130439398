import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { HeaderGroup, Table } from '@tanstack/react-table';
import TableHeaderCell from '../TableHeaderCell';
import { TableRow as MuiTableRow, TableCell as MuiTableCell } from '@mui/material';
import { Checkbox } from '@arcanna/generic';
import { Stack } from '@mui/material';
import TableColumnsSelector from '../TableColumnsSelector';
import TableSizeSelector from '../TableSizeSelector';
import StyledTableHead from './StyledTableHead.styles';

type TTableHeadProps<T> = {
  tableInstance: Table<T>;
  columnOrder?: string[];
  tableSize: string;
  handleTableSizeChange: (tableSize: string) => void;
  isTableResizable: boolean;
  isActionsColumnVisible: boolean;
  customTableSizes?: string[];
  actionsColumnSize: number;
};
function TableHead<T>({
  isActionsColumnVisible,
  isTableResizable,
  tableInstance,
  columnOrder,
  tableSize,
  customTableSizes,
  handleTableSizeChange,
  actionsColumnSize
}: TTableHeadProps<T>) {
  const getCheckboxState = () => {
    if (tableInstance.getIsAllPageRowsSelected()) {
      return 'checked';
    }

    if (tableInstance.getIsSomePageRowsSelected()) {
      return 'indeterminate';
    }

    return 'default';
  };

  const renderTableRowContent = (headerGroup: HeaderGroup<T>) => (
    <>
      {isActionsColumnVisible && (
        <MuiTableCell
          component="th"
          className="table-head-cell"
          style={{ width: `${actionsColumnSize}px`, maxWidth: `${actionsColumnSize}px` }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            {tableInstance.options.enableMultiRowSelection && (
              <Checkbox
                state={getCheckboxState()}
                onChange={(_, event) => {
                  tableInstance.getToggleAllPageRowsSelectedHandler()(event);
                }}
                sx={{ marginRight: 0, marginLeft: 0 }}
              />
            )}

            {tableInstance.options.enableHiding && <TableColumnsSelector<T> tableInstance={tableInstance} />}

            {isTableResizable && (
              <TableSizeSelector
                tableSize={tableSize}
                handleTableSizeChange={handleTableSizeChange}
                customTableSizes={customTableSizes}
              />
            )}
          </Stack>
        </MuiTableCell>
      )}

      {headerGroup.headers.map((header) => (
        <TableHeaderCell<T>
          key={header.id}
          headerCell={header}
          actionsColumnSize={actionsColumnSize}
          enableColumnSorting={Boolean(columnOrder)}
        />
      ))}
    </>
  );

  return (
    <StyledTableHead actionsColumnSize={actionsColumnSize}>
      {tableInstance.getHeaderGroups().map((headerGroup) => (
        <MuiTableRow key={headerGroup.id} className="table-head-row">
          {columnOrder ? (
            <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
              {renderTableRowContent(headerGroup)}
            </SortableContext>
          ) : (
            renderTableRowContent(headerGroup)
          )}
        </MuiTableRow>
      ))}
    </StyledTableHead>
  );
}

export default TableHead;
