import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import Button from '../../../../../../shared/components/Button/Button';
import InlineNotification from '../../../../../../shared/components/InlineNotification';
import StartIcon from '../../../../../../../themes/icons/StartIcon';
import DecisionPointsButton from '../../../Feedback/Flow/DecisionPointsButton';
import { useStartJob, useJobStatus } from '../../../../../../../data-access';
import { JobStatusChangeRequest } from '../../../../../../shared/models/job/JobStatusChangeRequest';
import { enumFromValue } from '../../../../../../shared/utilities/enumFromValue';
import { JobStateEnum } from '../../../../../../shared/models';
import { permissions } from '../../../../../../shared/static/ComponentsPermissions';
import PermissionsCheck from '../../../../../../shared/components/Permissions/Permissions';
import { StartNotificationType } from '../hooks/useEstablishStartNotificationType';
import { StartedState } from '../../../../../../shared/components/JobState/JobState';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

type TJobStartNotificationProps = {
  jobId: number;
  fromPage?: string;
  notificationType?: StartNotificationType;
};
export default function JobStartNotification({ jobId, fromPage, notificationType }: TJobStartNotificationProps) {
  const { t } = useTranslation(['feedback', 'job']);

  const [isBusy, setIsBusy] = useState(false);

  const jobStatus = useJobStatus(jobId);

  const jobState = jobStatus?.data?.state;
  const cloningJob = jobState ? enumFromValue(jobState, JobStateEnum) === JobStateEnum.CLONING : false;

  const jobStartMutation = useStartJob();
  const onStartJob = async () => {
    setIsBusy(true);
    await jobStartMutation.mutateAsync(new JobStatusChangeRequest(jobId));
  };

  useEffect(() => {
    if (jobStatus?.data?.state && StartedState.includes(enumFromValue(jobState, JobStateEnum))) {
      setIsBusy(false);
    }
    // eslint-disable-next-line
  }, [jobStatus?.data?.state]);

  switch (notificationType) {
    case StartNotificationType.JOB_NOT_STARTED: {
      return (
        <InlineNotification type={'info'} color={'blue'} text={t('job:notifications:noEventsIngested')}>
          <Typography.Text className="color-nb-300">Click</Typography.Text>
          <Typography.Text className="color-nb-100">{t('job:startJob')}</Typography.Text>
          <Typography.Text className="color-nb-300">to ingest events.</Typography.Text>
          <Button
            size="small"
            iconPosition="right"
            icon={isBusy ? <LoadingOutlined /> : <StartIcon fill="var(--secondary-color-nb-1000)" />}
            text={t('job:startJob')}
            onClick={() => onStartJob()}
            disabled={cloningJob || isBusy}
          />
        </InlineNotification>
      );
    }
    case StartNotificationType.COLLECTING_DATA: {
      return <InlineNotification type={'spin'} color={'blue'} text={t('job:jobState:collectingData')} />;
    }
    case StartNotificationType.NO_DECISION_POINTS: {
      return (
        <InlineNotification type={'info'} color={'blue'} text={t('job:notifications:noDecisionPoints')}>
          <Typography.Text className="color-nb-300">{t('job:notifications:selectDecisionPoints')}</Typography.Text>
          <Typography.Text className="color-nb-100">{t('feedback:decisionPointsButtonLabel')}</Typography.Text>
          <PermissionsCheck permissions={[permissions.jobAction]} action={permissions.action.disabledAction}>
            <DecisionPointsButton jobId={jobId} fromPage={fromPage} />
          </PermissionsCheck>
        </InlineNotification>
      );
    }
    default: {
      return <></>;
    }
  }
}
