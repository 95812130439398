import { useEffect, useState } from 'react';
import { useJobStatus } from '../../../../../../../data-access';
import { enumFromValue } from '../../../../../../shared/utilities/enumFromValue';
import { JobStateEnum } from '../../../../../../shared/models';
import { StartedState } from '../../../../../../shared/components/JobState/JobState';
import { useTranslation } from 'react-i18next';

export enum StartNotificationType {
  NONE = 0,
  JOB_NOT_STARTED = 1,
  COLLECTING_DATA = 2,
  NO_DECISION_POINTS = 3
}

export function useEstablishStartNotificationType(jobId: number, fromPage: string) {
  const [notificationType, setNotificationType] = useState(StartNotificationType.NONE);
  const [showContent, setShowContent] = useState(false);

  const { t } = useTranslation(['feedback', 'job']);

  const jobStatus = useJobStatus(jobId);
  const jobState = jobStatus?.data?.state;
  const stoppedJob = jobState ? !StartedState.includes(enumFromValue(jobState, JobStateEnum)) : true;
  const invalidJob = jobStatus?.data?.invalid ? jobStatus.data.invalid : false;

  useEffect(
    function establishNotificationType() {
      let notification = StartNotificationType.NONE;
      let showPageContent = true;

      if (jobStatus?.data?.processedDocumentsCount != null && !jobStatus.isLoading) {
        if (jobStatus?.data?.processedDocumentsCount === 0) {
          if (stoppedJob) {
            notification = StartNotificationType.JOB_NOT_STARTED;
            showPageContent = false;
          } else {
            notification = StartNotificationType.COLLECTING_DATA;
            showPageContent = false;
          }
          if (invalidJob) {
            notification = StartNotificationType.NONE;
          }
        } else if (!jobStatus?.data?.has_features_selected) {
          if (invalidJob) {
            notification = StartNotificationType.NONE;
            showPageContent = fromPage === t('job:navigation.explorer');
          } else if (fromPage === t('job:navigation.decisionPoints')) {
            notification = StartNotificationType.NONE;
          } else {
            notification = StartNotificationType.NO_DECISION_POINTS;
            if (fromPage === t('job:navigation.feedback') || fromPage === t('job:navigation.train')) {
              showPageContent = false;
            }
          }
        }
      } else {
        showPageContent = false;
      }
      setNotificationType(notification);
      setShowContent(showPageContent);
    },
    // eslint-disable-next-line
    [jobStatus?.data, jobStatus.isLoading]
  );

  return { notificationType, showContent };
}
