import { ReactNode, useState } from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FeedbackTimeSelectorOption, useFeedbackFlowHook } from '../FeedbackFlow.hook';
import { CalendarIcon } from 'src/themes/icons';
import { useFeedbackFlowContext } from '../FeedbackFlow.context';
import { useJobContext } from '../../../Job.context';
import DecisionPointsButton from '../DecisionPointsButton';
import FeedbackFlowQuickFilters from '../FeedbackFlowQuickFilters';
import FeedbackFlowConsensusDecisionFilters from '../FeedbackFlowConsensusDecisionFilters';
import { enumFromValue } from 'src/components/shared/utilities/enumFromValue';
import { AdvancedFilters, RefreshButton } from '@arcanna/components';
import { Icon, EIcon } from '@arcanna/generic';
import { Stack, Button } from '@mui/material';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';

export type TFeedbackFlowNavigationProps = {
  jobInfos: JobInfoResponse[];
  hideShrinkExpand?: boolean;
  hideDecisionPoints?: boolean;
  extraFilters?: ReactNode;
  children?: ReactNode;
};

export function FeedbackFlowNavigation({
  jobInfos,
  hideShrinkExpand,
  hideDecisionPoints,
  extraFilters,
  children
}: TFeedbackFlowNavigationProps) {
  const {
    state: feedbackFlowState,
    setTableStyle,
    additionalFilters,
    clearAdditionalFilters,
    setQuickFilters,
    setDecisionFilters,
    setConsensusFilters
  } = useFeedbackFlowContext();
  const { onReload, handleFeedbackTimeSelection, advancedFilters } = useFeedbackFlowHook();

  const { state: jobState } = useJobContext();

  const [feedbackTimeSelectorOption, setFeedbackTimeSelectorOption] = useState<FeedbackTimeSelectorOption>(
    FeedbackTimeSelectorOption.ALL_TIME
  );

  const feedbackTimeSelectorOnChange = (value: string) => {
    setFeedbackTimeSelectorOption(enumFromValue(value, FeedbackTimeSelectorOption));
    handleFeedbackTimeSelection(enumFromValue(value, FeedbackTimeSelectorOption));
  };

  const { t } = useTranslation(['common', 'job', 'feedback']);

  return (
    <Stack direction="column" marginBottom={2} gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
          <Input.Group size="small" compact style={{ display: 'flex', width: '180px' }}>
            <Input
              style={{
                width: '42px',
                pointerEvents: 'none',
                backgroundColor: 'var(--secondary-color-nb-800)',
                color: 'var(--secondary-color-nb-200)'
              }}
              prefix={<CalendarIcon />}
              disabled
              size="small"
            />
            <Select
              value={feedbackTimeSelectorOption}
              style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', width: '100%' }}
              options={[
                {
                  value: FeedbackTimeSelectorOption.ALL_TIME,
                  label: t('feedback:timeSelectorOptions.allTime'),
                  ['data-testid']: 'filter-option-all'
                },
                {
                  value: FeedbackTimeSelectorOption.LAST_30_DAYS,
                  label: t('feedback:timeSelectorOptions.last30Days'),
                  ['data-testid']: 'filter-option-30-days'
                },
                {
                  value: FeedbackTimeSelectorOption.LAST_7_DAYS,
                  label: t('feedback:timeSelectorOptions.last7Days'),
                  ['data-testid']: 'filter-option-7-days'
                },
                {
                  value: FeedbackTimeSelectorOption.LAST_24_HOURS,
                  label: t('feedback:timeSelectorOptions.last24h'),
                  ['data-testid']: 'filter-option-24h'
                }
              ]}
              size="small"
              onChange={feedbackTimeSelectorOnChange}
            />
          </Input.Group>
          {extraFilters}
          {!hideShrinkExpand && (
            <Button
              disabled={feedbackFlowState.tableData.length === 0}
              variant="contained"
              color="secondary"
              size="small"
              endIcon={
                feedbackFlowState.tableStyle === 'expanded' ? <Icon name={EIcon.Minimize01} /> : <Icon name={EIcon.Expand01} />
              }
              onClick={() => setTableStyle(feedbackFlowState.tableStyle === 'expanded' ? 'shrinked' : 'expanded')}
            >
              {feedbackFlowState.tableStyle === 'expanded' ? t('feedback:shrinkTable') : t('feedback:expandTable')}
            </Button>
          )}
          <FeedbackFlowQuickFilters quickFiltersState={feedbackFlowState.quickFilters} setQuickFilters={setQuickFilters} />
          <FeedbackFlowConsensusDecisionFilters
            jobInfos={jobInfos}
            decisionFilters={feedbackFlowState.decisionFilters}
            consensusFilters={feedbackFlowState.consensusFilters}
            setDecisionFilters={setDecisionFilters}
            setConsensusFilters={setConsensusFilters}
          />
          <AdvancedFilters.Button
            fieldOptions={advancedFilters.fieldOptions}
            operatorOptionsMap={advancedFilters.operatorOptionsMap}
            addAdvancedFilter={advancedFilters.addAdvancedFilter}
            getValueOptions={advancedFilters.getValueOptions}
          />
        </Stack>
        {children}
        {!hideDecisionPoints && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end" flexGrow={1} gap={1}>
            {jobState.selectedJob && jobState.selectedJob.id != undefined && (
              <DecisionPointsButton jobId={jobState.selectedJob.id} fromPage={t('job:navigation.feedback')} />
            )}
          </Stack>
        )}
        <Stack direction="row" paddingLeft="8px">
          <RefreshButton onRefresh={() => onReload()} />
        </Stack>
      </Stack>
      <AdvancedFilters.List
        filters={advancedFilters.activeFilters}
        fieldOptions={advancedFilters.fieldOptions}
        operatorOptionsMap={advancedFilters.operatorOptionsMap}
        onDelete={advancedFilters.removeAdvancedFilter}
        onUpdate={advancedFilters.addAdvancedFilter}
        onClearAll={() => {
          advancedFilters.clearAllAdvancedFilters();
          clearAdditionalFilters();
        }}
        getValueOptions={advancedFilters.getValueOptions}
        additionalFilters={additionalFilters}
      />
    </Stack>
  );
}
