import { withTransaction } from '@elastic/apm-rum-react';
import FeedbackUnified from './FeedbackUnified';
import { FeedbackUnifiedProvider } from './FeedbackUnified.context';

function FeedbackUnifiedWrapper() {
  return (
    <FeedbackUnifiedProvider>
      <FeedbackUnified />
    </FeedbackUnifiedProvider>
  );
}

export default withTransaction('FeedbackUnifiedWrapper', 'component')(FeedbackUnifiedWrapper);
