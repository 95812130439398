import * as React from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { JsonConvert } from 'json2typescript';
import HttpStatusCode from 'http-status-codes';
import { useHistory } from 'react-router-dom';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';

export function fetchJobInfo(jobId: number, jsonConvert: JsonConvert) {
  return axios
    .post<{ resource: JobInfoResponse }>(config.api.jobInfo.replace(':id', `${jobId}`), new CommonRequest())
    .then((responseData: AxiosResponse<{ resource: JobInfoResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, JobInfoResponse)
    );
}

export function useJobInfo(jobId: number | undefined, refetchIntervalMillis: number | false = false) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const history = useHistory();

  return useQuery<JobInfoResponse, AxiosError>(
    [config.api.jobInfo.replace(':id', `${jobId}`)],
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
    () => fetchJobInfo(jobId, jsonConvert),
    {
      enabled: Boolean(jobId),
      refetchInterval: () => refetchIntervalMillis,
      onError: (err: AxiosError) => {
        if (err.response?.status === HttpStatusCode.NOT_FOUND) {
          history.push(config.routes.page404);
        }
      }
    }
  );
}
