import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('IntegrationSummaryRequest')
export class IntegrationSummaryRequest extends CommonRequest {
  @JsonProperty('event_count', Number)
  event_count?: number = undefined;

  @JsonProperty('include_internal', Boolean)
  include_internal?: boolean = undefined;

  @JsonProperty('job_category_id', Number)
  job_category_id?: number = undefined;

  constructor(eventCount: number, include_internal?: boolean, job_category_id?: number) {
    super();
    this.event_count = eventCount;
    this.include_internal = include_internal;
    this.job_category_id = job_category_id;
  }
}
