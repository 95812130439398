import { StateChip } from '@arcanna/generic';
import { Stack, useTheme } from '@mui/material';
import { FeedbackBatchColumnRecord } from 'src/components/shared/models/feedback/FeedbackBatchColumnRecord';
import StyledDecisionPoint from './StyledDecisionPoint.styles';

export function DecisionPoint({ decisionPoint }: { decisionPoint: FeedbackBatchColumnRecord }) {
  const { palette } = useTheme();
  return (
    <StyledDecisionPoint>
      <Stack direction="row">
        <StateChip color={palette.info[500]} text={decisionPoint.name || ''} size="medium"></StateChip>
        &nbsp;
        <Stack direction="column" justifyContent="center">
          {decisionPoint.value}
        </Stack>
      </Stack>
    </StyledDecisionPoint>
  );
}
