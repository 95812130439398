import { ComboBox, TOption } from '@arcanna/generic';
import { AutocompleteRenderInputParams, Stack, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import { showWarningNotification } from 'src/components/shared/utilities/notification';
import { useJobsSummary } from 'src/data-access';
import { JobCategory } from '../../../helper';

type JobComboBoxProps = {
  initialJobIds: number[];
  onSelectionChange: (jobIds: string[]) => void;
};

export default function JobComboBox({ initialJobIds, onSelectionChange: onSelectionChangeHandler }: JobComboBoxProps) {
  const { t } = useTranslation(['feedback']);
  const [selectedJobs, setSelectedJobs] = useState<TOption[]>([]);
  const jobsQuery = useJobsSummary({ categoryId: JobCategory.DECISION_INTELLIGENCE, tablePageSize: 1000 });

  const selectedJobsLabel = useMemo(
    () => t('feedback:selectedJobs').replace('NUMBER_OF_JOBS', selectedJobs.length.toString()),
    [t, selectedJobs]
  );

  const options: TOption[] = useMemo(() => {
    const result =
      jobsQuery.data?.entries?.map((jobSummary: JobSummaryRecord) => ({
        value: jobSummary.id?.toString() || '',
        label: jobSummary.title || ''
      })) || [];
    result.sort((option1, options2) => option1.label.localeCompare(options2.label));

    return result;
  }, [jobsQuery.data]);

  const onSelectionChange = (selection: TOption[]) => {
    setSelectedJobs(selection);
    if (selection.length > 0) {
      onSelectionChangeHandler(selection.map((option: TOption) => option.value));
    } else {
      showWarningNotification(t('feedback:selectedJobsWarning.title'), t('feedback:selectedJobsWarning.description'));
    }
  };

  useEffect(() => {
    if (selectedJobs.length == 0 && initialJobIds.length > 0) {
      setSelectedJobs(options.filter((option) => initialJobIds.includes(Number(option.value))));
    }
  }, [selectedJobs, setSelectedJobs, initialJobIds, options]);

  return (
    <Stack direction="row" sx={{ width: '280px' }}>
      <ComboBox
        options={options}
        required={false}
        disabled={false}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            placeholder={selectedJobs.length == 0 ? selectedJobsLabel : ''}
            sx={{
              '.MuiAutocomplete-inputRoot': {
                paddingY: '0px',
                minWidth: '280px',
                input: {
                  caretColor: 'transparent'
                }
              }
            }}
          />
        )}
        renderTags={() => <span>{selectedJobsLabel}</span>}
        onChange={(_, newValue) => {
          onSelectionChange(newValue);
        }}
        value={selectedJobs}
      />
    </Stack>
  );
}
