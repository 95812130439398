export function NextAngleRightIcon() {
  return (
    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line
        d="M5.31055 4.35352C5.4375 4.22656 5.4375 4.04883 5.31055 3.92188L1.60352 0.164062C1.47656 0.0371094 1.27344 0.0371094 1.17188 0.164062L0.664062 0.671875C0.537109 0.773438 0.537109 0.976562 0.664062 1.10352L3.66016 4.125L0.664062 7.17188C0.537109 7.29883 0.537109 7.47656 0.664062 7.60352L1.17188 8.11133C1.27344 8.23828 1.47656 8.23828 1.60352 8.11133L5.31055 4.35352Z"
        fill="#465064"
      />
    </svg>
  );
}
