import { Button, Collapse, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Icon, EIcon } from '@arcanna/generic';
import StyledCollapsible from './StyledCollapsible.styles';

type TCollapsibleProps = {
  children: ReactNode;
  heading: ReactNode;
  size: 'small' | 'large';
  isForcedExpanded?: boolean;
  isClickOnHeaderDisabled?: boolean;
  buttonPosition?: 'right' | 'left';
  hasBorder?: boolean;
};

function Collapsible({
  children,
  heading,
  size = 'small',
  isForcedExpanded,
  isClickOnHeaderDisabled,
  buttonPosition = 'right',
  hasBorder
}: TCollapsibleProps) {
  const [isExpandedState, setIsExpanded] = useState<boolean>(false);

  const isExpanded = isForcedExpanded || isExpandedState;
  const renderTriggerButton = () => (
    <Button
      color="secondary"
      variant={isExpanded ? 'containedHover' : 'outlined'}
      size="small"
      className="collapsible-trigger"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <Icon name={isExpanded ? EIcon.ChevronDown : EIcon.ChevronUp} fontSize="small" />
    </Button>
  );

  return (
    // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty...
    <StyledCollapsible size={size} hasBorder={hasBorder} isExpanded={isExpanded}>
      <Stack
        direction="row"
        gap={hasBorder ? 1 : 2}
        alignItems="center"
        marginBottom={isExpanded ? 0.5 : 0}
        onClick={() => !isClickOnHeaderDisabled && setIsExpanded(!isExpanded)}
        // @ts-expect-error TS(2769): No overload matches this call.
        sx={!isClickOnHeaderDisabled && { cursor: 'pointer' }}
        className="collapsible-heading"
      >
        {buttonPosition === 'left' && renderTriggerButton()}
        {heading}
        {buttonPosition === 'right' && renderTriggerButton()}
      </Stack>
      <Collapse in={isExpanded}>{children}</Collapse>
    </StyledCollapsible>
  );
}

export default Collapsible;
