import Joi from 'joi';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { TFunction } from 'react-i18next';
import { IntegrationJobParameterRecord } from 'src/components/shared/models/integration/job/IntegrationJobParameterRecord';

const getFormParameterName = (parameter: IntegrationJobParameterRecord, formSectionName?: string) =>
  formSectionName ? `${formSectionName}.${parameter.field}` : parameter.field ?? '';

const getDependencyValue = (value: string) => {
  return ['true', 'false'].includes(value) ? value === 'true' : value;
};

const getSmartGeneratedFieldSchema = (parameter: IntegrationJobParameterRecord, t: TFunction) => {
  if (parameter.type === 'string' || parameter.type === 'text' || parameter.type === 'password') {
    if (parameter.dependency_field && parameter.required) {
      return Joi.string().when(Joi.ref(parameter.dependency_field), {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        is: getDependencyValue(parameter.dependency_value),
        then: Joi.required().messages({
          'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
          'string.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
        }),
        otherwise: Joi.optional().allow('')
      });
    }

    return parameter.required
      ? Joi.string()
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'string.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.string().optional().allow('');
  }

  if (parameter.type === 'boolean') {
    return Joi.boolean();
  }

  if (parameter.type === 'number') {
    if (parameter.dependency_field && parameter.required) {
      return Joi.number()
        .positive()
        .integer()
        .when(Joi.ref(parameter.dependency_field), {
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
          is: getDependencyValue(parameter.dependency_value),
          then: Joi.required().messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'number.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'number.base': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption }),
            'number.positive': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption }),
            'number.integer': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption })
          }),
          otherwise: Joi.optional().allow('')
        });
    }

    return parameter.required
      ? Joi.number()
          .positive()
          .integer()
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'number.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'number.base': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption }),
            'number.positive': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption }),
            'number.integer': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.number()
          .positive()
          .integer()
          .optional()
          .allow('')
          .messages({
            'number.base': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption }),
            'number.positive': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption }),
            'number.integer': t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption })
          });
  }

  if (parameter.type === 'date') {
    return parameter.required
      ? Joi.object()
          .custom((value, helper) => {
            if (moment.isMoment(value) && !value.isValid()) {
              return helper.message({
                custom: t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption })
              });
            }

            return value;
          })
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'object.base': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.object()
          .custom((value, helper) => {
            if (!moment.isMoment(value) && !_.isEmpty(value)) {
              return helper.message({
                custom: t('validation.fieldWithPlaceholderInvalid', { ns: 'forms', placeholder: parameter.caption })
              });
            }

            return value;
          })
          .allow('')
          .allow(null);
  }

  if (parameter.type === 'dropdown' && parameter.multiple_selection) {
    return parameter.required
      ? Joi.array()
          .min(1)
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'array.min': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.array();
  }

  if (parameter.type === 'dropdown' && !parameter.multiple_selection) {
    return parameter.required
      ? Joi.string()
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.string().optional().allow('');
  }

  if (parameter.type === 'user_api_key') {
    return parameter.required
      ? Joi.string()
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'string.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'string.base': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.string().optional().allow('');
  }

  if (parameter.type === 'radio_button') {
    if (parameter.dependency_field && parameter.required) {
      return Joi.alternatives(Joi.string(), Joi.number()).when(Joi.ref(parameter.dependency_field), {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        is: getDependencyValue(parameter.dependency_value),
        then: Joi.required().messages({
          'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
          'string.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
          'number.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
        }),
        otherwise: Joi.optional().allow('')
      });
    }

    return parameter.required
      ? Joi.alternatives(Joi.string(), Joi.number())
          .required()
          .messages({
            'any.required': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'string.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption }),
            'number.empty': t('validation.fieldWithPlaceholderRequired', { ns: 'forms', placeholder: parameter.caption })
          })
      : Joi.alternatives(Joi.string(), Joi.number()).optional().allow('');
  }

  return null;
};

const getSmartGeneratedFieldDefaultValue = (
  parameter: IntegrationJobParameterRecord
): string | string[] | number | boolean | Moment => {
  if (parameter.type === 'boolean') {
    // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty...
    return parameter.default?.toString() === 'true' || parameter.default_checked;
  }

  if (parameter.type === 'dropdown' && parameter.multiple_selection) {
    return parameter.default || [];
  }

  if (parameter.type === 'date') {
    // @ts-expect-error TS(2322): Type 'Moment | null' is not assignable to type 'st...
    return parameter.default ? moment(parameter.default) : null;
  }

  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
  return parameter.default;
};

export { getFormParameterName, getSmartGeneratedFieldSchema, getSmartGeneratedFieldDefaultValue };
