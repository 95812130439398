import { withTransaction } from '@elastic/apm-rum-react';
import { ArcannaBreadcrumbs } from 'src/components/shared/components';
import { useFeedbackUnifiedContext } from './FeedbackUnified.context';
import { Spinner } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';
import { useJobNavigate } from 'src/components/shared/hooks/useJobNavigate';
import { FeedbackUnifiedTable } from './FeedbackUnifiedTable';
import FeedbackFlowFooter from '@arcanna/pages/Feedback/FeedbackTable/components/FeedbackFlowFooter';
import { Box, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { fetchJobInfo } from 'src/data-access';
import React from 'react';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { FeedbackFlowNavigation } from '../Flow/FeedbackFlowNavigation';
import { getCustomLabelsFromJobs } from '../Flow/SelectFeedback';
import StyledFeedbackUnifiedFooter from './StyledFeedbackUnifiedFooter.styles';
import { RetrainButtonUnified } from './RetrainButton/RetrainButtonUnified';
import JobComboBox from './JobComboBox/JobComboBox';
import FeedbackBulkContextProvider from '@arcanna/pages/Feedback/FeedbackBulkContext/FeedbackBulkContextProvider';
import { useFeedbackUnified } from './FeedbackUnified.hook';

function FeedbackUnified() {
  const {
    state: feedbackState,
    setJobIds,
    setJobInfos,
    setJobCustomLabels,
    setEditLabelOnSelected,
    setReloadFeedbackData,
    setPrepareConfirm,
    setPrepareClear
  } = useFeedbackUnifiedContext();
  const { allLabelsOnChangeCancel, allLabelsOnChangeConfirm } = useFeedbackUnified();
  const { t } = useTranslation(['common', 'homepage', 'job', 'feedback']);
  const { navigateToJobs, navigateToJobFeedbackUnified } = useJobNavigate();

  const { ids } = useParams<{ ids: string }>();
  const jobIds = useMemo(() => ids.split(',').map((id: string) => Number(id)), [ids]);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  useEffect(() => {
    setJobIds(jobIds);
    Promise.all(jobIds.map(async (id) => await fetchJobInfo(id, jsonConvert))).then((jInfos) => {
      setJobInfos(jInfos);
      setJobCustomLabels(getCustomLabelsFromJobs(jInfos));
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobIds]);

  return (
    <>
      <Box>
        {feedbackState.loading && <Spinner isOverlay />}
        <ArcannaBreadcrumbs
          items={[
            {
              title: t('homepage:AIJobsCardTitle'),
              onClick: navigateToJobs
            },
            {
              title: t('feedback:jobsGroup')
            },
            {
              title: t('job:navigation.feedback')
            }
          ]}
        />
        <FeedbackFlowNavigation
          hideShrinkExpand={true}
          hideDecisionPoints={true}
          jobInfos={feedbackState.jobInfos}
          extraFilters={<JobComboBox initialJobIds={jobIds} onSelectionChange={navigateToJobFeedbackUnified} />}
        >
          <Stack flexGrow={1} direction="row" justifyContent="flex-end">
            <RetrainButtonUnified jobInfos={feedbackState.jobInfos} />
          </Stack>
        </FeedbackFlowNavigation>
        <FeedbackUnifiedTable />
        <StyledFeedbackUnifiedFooter>
          <FeedbackBulkContextProvider
            selectedLabel={undefined}
            prepareConfirm={feedbackState.prepareConfirm}
            prepareClear={feedbackState.prepareClear}
            feedbackBulkContextValue={{
              totalBucketsCount: feedbackState.tableData.length,
              selectedBuckets: feedbackState.selectedRowKeys,
              bucketsRows: feedbackState.tableData,
              setEditLabelOnSelected: setEditLabelOnSelected,
              handleCancelBulkFeedback: allLabelsOnChangeCancel,
              handleCustomOnSave: allLabelsOnChangeConfirm,
              triggerBucketsReload: () => setReloadFeedbackData(true),
              setPrepareConfirm: setPrepareConfirm,
              setPrepareClear: setPrepareClear
            }}
          >
            <FeedbackFlowFooter
              enabled={feedbackState.selectedRowKeys.length > 0}
              jobInfos={feedbackState.jobInfos}
              prepareConfirm={feedbackState.prepareConfirm}
              prepareClear={feedbackState.prepareClear}
            />
          </FeedbackBulkContextProvider>
        </StyledFeedbackUnifiedFooter>
      </Box>
    </>
  );
}

export default withTransaction('FeedbackUnified', 'component')(FeedbackUnified);
