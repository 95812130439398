import { JsonObject, JsonProperty, PropertyConvertingMode } from 'json2typescript';
import { IntegrationJobParameterRecordNew } from './IntegrationJobParameterRecord';

@JsonObject('IntegrationJobParametersRecord')
export class IntegrationJobParametersRecord {
  @JsonProperty('input', IntegrationJobParameterRecordNew)
  input: IntegrationJobParameterRecordNew = {};

  @JsonProperty('processor', IntegrationJobParameterRecordNew)
  processor: IntegrationJobParameterRecordNew = {};

  @JsonProperty('enrichment', IntegrationJobParameterRecordNew)
  enrichment: IntegrationJobParameterRecordNew = {};

  @JsonProperty('context_enrichment', IntegrationJobParameterRecordNew, PropertyConvertingMode.IGNORE_NULLABLE)
  context_enrichment?: IntegrationJobParameterRecordNew = {};

  @JsonProperty('case_creation', IntegrationJobParameterRecordNew)
  case_creation: IntegrationJobParameterRecordNew = {};

  @JsonProperty('post_decision', IntegrationJobParameterRecordNew)
  post_decision: IntegrationJobParameterRecordNew = {};

  @JsonProperty('output', IntegrationJobParameterRecordNew)
  output: IntegrationJobParameterRecordNew = {};

  @JsonProperty('alerting', IntegrationJobParameterRecordNew, PropertyConvertingMode.IGNORE_NULLABLE)
  alerting?: IntegrationJobParameterRecordNew = {};
}
