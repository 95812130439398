import { SVGProps } from 'react';

export type ExclamationOutlinedIconProps = SVGProps<SVGSVGElement>;

export default function ExclamationOutlinedIcon({ ...props }: ExclamationOutlinedIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M11.9898 17.7499L12 17.7501L12.0102 17.7499C12.4354 17.7412 12.8403 17.5662 13.1379 17.2624C13.4356 16.9586 13.6023 16.5503 13.6023 16.125C13.6023 15.6997 13.4356 15.2914 13.1379 14.9876C12.8403 14.6838 12.4354 14.5088 12.0102 14.5001L12 14.4999L11.9898 14.5001C11.5646 14.5088 11.1597 14.6838 10.8621 14.9876C10.5644 15.2914 10.3977 15.6997 10.3977 16.125C10.3977 16.5503 10.5644 16.9586 10.8621 17.2624C11.1597 17.5662 11.5646 17.7412 11.9898 17.7499ZM2 12C2 6.47771 6.47771 2 12 2C17.5223 2 22 6.47771 22 12C22 17.5223 17.5223 22 12 22C6.47771 22 2 17.5223 2 12ZM11.4375 6.25C11.0582 6.25 10.75 6.55823 10.75 6.9375V13.3125C10.75 13.6918 11.0582 14 11.4375 14H12.5625C12.9418 14 13.25 13.6918 13.25 13.3125V6.9375C13.25 6.55823 12.9418 6.25 12.5625 6.25H11.4375Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}
