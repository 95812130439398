import { useMemo } from 'react';
import { TFeedbackTableRowData } from '../../FeedbackTable.types';
import { Stack, Tooltip } from '@mui/material';
import { CustomLabel } from '@arcanna/components';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';
import { noDecisionResult, undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';
import { Row } from '@tanstack/react-table';

type TLabelColumnProps = {
  row: Row<TFeedbackTableRowData>;
  jobInfoCustomLabels: CustomLabelType[];
  newLabelId: string | undefined;
  prepareConfirm: boolean;
  prepareClear: boolean;
  isDisplayedOnTwoRows: boolean;
};

function LabelColumn({
  row,
  jobInfoCustomLabels,
  newLabelId,
  prepareConfirm,
  prepareClear,
  isDisplayedOnTwoRows
}: TLabelColumnProps) {
  const selectedLabelId = row.getIsSelected() ? newLabelId : undefined;
  const rowData = row.original;
  const labelId = rowData.label;
  const consensus = rowData.consensus;
  const newLabel = useMemo(() => {
    if (prepareConfirm && selectedLabelId !== undefined) {
      return labelId;
    }
    if (prepareClear && selectedLabelId !== undefined) {
      return undefined;
    }
    return selectedLabelId || consensus;
  }, [selectedLabelId, consensus, labelId, prepareConfirm, prepareClear]);

  const hasConsensusOverwritten = Boolean(rowData.consensusOverwritten?.consensusOverwrittenBy);

  let oldValue: CustomLabelType | undefined = jobInfoCustomLabels.find((customLabel) => customLabel.id === labelId);

  if (oldValue == null) {
    oldValue = noDecisionResult;
  }

  const newValue: CustomLabelType | undefined =
    newLabel === undecidedConsensus.id
      ? undecidedConsensus
      : jobInfoCustomLabels.find((customLabel) => customLabel.id === newLabel);

  const hasNewValue = newValue && newValue !== oldValue && newValue !== undefined;

  const isFeedbackSelected = useMemo(
    () => (Boolean(newValue) && newValue !== oldValue) || (prepareConfirm && selectedLabelId !== undefined),
    [newValue, oldValue, prepareConfirm, selectedLabelId]
  );

  const getContent = (width?: number) =>
    oldValue ? (
      <Stack
        direction={isDisplayedOnTwoRows ? 'column' : 'row'}
        alignItems={isDisplayedOnTwoRows ? 'flex-start' : 'center'}
        gap={1}
      >
        <CustomLabel
          name={oldValue.name}
          dataTestId="label-column-value"
          hexColor={oldValue.hexColor}
          disabled={isFeedbackSelected}
          strike={isFeedbackSelected}
          fontSize="12px"
          disableColorCircle={oldValue === noDecisionResult || oldValue.name === undecidedConsensus.name}
          isConfirmed={newValue === oldValue}
          hasConsensusOverwritten={hasConsensusOverwritten && newValue == oldValue}
          width={width && (hasNewValue && !isDisplayedOnTwoRows ? width / 2 : 160)}
        />
        {hasNewValue && (
          <CustomLabel
            name={newValue.name}
            dataTestId="new-label-column-value"
            disableColorCircle={newValue === undecidedConsensus}
            hexColor={newValue.hexColor}
            fontSize="12px"
            hasConsensusOverwritten={hasConsensusOverwritten}
            width={width && !isDisplayedOnTwoRows ? width / 2 : undefined}
          />
        )}
      </Stack>
    ) : (
      <></>
    );

  return oldValue ? <Tooltip title={getContent()}>{getContent(140)}</Tooltip> : null;
}

export default LabelColumn;
