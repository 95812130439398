import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { FeedbackFiltersFieldsRequest } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRequest';
import { FeedbackFiltersFieldsResponse } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsResponse';
import { useMemo } from 'react';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';

export function useFeedbackFilterFields(jobId: number) {
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackFiltersFieldsResponse, Error>([config.api.feedbackFiltersFields], () =>
    axios
      .post<{
        resource: FeedbackFiltersFieldsResponse;
      }>(config.api.feedbackFiltersFields, new FeedbackFiltersFieldsRequest(jobId))
      .then((responseData: AxiosResponse<{ resource: FeedbackFiltersFieldsResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, FeedbackFiltersFieldsResponse)
      )
  );
}
