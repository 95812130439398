import { Stack, StackProps, styled } from '@mui/material';

const StyledInlineNotification = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'notificationColor' && propName !== 'textPosition';
  }
})<
  StackProps & {
    notificationColor: string;
    textPosition: 'vertical' | 'horizontal';
  }
>(({ theme, notificationColor, textPosition }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: textPosition === 'vertical' ? 'flex-start' : 'center',
  backgroundColor: theme.palette.secondary[800],
  padding: '14px 12px',
  borderRadius: '0px 4px 4px 0px',
  borderLeft: `2px solid ${notificationColor}`
}));

export default StyledInlineNotification;
