import TableComponent from './Table';
import GeneralCellWithFilters from './components/GeneralCellWithFilters';
import { TABLE_SIZES } from './components/TableSizeSelector/TableSizeSelector.constants';
import TablePagination from './components/TablePagination';
import { computeTableColumnIdFromColumnName, extractColumnNameFromTableColumnId } from './Table.utils';
import { getDisplayedValue } from './components/GeneralCellWithFilters/GeneralCellWithFilters.utils';

const Table = {
  component: TableComponent,
  GeneralCellWithFilters,
  TablePagination,
  computeTableColumnIdFromColumnName,
  extractColumnNameFromTableColumnId,
  getDisplayedValue,
  TABLE_SIZES
};

export { Table };
