import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FeedbackContext } from './FeedbackContext';
import { useFeedbackBucketsBatch, useJobInfo } from 'src/data-access';
import {
  TFeedbackContext,
  TFeedbackExpandedBucketContextState,
  TFeedbackFiltersContextState,
  TFeedbackTableContextState
} from './FeedbackContext.type';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { TPageSize } from '@arcanna/generic';
import { RowSelectionState, SortingState, Updater } from '@tanstack/react-table';
import { useJobFeedbackFilterFields } from '@arcanna/requests/Jobs';
import { getColumnKeyBasedOnFilters } from '../FeedbackTable/FeedbackTable.utils';
import { useAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/hooks';
import { FeedbackQuickFilters } from 'src/components/shared/models/feedback/FeedbackQuickFilters';
import { TAdditionalFilter, TFilterItem } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.types';
import { TOption } from '@arcanna/generic';
import { useFetch } from 'src/components/shared/hooks/useFetch';
import { getBackendEndpoint } from 'src/components/shared/utilities/api';
import { FeedbackFiltersValuesRequest } from 'src/components/shared/models/feedback/FeedbackFiltersValuesRequest';
import { getTransformedAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { FeedbackAdvancedFilterRecord } from '../../../../components/shared/models/feedback/FeedbackAdvancedFilterRecord';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { FeedbackFiltersValuesResponse } from 'src/components/shared/models/feedback/FeedbackFiltersValuesResponse';
import { ARCANNA_NO_DECISION, ARCANNA_NO_DECISION_DISPLAY_VALUE } from 'src/components/pages/JobEventExplorer/utils';
import { FeedbackBucketState } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { useJobNavigate } from 'src/components/shared/hooks/useJobNavigate';
import { useJobContext } from 'src/components/pages/Main/Jobs/Job.context';
import { FeedbackTimeSelectorOption } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.hook';
import moment from 'moment';
import { JobCategory } from 'src/components/pages/Main/Jobs/helper';
import { useUrlFilters } from 'src/components/shared/hooks/useUrlFilters';
import { FeedbackFlowFilters } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.context';
import {
  convertDateToISOString,
  createValueOptions,
  feedbackTimeSelectorRecord,
  getBucketsFromAggs,
  mapSingleFilterValue
} from './FeedbackContext.utils';

type TFeedbackProviderProps = {
  children: ReactNode;
};

function FeedbackContextProvider({ children }: TFeedbackProviderProps) {
  const { getFiltersFromQueryParams, setQueryParamsFilters } = useUrlFilters<FeedbackFlowFilters>();

  const pageLoadFilters = useMemo(
    () =>
      getFiltersFromQueryParams({
        startDate: null,
        // @ts-expect-error
        endDate: moment().toDate(),
        advancedFilters: [],
        quickFilters: new FeedbackQuickFilters(),
        tablePageSize: 20,
        tablePage: 1,
        tableSortColumn: 'timestamp',
        tableSortOrder: 'desc',
        excludeProcessed: 'false',
        includeProcessed: 'false'
      }),
    // eslint-disable-next-line
    []
  );

  const { t } = useTranslation();
  const [tableState, setTableState] = useState<TFeedbackTableContextState>(() => {
    return {
      batchBucketDynamicColumns: [],
      paginationState: {
        pageIndex: Number(pageLoadFilters.tablePage),
        pageSize: Number(pageLoadFilters.tablePageSize) as TPageSize
      },
      sortingState: [
        {
          id: pageLoadFilters.tableSortColumn,
          desc: pageLoadFilters.tableSortOrder === 'desc'
        }
      ],
      rowSelectionState: {}
    };
  });
  const [isBucketsTableLoaded, setIsBucketsTableLoaded] = useState<boolean>(false);
  const [expandedBucketState, setExpandedBucketState] = useState<TFeedbackExpandedBucketContextState>({
    expandedBucket: {
      data: undefined,
      index: undefined
    }
  });

  const [filtersState, setFiltersState] = useState<TFeedbackFiltersContextState>({
    startDate: pageLoadFilters.startDate ? moment(pageLoadFilters.startDate).toDate() : null,
    endDate: pageLoadFilters.endDate ? moment(pageLoadFilters.endDate).toDate() : null,
    quickFilters: pageLoadFilters.quickFilters,
    excludeProcessed: pageLoadFilters.excludeProcessed === 'true',
    includeProcessed: pageLoadFilters.includeProcessed === 'true',
    decisionFilters: [],
    consensusFilters: []
  });

  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { jobId } = useJobIdFromUrl();

  const jobInfoQuery = useJobInfo(Number(jobId));

  const jobInfoCustomLabels = useMemo(
    () => jobInfoQuery.data?.info?.advancedSettings?.customLabels ?? [],
    [jobInfoQuery.data?.info?.advancedSettings?.customLabels]
  );

  const jobTitle = useMemo(() => jobInfoQuery.data?.info?.title ?? '', [jobInfoQuery.data?.info?.title]);

  const isDIJob = useMemo(
    () => jobInfoQuery.data?.info?.jobDetails?.category_id === JobCategory.DECISION_INTELLIGENCE,
    [jobInfoQuery.data?.info?.jobDetails?.category_id]
  );

  const { navigateToNotFound } = useJobNavigate();
  const { setSelectedJob, setIsFeatureSelectionEnabled, setFeatureSelectionAvailabilityMessage, setHasFeaturesSelected } =
    useJobContext();

  useEffect(() => {
    // set job info on the JobInfoProvider
    // need to be removed
    const jobInfo = jobInfoQuery.data?.info;

    if (!jobInfo && jobInfoQuery.isFetched) {
      navigateToNotFound();
    }

    if (!jobInfo && !jobInfoQuery.isFetched) {
      return;
    }

    // @ts-expect-error
    setSelectedJob(jobInfo);
    setHasFeaturesSelected(Boolean(jobInfo?.status?.has_features_selected));
    setIsFeatureSelectionEnabled(jobInfo?.status?.processedDocumentsCount !== 0);
    if (!jobInfo?.status?.has_features_selected) {
      if (jobInfo?.status?.processedDocumentsCount === 0) {
        setFeatureSelectionAvailabilityMessage(t('job:featureSelection:featureSelectionDisabledNoEvents'));
      } else {
        // @ts-expect-error
        setFeatureSelectionAvailabilityMessage(null);
      }
    } else {
      // @ts-expect-error
      setFeatureSelectionAvailabilityMessage(null);
    }
  }, [
    jobInfoQuery.data,
    jobInfoQuery.isFetched,
    navigateToNotFound,
    setFeatureSelectionAvailabilityMessage,
    setHasFeaturesSelected,
    setIsFeatureSelectionEnabled,
    setSelectedJob,
    t
  ]);

  const { data: filterFields } = useJobFeedbackFilterFields({ jobId });

  const filterFieldsList = useMemo(() => filterFields?.fields_list ?? [], [filterFields?.fields_list]);

  const activeSortingKey = useMemo(
    () => getColumnKeyBasedOnFilters(tableState.sortingState[0]?.id, filterFieldsList),
    [tableState.sortingState, filterFieldsList]
  );

  const { post: postFiltersValues } = useFetch({
    path: getBackendEndpoint('/feedback/filters/values'),
    initialResponseData: null,
    load: false
  });

  const getValueOptions = useCallback(
    (fieldValue: string, activeFilters: TFilterItem[]): Promise<TOption[]> => {
      if (!fieldValue) {
        return Promise.resolve([]);
      }

      return postFiltersValues(
        new FeedbackFiltersValuesRequest(
          jobId,
          fieldValue,
          convertDateToISOString(filtersState.startDate),
          convertDateToISOString(filtersState.endDate),
          filtersState.excludeProcessed,
          filtersState.includeProcessed,
          getTransformedAdvancedFilters(activeFilters) as FeedbackAdvancedFilterRecord[],
          filtersState.quickFilters,
          filtersState.decisionFilters,
          filtersState.consensusFilters
        )
      ).then((res) => {
        const filtersValuesResponse = jsonConvert.deserializeObject(res.data.resource, FeedbackFiltersValuesResponse);
        const buckets = getBucketsFromAggs(filtersValuesResponse.recommended_values, fieldValue);

        return createValueOptions(buckets) ?? [];
      });
    },
    [
      filtersState.endDate,
      filtersState.excludeProcessed,
      filtersState.includeProcessed,
      filtersState.quickFilters,
      filtersState.startDate,
      filtersState.consensusFilters,
      filtersState.decisionFilters,
      jobId,
      jsonConvert,
      postFiltersValues
    ]
  );

  const setPageIndex = useCallback(
    (pageIndex: number) =>
      setTableState((previousState) => ({
        ...previousState,
        paginationState: {
          ...previousState.paginationState,
          pageIndex
        }
      })),
    []
  );

  const advancedFilters = useAdvancedFilters({
    fields: filterFieldsList,
    getValueOptions,
    initialFilters: pageLoadFilters.advancedFilters,
    onFiltersChange: () => {
      setPageIndex(1);
    }
  });

  const feedbackBatchQuery = useFeedbackBucketsBatch({
    jobId: jobId,
    tablePageSize: tableState.paginationState.pageSize,
    tablePage: tableState.paginationState.pageIndex - 1,
    startDate: filtersState.startDate,
    endDate: filtersState.endDate,
    excludeProcessed: filtersState.excludeProcessed,
    includeProcessed: filtersState.includeProcessed,
    tableSort: {
      column: activeSortingKey,
      order: tableState.sortingState[0]?.desc ? 'desc' : 'asc'
    },
    advancedFilters: getTransformedAdvancedFilters(advancedFilters.activeFilters),
    quickFilters: filtersState.quickFilters,
    decisionFilters: filtersState.decisionFilters,
    consensusFilters: filtersState.consensusFilters
  });

  useEffect(() => {
    // set query params if filters or table state has changed

    setQueryParamsFilters({
      startDate: filtersState.startDate ? filtersState.startDate.toISOString() : null,
      endDate: filtersState.endDate ? filtersState.endDate.toISOString() : null,
      quickFilters: filtersState.quickFilters,
      tablePageSize: tableState.paginationState.pageSize,
      tablePage: tableState.paginationState.pageIndex,
      tableSortColumn: tableState.sortingState?.[0]?.id ?? null,
      tableSortOrder: tableState.sortingState?.[0]?.desc ? 'desc' : 'asc',
      advancedFilters: advancedFilters.activeFilters,
      excludeProcessed: `${filtersState.excludeProcessed}`,
      includeProcessed: `${filtersState.includeProcessed}`
    });
  }, [
    filtersState.startDate,
    filtersState.endDate,
    filtersState.quickFilters,
    tableState.paginationState.pageSize,
    tableState.paginationState.pageIndex,
    tableState.sortingState,
    advancedFilters.activeFilters,
    filtersState.excludeProcessed,
    filtersState.includeProcessed,
    setQueryParamsFilters
  ]);

  const batchBucketRows = useMemo(() => feedbackBatchQuery.data?.rows ?? [], [feedbackBatchQuery.data?.rows]);

  useEffect(() => {
    // this is used for setting the dynamic columns of the feedback table
    // if the dynamic columns have already been set previously, we should not update them
    // if we update them, the columnOrdering of the react-table will be reset
    if (!isBucketsTableLoaded) {
      setTableState((previousState) => ({
        ...previousState,
        batchBucketDynamicColumns: feedbackBatchQuery.data?.dynamic_columns ?? []
      }));
      setIsBucketsTableLoaded(feedbackBatchQuery.data?.dynamic_columns !== undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackBatchQuery.data?.dynamic_columns]);

  const clearExpandedBucket = useCallback(() => {
    setExpandedBucketState((previousState) => ({ ...previousState, expandedBucket: { data: undefined, index: undefined } }));
  }, []);

  const setSortingState = useCallback((updaterOrValue: Updater<SortingState>) => {
    if (typeof updaterOrValue === 'function') {
      setTableState((previousState) => {
        return { ...previousState, sortingState: updaterOrValue(previousState.sortingState) };
      });
    } else {
      setTableState((previousState) => ({ ...previousState, sortingState: updaterOrValue }));
    }
  }, []);

  const setRowSelectionState = useCallback((updaterOrValue: Updater<RowSelectionState>) => {
    if (typeof updaterOrValue === 'function') {
      setTableState((previousState) => ({
        ...previousState,
        rowSelectionState: updaterOrValue(previousState.rowSelectionState)
      }));
    } else {
      setTableState((previousState) => ({ ...previousState, rowSelectionState: updaterOrValue }));
    }
  }, []);

  const setExpandedBucketByIndex = useCallback(
    (bucketDataIndex: number | undefined) => {
      if ((!bucketDataIndex && bucketDataIndex !== 0) || bucketDataIndex > batchBucketRows.length - 1 || bucketDataIndex < 0) {
        clearExpandedBucket();
      } else {
        setExpandedBucketState((previousState) => {
          if (previousState.expandedBucket.index === bucketDataIndex) {
            return { ...previousState, expandedBucket: { data: undefined, index: undefined } };
          }

          return {
            ...previousState,
            expandedBucket: { data: batchBucketRows[bucketDataIndex], index: bucketDataIndex }
          };
        });
      }
    },
    [batchBucketRows, clearExpandedBucket]
  );

  const setExpandedBucketById = useCallback(
    (bucketId: string) => {
      const bucketDataIndex = batchBucketRows.findIndex((rowData) => rowData.row_id === bucketId);

      if (!bucketDataIndex) {
        clearExpandedBucket();
      } else {
        setExpandedBucketState((previousState) => ({
          ...previousState,
          expandedBucket: { data: batchBucketRows[bucketDataIndex], index: bucketDataIndex }
        }));
      }
    },
    [batchBucketRows, clearExpandedBucket]
  );

  const setPageSize = useCallback(
    (pageSize: TPageSize) =>
      setTableState((previousState) => ({
        ...previousState,
        paginationState: {
          ...previousState.paginationState,
          pageIndex: 1,
          pageSize
        }
      })),
    []
  );

  const setQuickFilters = useCallback(
    (quickFilters: FeedbackQuickFilters) => {
      setFiltersState((previousState) => ({
        ...previousState,
        quickFilters
      }));
      setPageIndex(1);
    },
    [setPageIndex]
  );

  const clearQuickFilters = useCallback(() => {
    setFiltersState((previousState) => ({
      ...previousState,
      quickFilters: new FeedbackQuickFilters()
    }));
    setPageIndex(1);
  }, [setPageIndex]);

  const setDecisionFilters = useCallback(
    (decisionFilters: string[]) => {
      setFiltersState((previousState) => ({
        ...previousState,
        decisionFilters
      }));
      setPageIndex(1);
    },
    [setPageIndex]
  );

  const clearDecisionFilters = useCallback(() => {
    setFiltersState((previousState) => ({
      ...previousState,
      decisionFilters: []
    }));
    setPageIndex(1);
  }, [setPageIndex]);

  const setConsensusFilters = useCallback(
    (consensusFilters: string[]) => {
      setFiltersState((previousState) => ({
        ...previousState,
        consensusFilters
      }));
      setPageIndex(1);
    },
    [setPageIndex]
  );

  const clearConsensusFilters = useCallback(() => {
    setFiltersState((previousState) => ({
      ...previousState,
      decisionFilters: []
    }));
    setPageIndex(1);
  }, [setPageIndex]);

  const additionalFilters: TAdditionalFilter[] = useMemo(() => {
    const requiringAttentionFilters = filtersState.quickFilters.requiring_attention
      ? mapSingleFilterValue(
          t(`feedback:quickFilters:${filtersState.quickFilters.requiring_attention}`),
          t('feedback:quickFilters:requiring_attention'),
          () =>
            setQuickFilters(
              new FeedbackQuickFilters(undefined, filtersState.quickFilters.feedback, filtersState.quickFilters.training_status)
            )
        )
      : undefined;

    const feedbackFilters = filtersState.quickFilters.feedback
      ? mapSingleFilterValue(
          t(`feedback:quickFilters:${filtersState.quickFilters.feedback}`),
          t('feedback:quickFilters:feedback'),
          () =>
            setQuickFilters(
              new FeedbackQuickFilters(
                filtersState.quickFilters.requiring_attention,
                undefined,
                filtersState.quickFilters.training_status
              )
            )
        )
      : undefined;

    const trainingStatusFilters = filtersState.quickFilters.training_status
      ? mapSingleFilterValue(
          t(`feedback:quickFilters:${filtersState.quickFilters.training_status}`),
          t('feedback:quickFilters:training_status'),
          () =>
            setQuickFilters(
              new FeedbackQuickFilters(
                filtersState.quickFilters.requiring_attention,
                filtersState.quickFilters.feedback,
                undefined
              )
            )
        )
      : undefined;

    const decisionFilters = filtersState.decisionFilters.map((filterValue) => {
      const label = jobInfoCustomLabels.find((customLabel) => customLabel.id === filterValue)?.name || filterValue;

      return mapSingleFilterValue(
        filterValue === ARCANNA_NO_DECISION ? ARCANNA_NO_DECISION_DISPLAY_VALUE : label,
        t('common:arcannaDecision'),
        () => setDecisionFilters(filtersState.decisionFilters.filter((item) => item !== filterValue))
      );
    });

    const consensusFilters = filtersState.consensusFilters.map((filterValue) => {
      const label = jobInfoCustomLabels.find((customLabel) => customLabel.id === filterValue)?.name || filterValue;

      return mapSingleFilterValue(
        filterValue === FeedbackBucketState.Undecided ? t('feedback:bucketExpand:undecided') : label,
        t('job:jobDashboardFlow:consensus'),
        () => setConsensusFilters(filtersState.consensusFilters.filter((item) => item !== filterValue))
      );
    });

    return [requiringAttentionFilters, feedbackFilters, trainingStatusFilters, ...decisionFilters, ...consensusFilters].filter(
      Boolean
    ) as TAdditionalFilter[];
  }, [
    t,
    jobInfoCustomLabels,
    filtersState.decisionFilters,
    filtersState.consensusFilters,
    filtersState.quickFilters,
    setQuickFilters,
    setConsensusFilters,
    setDecisionFilters
  ]);

  const clearAdditionalFilters = useCallback(() => {
    setQuickFilters(new FeedbackQuickFilters(undefined, undefined, undefined));
    setDecisionFilters([]);
    setConsensusFilters([]);
  }, [setQuickFilters, setConsensusFilters, setDecisionFilters]);

  const triggerBucketsReload = useCallback(() => {
    feedbackBatchQuery.refetch();
  }, [feedbackBatchQuery]);

  const handleFeedbackTimeSelection = useCallback((option: FeedbackTimeSelectorOption) => {
    const dates = feedbackTimeSelectorRecord[option];
    setFiltersState((prevFiltersState) => ({
      ...prevFiltersState,
      startDate: dates.dateFrom,
      endDate: dates.dateTo
    }));
  }, []);

  const value: TFeedbackContext = useMemo(
    () => ({
      jobInfoSlice: {
        jobId,
        jobInfoCustomLabels,
        isDIJob,
        jobTitle
      },
      tableSlice: {
        ...tableState,
        isBucketsLoading: feedbackBatchQuery.isLoading || (feedbackBatchQuery.isFetching && !feedbackBatchQuery.isRefetching),
        isBucketsTableLoaded: isBucketsTableLoaded,
        batchBucketRows,
        setPageSize,
        setPageIndex,
        totalRecordsCount: feedbackBatchQuery.data?.total ?? 0,
        setSortingState,
        setRowSelectionState,
        triggerBucketsReload
      },
      expandedBucketSlice: {
        ...expandedBucketState,
        setExpandedBucketByIndex,
        setExpandedBucketById,
        clearExpandedBucket
      },
      filtersSlice: {
        ...filtersState,
        advancedFilters,
        clearAdditionalFilters,
        additionalFilters,
        setQuickFilters,
        setDecisionFilters,
        setConsensusFilters,
        clearQuickFilters,
        clearDecisionFilters,
        clearConsensusFilters,
        handleFeedbackTimeSelection,
        filterFields: filterFieldsList
      }
    }),
    [
      isBucketsTableLoaded,
      feedbackBatchQuery.isLoading,
      feedbackBatchQuery.isFetching,
      feedbackBatchQuery.isRefetching,
      feedbackBatchQuery.data?.total,
      tableState,
      expandedBucketState,
      batchBucketRows,
      setPageSize,
      setPageIndex,
      setSortingState,
      setExpandedBucketByIndex,
      setExpandedBucketById,
      clearExpandedBucket,
      advancedFilters,
      filtersState,
      jobId,
      jobInfoCustomLabels,
      additionalFilters,
      clearAdditionalFilters,
      setQuickFilters,
      setDecisionFilters,
      setConsensusFilters,
      clearQuickFilters,
      clearDecisionFilters,
      clearConsensusFilters,
      triggerBucketsReload,
      handleFeedbackTimeSelection,
      isDIJob,
      filterFieldsList,
      jobTitle,
      setRowSelectionState
    ]
  );

  return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
}

export default FeedbackContextProvider;
