import { useMemo } from 'react';
import { RHFComboBox, RHFSelect } from '@arcanna/forms';
import { TOption } from '@arcanna/generic';
import { getDataTestIdFormat } from 'src/utils/getDataTestIdFormat';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';
import { Stack } from '@mui/material';
import { LabelCircle } from '@arcanna/components';
import { Typography } from '@mui/material';

type TOptionWithColor = TOption & { hexColor?: string };

function DropdownParameter({ parameter, customLabels, formSectionName }: TRHFSmartGeneratedFieldProps) {
  const options: TOptionWithColor[] = useMemo(() => {
    let optionsTemp: TOptionWithColor[] = [];

    if (parameter.multiple_selection && parameter.multiclass) {
      optionsTemp =
        customLabels?.map((customLabel) => ({
          label: customLabel.name,
          value: customLabel.name,
          hexColor: customLabel.hexColor
        })) ?? [];
    }

    return [
      ...optionsTemp,
      ...(parameter.allowed_values?.map((value) => ({
        label: value.value,
        value: value.key
      })) ?? [])
    ].filter((option) => Boolean(option.label) && Boolean(option.value)) as TOptionWithColor[];
  }, [parameter, customLabels]);

  if (parameter.multiple_selection) {
    return (
      <RHFComboBox<TOptionWithColor>
        name={getFormParameterName(parameter, formSectionName)}
        options={options}
        label={parameter.caption ?? ''}
        required={parameter.required}
        tooltipText={parameter.description}
        renderOptionLabel={(option) =>
          option.hexColor ? (
            <Stack direction="row" gap="8px" alignItems="center">
              <LabelCircle hexColor={option.hexColor} />
              <Typography fontSize="14px" lineHeight="14px">
                {option.label}
              </Typography>
            </Stack>
          ) : (
            option.label
          )
        }
      />
    );
  }

  return (
    <RHFSelect
      label={parameter.caption}
      name={getFormParameterName(parameter, formSectionName)}
      tooltipText={parameter.description}
      dataTestId={getDataTestIdFormat(`parameter-field-${parameter.field}`)}
      options={options}
      required={parameter.required}
    />
  );
}

export default DropdownParameter;
