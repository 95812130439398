import { JsonObject, JsonProperty } from 'json2typescript';
import { JobCreateParameterRecord } from './JobCreateParameterRecord';
import { JobIntegrationThrottleRecord } from './JobIntegrationThrottleRecord';

@JsonObject('JobCreateIntegrationRecord')
export class JobCreateIntegrationRecord {
  autoId?: number = undefined;

  @JsonProperty('id', Number)
  id?: number = undefined;

  @JsonProperty('title', String)
  title?: string = undefined;

  @JsonProperty('params', [JobCreateParameterRecord])
  params?: JobCreateParameterRecord[] = [];

  @JsonProperty('throttle', JobIntegrationThrottleRecord, true)
  throttle?: JobIntegrationThrottleRecord = undefined;

  constructor(id: number, params: JobCreateParameterRecord[], title?: string) {
    this.autoId = Math.random();
    this.id = id;
    this.params = params;
    this.title = title;
  }
}
