import { BoxProps } from '@mui/material';
import StyledStateChip from './StyledStateChip.styles';

type TStateChipProps = {
  color: string;
  text: string;
  size?: 'small' | 'medium' | 'large';
} & BoxProps;

function StateChip({ color, text, size = 'small', ...otherProps }: TStateChipProps) {
  return (
    <StyledStateChip color={color} size={size} {...otherProps}>
      {text}
    </StyledStateChip>
  );
}

export default StateChip;
