import { withTransaction } from '@elastic/apm-rum-react';
import { useJobTitle } from '../Retrain/hooks/usePageTitle';
import { useParams } from 'react-router-dom';
import JobOverviewBreadcrumb from './components/breadcrumb/JobOverviewBreadcrumb';
import JobSummary from './JobSummary/JobSummary';
import JobFeedbackSection from './JobFeedbackSection';
import { JobOverviewActions } from './components/JobOverviewActions';
import JobPerformance from './JobPerformance';
import { useTranslation } from 'react-i18next';
import { useEstablishStartNotificationType } from '../components/JobNotifications/hooks/useEstablishStartNotificationType';
import { useOverviewReload } from './hooks/useOverviewReload';
import JobNotifications from '../components/JobNotifications';

const JobOverview = () => {
  const { t } = useTranslation(['job']);
  const { id } = useParams<{ id: string }>();
  const jobId = +id;
  useJobTitle(jobId);
  const { notificationType } = useEstablishStartNotificationType(jobId, t('job:navigation.overview'));
  const reload = useOverviewReload();

  return (
    <>
      <JobOverviewBreadcrumb jobId={jobId} />
      <JobNotifications fromPage={t('job:navigation.overview')} reload={reload} startNotificationType={notificationType} />
      <div className="flex flex-col" style={{ gap: 24, marginBottom: 100 }}>
        <JobSummary jobId={jobId} />
        <JobFeedbackSection jobId={jobId} />
        <JobPerformance jobId={jobId} />
      </div>
      <JobOverviewActions jobId={jobId} />
    </>
  );
};
export default withTransaction('JobEventExplorer', 'component')(JobOverview);
