import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../FeedbackContext/useFeedbackContext';
import { useJobIdFromUrl } from '@arcanna/hooks';
import FeedbackHeader from '../FeedbackHeader';
import FeedbackTable from '../FeedbackTable';
import { Breadcrumbs, Stack } from '@mui/material';
import { Icon, EIcon, Spinner } from '@arcanna/generic';
import { config } from 'src/config';
import { Link } from 'react-router-dom';
import { useEstablishStartNotificationType } from 'src/components/pages/Main/Jobs/components/JobNotifications/hooks/useEstablishStartNotificationType';
import JobNotifications from 'src/components/pages/Main/Jobs/components/JobNotifications';
import { useJobInfo } from 'src/data-access';
import { useMemo } from 'react';
import { JobStatusToggle } from 'src/components/shared/components';

function FeedbackPageContent() {
  const { t } = useTranslation();
  const { jobInfoSlice, tableSlice } = useFeedbackContext();
  const { jobId } = useJobIdFromUrl();
  const { notificationType, showContent } = useEstablishStartNotificationType(jobId, t('job:navigation.feedback'));
  const jobInfoQuery = useJobInfo(jobId);
  const jobInfos = useMemo(() => (jobInfoQuery.data != null ? [jobInfoQuery.data] : []), [jobInfoQuery.data]);

  return (
    <Stack direction="column" gap={2}>
      {!tableSlice.isBucketsTableLoaded ? (
        <Stack alignItems="center" justifyContent="center" height="80vh" position={'relative'}>
          <Spinner />
        </Stack>
      ) : (
        <>
          {jobInfoSlice.jobId ? (
            <Stack direction="row" gap={2} justifyContent="space-between">
              <Breadcrumbs separator={<Icon name={EIcon.ChevronRight} />} sx={{ marginBottom: 1 }}>
                <Link to={config.routes.jobsAll}>{t('job:aiJobs')}</Link>
                <span>{jobInfoSlice.jobTitle}</span>
                <span>{t('job:navigation.feedback')}</span>
              </Breadcrumbs>
              <JobStatusToggle jobId={jobInfoSlice.jobId} />
            </Stack>
          ) : (
            <></>
          )}
          <JobNotifications
            fromPage={t('job:navigation.feedback')}
            reload={tableSlice.triggerBucketsReload}
            startNotificationType={notificationType}
          />
          {showContent ? (
            <>
              <FeedbackHeader jobInfos={jobInfos} />
              <FeedbackTable jobInfos={jobInfos} />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Stack>
  );
}

export default FeedbackPageContent;
