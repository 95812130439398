import { FeedbackContext } from './FeedbackContext';
import { TFeedbackContext } from './FeedbackContext.type';
import { useContext } from 'react';

export function useFeedbackContext(): TFeedbackContext {
  const context = useContext(FeedbackContext);

  if (context === undefined) {
    throw new Error('useFeedbackContext must be used within FeedbackProvider');
  }

  return context;
}
