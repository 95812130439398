import { useEffect, useState } from 'react';
import { Form, Row, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './JobOverviewMetrics.module.css';
import InputNumber from '../../../../../shared/components/InputNumber';
import Button from '../../../../../shared/components/Button/Button';
import { useJobTimeMetrics } from '../../../../../../data-access/job/useJobTimeMetrics';
import { JobTimeMetricsRequest } from '../../../../../shared/models/job/JobTimeMetricsRequest';
import { useJobOverviewContext } from '../JobOverview.context';
import { config } from '../../../../../../config';
import { useQueryClient } from 'react-query';
import { validateSuperunitaryNumber } from '../../components/JobDraft/utils/validators';
import { RuleObject } from 'antd/lib/form';
import { showErrorNotification } from '../../../../../shared/utilities/notification';
import TimeMetric from './TimeMetric';

type TimeMetrics = {
  time_value: number | undefined;
  time_units: string | undefined;
};

type TimeMetricsEditProps = {
  jobId: number;
  jobTimeMetrics: TimeMetrics | undefined;
};

export default function TimeMetricsEdit({ jobId, jobTimeMetrics }: TimeMetricsEditProps) {
  const { t } = useTranslation(['common', 'job']);
  const selectOptions = [
    {
      value: 'seconds',
      label: t('common:timeUnits:seconds'),
      style: { fontSize: '12px', color: 'var(--secondary-color-nb-300)' }
    },
    {
      value: 'minutes',
      label: t('common:timeUnits:minutes'),
      style: { fontSize: '12px', color: 'var(--secondary-color-nb-300)' }
    },
    {
      value: 'hours',
      label: t('common:timeUnits:hours'),
      style: { fontSize: '12px', color: 'var(--secondary-color-nb-300)' }
    }
  ];
  const actualTimeValue = jobTimeMetrics?.time_value;
  const initialTimeValue = actualTimeValue !== null ? actualTimeValue : 'Value';

  const [form] = Form.useForm();

  const actualTimeUnits = jobTimeMetrics?.time_units;
  const initialTimeUnits = actualTimeUnits !== null ? actualTimeUnits : 'minutes';
  const [timeUnits, setTimeUnits] = useState(initialTimeUnits);

  const { setEditTimeMetrics } = useJobOverviewContext();
  const queryClient = useQueryClient();
  const updateJobTimeMetrics = useJobTimeMetrics();

  useEffect(
    function initTimeValue() {
      form.setFieldsValue({ time_value: jobTimeMetrics?.time_value });
    },
    // eslint-disable-next-line
    [jobTimeMetrics?.time_value]
  );

  const positiveRequiredNumberInputRules = [
    { required: true, message: t('common:required') },
    () => ({
      validator(_: RuleObject, value: number) {
        return validateSuperunitaryNumber(value, t);
      }
    })
  ];

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    const timeValue = values.time_value;
    if (timeValue === null || timeValue < 1) {
      showErrorNotification(t('job:jobTimeMetrics.errorMessage'), t('job:jobTimeMetrics.genericErrorDescription'));
      return;
    }
    if (timeUnits !== null) {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
      updateJobTimeMetrics.mutate(new JobTimeMetricsRequest(jobId, timeUnits, timeValue), {
        onSuccess: () => {
          setEditTimeMetrics(false);
          queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
        }
      });
    }
  };
  return (
    <>
      <Row>
        <Typography.Text className="body-2 color-nb-100">{t('job:jobTimeMetrics:title')}</Typography.Text>
      </Row>
      <TimeMetric
        text={t('job:jobTimeMetrics:averageTimeMessage')}
        tooltipText={t('job:jobTimeMetrics:averageTimeMessageInfo')}
        customClassName="p-t-8 p-b-8"
      />
      <Row>
        <Space align={'center'}>
          <Form form={form} style={{ height: 36, width: 140 }} size={'small'} onFinish={onFinish}>
            <Form.Item name={'time_value'} initialValue={initialTimeValue} rules={positiveRequiredNumberInputRules}>
              {form.getFieldValue('time_value') === null ? (
                <InputNumber className={style.inputNumberInit} size={'small'} />
              ) : (
                <InputNumber className={style.inputNumber} size={'small'} />
              )}
            </Form.Item>
          </Form>
          <Select
            defaultValue={initialTimeUnits}
            style={{ width: 106, fontSize: 14 }}
            dropdownStyle={{ maxWidth: 106 }}
            size="small"
            onChange={(value) => setTimeUnits(value)}
            options={selectOptions}
          />
          <Button
            type="secondaryNew"
            size="small"
            onClick={() => {
              form.submit();
            }}
            text={t('common:save')}
          />
          <Button type="outlined" size="small" onClick={() => setEditTimeMetrics(false)} text={t('common:cancel')} />
        </Space>
      </Row>
    </>
  );
}
