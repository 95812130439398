import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { Drawer } from '@arcanna/generic';
import BucketDrawerHeader from './components/BucketDrawerHeader';
import { getBucketDrawerTabs } from './BucketDrawer.utils';
import { EBucketDrawerTabs } from './BucketDrawer.constants';

export type BucketDrawerProps = {
  jobId: number;
  bucketId: string | undefined;
  shortBucketId?: string;
  expandedBucketRowIndex: number | undefined;
  handleDrawerClose: () => void;
};

function BucketDrawer({ jobId, bucketId, shortBucketId, expandedBucketRowIndex, handleDrawerClose }: BucketDrawerProps) {
  const [currentTab, setCurrentTab] = useState(EBucketDrawerTabs.FEEDBACK);
  const { t } = useTranslation(['feedback']);
  const tabs = useMemo(() => getBucketDrawerTabs(t), [t]);
  const Component = useMemo(() => tabs.find((tab) => tab.name === currentTab)?.component, [tabs, currentTab]);

  const closeDrawer = () => {
    handleDrawerClose();
    setCurrentTab(EBucketDrawerTabs.FEEDBACK);
  };

  const isDrawerVisible = Boolean(bucketId) && (Boolean(expandedBucketRowIndex) || expandedBucketRowIndex === 0);

  return (
    <Drawer
      anchor="right"
      open={isDrawerVisible}
      onClose={closeDrawer}
      header={
        bucketId && (expandedBucketRowIndex || expandedBucketRowIndex === 0) ? (
          <BucketDrawerHeader jobId={jobId} bucketId={bucketId} shortBucketId={shortBucketId} />
        ) : (
          <></>
        )
      }
    >
      <Stack gap={3} position="relative" height="100%">
        <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
          {tabs.map((tab) => (
            <Tab
              key={tab.key}
              label={
                <Typography variant="body2" fontWeight={500} color="inherit">
                  {tab.name}
                </Typography>
              }
              value={tab.name}
            />
          ))}
        </Tabs>
        {Component && bucketId && shortBucketId && <Component jobId={jobId} bucketId={bucketId} shortBucketId={shortBucketId} />}
      </Stack>
    </Drawer>
  );
}

export default BucketDrawer;
