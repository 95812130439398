import { useTranslation } from 'react-i18next';
import { useMigrationStatus } from '../../../data-access/migration/useMigrationStatus';
import SystemNotification from '../../shared/components/Notifications/SystemNotification';

function MigrationInProgressNotification() {
  const migrationStatusQuery = useMigrationStatus();

  const { t } = useTranslation(['common', 'job']);

  if (migrationStatusQuery.data?.upgradeInProgress) {
    return (
      <SystemNotification
        title={t('common:migrationInProgressNotification')}
        subtitle={t('job:notifications.stayTuned')}
        type={'warning'}
        hasSpinner
        sx={{ marginBottom: '24px' }}
      />
    );
  }
  return <></>;
}

export default MigrationInProgressNotification;
