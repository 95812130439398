import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FilterValue } from 'antd/lib/table/interface';
import moment from 'moment';
import { FeedbackBatchBucketRow } from '../../../../../shared/models/feedback/FeedbackBatchBucketRow';
import { useUrlFilters } from '../../../../../shared/hooks/useUrlFilters';
import { FeedbackQuickFilters } from '../../../../../shared/models/feedback/FeedbackQuickFilters';
import { TableStyle } from '../../../../../shared/components';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { EventExplorerFiltersFieldsRecord } from 'src/components/shared/models/event-explorer/EventExplorerFiltersFieldsRecord';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { useFetch } from 'src/components/shared/hooks/useFetch';
import { getBackendEndpoint } from 'src/components/shared/utilities/api';
import { TOption } from '@arcanna/generic';
import { FeedbackFiltersValuesRequest } from 'src/components/shared/models/feedback/FeedbackFiltersValuesRequest';
import { FeedbackFiltersValuesResponse } from 'src/components/shared/models/feedback/FeedbackFiltersValuesResponse';
import { TUseAdvancedFilters, AdvancedFilters, TAdditionalFilter, TFilterItem } from '@arcanna/components';
import { useParams } from 'react-router-dom';
import { getTransformedAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { FeedbackAdvancedFilterRecord } from '../../../../../shared/models/feedback/FeedbackAdvancedFilterRecord';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useJobInfo } from 'src/data-access';
import { FeedbackBucketState } from 'src/constants';
import { ARCANNA_NO_DECISION, ARCANNA_NO_DECISION_DISPLAY_VALUE } from 'src/components/pages/JobEventExplorer/utils';
import { FeedbackUnifiedContext, FeedbackUnifiedContextType } from '../Unified/FeedbackUnified.context';

export const FeedbackFlowContext = createContext<FeedbackFlowContextType | undefined>(undefined);

export type DateSubtractType = 'oneWeek' | 'oneMonth' | 'oneYear' | 'today' | '';
export type BatchTableSort = {
  column: string;
  order: string;
};

export type TableFilter = Record<string, FilterValue | null>;

export type FeedbackStateType = {
  id: number;
  expanded: boolean;
  loading: boolean;
  startDate: Date;
  endDate: Date;
  dateSubtract: DateSubtractType;
  readonly: boolean;
  tableDynamicColumns: string[];
  tableData: FeedbackBatchBucketRow[];
  tablePage: number;
  tablePageSize: number;
  tableTotalSize: number;
  reloadFeedbackData: boolean;
  flushFeedbackData: boolean;
  editLabels: Map<string, string>;
  editLabelsCount: number;
  editAll: boolean;
  showCreateStaticRule: boolean;
  quickFilters: FeedbackQuickFilters;
  decisionFilters: string[];
  consensusFilters: string[];
  filtersFields: FeedbackFiltersFieldsRecord[];
  hasSelectedFeedback: boolean;
  selectedFeedback: FeedbackBatchBucketRow[];
  hoveredRowId: number;
  selectedRowKeys: string[];
  selectedRows: unknown[];
  structureColumns: unknown[];
  tableSort: BatchTableSort;
  tableFilters: TableFilter;
  bucketAlertsTableColumns: string[];
  bucketAlertsStructureColumns: unknown[];
  showAllBucketsUpdated: boolean;
  expandedBucketRow: FeedbackBatchBucketRow;
  tableStyle: TableStyle;
  excludeProcessed: boolean;
  includeProcessed: boolean;

  // eslint-disable-next-line
  advancedFilters: any[];
};

export type FeedbackFlowContextType = {
  state: FeedbackStateType;
  flushFeedbackState: () => void;
  setExpanded: (expanded: boolean) => void;
  setLoading: (loading: boolean) => void;
  setDates: (startDate: Date, endDate: Date) => void;
  getEndDateAsISO: () => string | null;
  getStartDateAsISO: () => string | null;
  clearEndDate: () => void;
  setDateSubtract: (startDate: Date, endDate: Date, dateSubtract: DateSubtractType) => void;
  setReloadFeedbackData: (reloadFeedbackData: boolean) => void;
  setReloadBucketAlertsData: (reloadBucketAlertsData: boolean) => void;
  setFlushFeedbackData: (flushFeedbackData: boolean) => void;
  setTableDynamicColumns: (tableDynamicColumns: string[]) => void;
  setTableData: (tableData: FeedbackBatchBucketRow[]) => void;
  setTablePage: (tablePage: number) => void;
  setTablePageSize: (tablePageSize: number) => void;
  setTableTotalSize: (tableTotalSize: number) => void;
  setEditIdAndLabel: (editId: string, editLabel: string) => void;
  setEditLabelOnSelected: (editLabel: string) => void;
  setEditAll: (editAllLabel: boolean, reloadTableData: boolean, loading: boolean) => void;
  setHasSelectedFeedback: (hasSelectedFeedback: boolean) => void;
  setSelectedFeedback: (selectedFeedback: FeedbackBatchBucketRow[]) => void;
  setHoveredRowId: (hoveredRowId: number) => void;
  setSelectedRowKeys: (selectedRowKeys: string[]) => void;
  setSelectedRows: (selectedRows: unknown[]) => void;
  setStructureColumns: (structureColumns: unknown[]) => void;
  setTableSort: (tableSort: BatchTableSort) => void;
  setAllAsEditOnSelected: (editLabel: string) => void;
  setBucketAlertsTableColumns: (bucketAlertsTableColumns: string[]) => void;
  setBucketAlertsStructureColumns: (bucketAlertsStructureColumns: unknown[]) => void;
  setFiltersFields: (filtersFields: EventExplorerFiltersFieldsRecord[]) => void;
  setTableFilters: (tableFilter: Record<string, FilterValue | null>) => void;
  setShowAllBucketsUpdated: (showAllBucketsUpdated: boolean) => void;
  setExpandedBucketRow: (expandedBucketRow: FeedbackBatchBucketRow) => void;
  openExpandedBucketRow: (expandedBucketRow: FeedbackBatchBucketRow) => void;
  closeExpandedBucketRow: () => void;
  setTableStyle: (style: TableStyle) => void;
  setExcludeProcessed: (excludeProcessed: boolean) => void;
  setIncludeProcessed: (includeProcessed: boolean) => void;
  setQuickFilters: (quickFilters: FeedbackQuickFilters) => void;
  clearQuickFilters: () => void;
  setDecisionFilters: (decisionFilters: string[]) => void;
  clearDecisionFilters: () => void;
  setConsensusFilters: (consensusFilters: string[]) => void;
  clearConsensusFilters: () => void;
  advancedFilters: TUseAdvancedFilters;
  additionalFilters: TAdditionalFilter[];
  clearAdditionalFilters: () => void;
};

const feedbackInitialState: FeedbackStateType = {
  id: Math.random(),
  expanded: false,
  loading: false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
  startDate: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
  endDate: null,
  excludeProcessed: false,
  includeProcessed: false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'boolean'.
  readonly: null,
  tableDynamicColumns: [],
  tableData: [],
  tablePage: 1,
  tablePageSize: 10,
  tableTotalSize: 0,
  reloadFeedbackData: true,
  flushFeedbackData: false,
  editLabels: new Map<string, string>(),
  editLabelsCount: 0,
  editAll: false,
  showCreateStaticRule: false,
  quickFilters: new FeedbackQuickFilters(),
  decisionFilters: [],
  consensusFilters: [],
  hasSelectedFeedback: false,
  selectedFeedback: [],
  hoveredRowId: -1,
  selectedRowKeys: [],
  selectedRows: [],
  dateSubtract: '',
  structureColumns: [],
  tableSort: {
    column: 'timestamp',
    order: 'desc'
  },
  tableFilters: {},
  bucketAlertsTableColumns: [],
  bucketAlertsStructureColumns: [],
  filtersFields: [],
  showAllBucketsUpdated: false,
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Feedba...
  expandedBucketRow: undefined,
  tableStyle: 'expanded',
  advancedFilters: []
};

export type FeedbackFlowFilters = {
  startDate: string | null;
  endDate: string | null;
  // eslint-disable-next-line
  advancedFilters: any[];
  quickFilters: FeedbackQuickFilters;
  tablePageSize: number;
  tablePage: number;
  tableSortColumn: string;
  tableSortOrder: string;
  excludeProcessed: string;
  includeProcessed: string;
};

export function mapSingleFilterValue(value: string, title: string, onDelete: (value: string) => void) {
  return {
    label: `${title}: ${value}`,
    onDelete: () => onDelete(value)
  };
}

// eslint-disable-next-line
export function getBucketsFromAggs(aggs: any, valueField: string) {
  let buckets = [];
  if (valueField in aggs) {
    buckets = aggs[valueField].buckets;
  }
  return buckets;
}

export function createValueOptions(buckets: { doc_count: number; key: string }[] = []) {
  // eslint-disable-next-line
  const recommendedValueList: any[] = [];

  if (buckets !== null) {
    buckets.forEach((item: { doc_count: number; key: string; key_as_string?: string }) => {
      const optionValue = item.key_as_string || item.key.toString();
      const label = `${optionValue} (${item.doc_count})`;

      if (!_.isEmpty(optionValue)) {
        recommendedValueList.push({ value: optionValue, label });
      }
    });
  }

  return recommendedValueList;
}

type FeedbackFlowProviderProps = {
  children: React.ReactNode;
};

export function FeedbackFlowProvider({ children }: FeedbackFlowProviderProps) {
  const { getFiltersFromQueryParams, setQueryParamsFilters } = useUrlFilters<FeedbackFlowFilters>();
  const { t } = useTranslation();

  const { id: filterJobId } = useParams<{ id: string }>();
  const [jobId, setJobId] = useState(null);

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  const jobInfoQuery = useJobInfo(jobId);

  const jobCustomLabels = useMemo(() => jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [], [jobInfoQuery]);

  const jsonConvert = useMemo(() => getJsonConvert(), []);

  // @ts-expect-error TS(2345): Argument of type '() => { startDate: Date | null; ...
  const [state, setState] = useState<FeedbackStateType>(() => {
    const filters = getFiltersFromQueryParams({
      startDate: feedbackInitialState.startDate ? feedbackInitialState.startDate.toISOString() : null,
      endDate: feedbackInitialState.endDate ? feedbackInitialState.endDate.toISOString() : null,
      advancedFilters: feedbackInitialState.advancedFilters,
      quickFilters: feedbackInitialState.quickFilters,
      tablePageSize: feedbackInitialState.tablePageSize,
      tablePage: feedbackInitialState.tablePage,
      tableSortColumn: feedbackInitialState.tableSort.column,
      tableSortOrder: feedbackInitialState.tableSort.order,
      excludeProcessed: `${feedbackInitialState.excludeProcessed}`,
      includeProcessed: `${feedbackInitialState.includeProcessed}`
    });

    return {
      ...feedbackInitialState,
      startDate: filters.startDate ? moment(filters.startDate).toDate() : null,
      endDate: filters.endDate ? moment(filters.endDate).toDate() : null,
      advancedFilters: filters.advancedFilters,
      quickFilters: filters.quickFilters,
      tablePageSize: Number(filters.tablePageSize),
      tablePage: Number(filters.tablePage),
      tableSort: {
        column: filters.tableSortColumn,
        order: filters.tableSortOrder
      },
      // all query params are parsed as strings and this will not change. See https://github.com/ljharb/qs/issues/91
      excludeProcessed: filters.excludeProcessed === 'true',
      includeProcessed: filters.includeProcessed === 'true'
    };
  });

  useEffect(() => {
    if (filterJobId !== null) {
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par...
      setJobId(parseInt(filterJobId, 10));
    }
  }, [filterJobId, setJobId]);

  const { post: postFiltersValues } = useFetch({
    path: getBackendEndpoint('/feedback/filters/values'),
    initialResponseData: null,
    load: false
  });

  const getEndDateAsISO = useCallback(() => (state.endDate !== null ? state.endDate.toISOString() : null), [state.endDate]);

  const getStartDateAsISO = useCallback(
    () => (state.startDate !== null ? state.startDate.toISOString() : null),
    [state.startDate]
  );

  const getValueOptions = useCallback(
    (fieldValue: string, activeFilters: TFilterItem[]): Promise<TOption[]> => {
      if (!fieldValue) {
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Promis...
        return;
      }

      return postFiltersValues(
        new FeedbackFiltersValuesRequest(
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
          parseInt(jobId, 10),
          fieldValue,
          getStartDateAsISO(),
          getEndDateAsISO(),
          state.excludeProcessed,
          state.includeProcessed,
          getTransformedAdvancedFilters(activeFilters) as FeedbackAdvancedFilterRecord[],
          state.quickFilters,
          state.decisionFilters,
          state.consensusFilters
        )
      ).then((res) => {
        const filtersValuesResponse = jsonConvert.deserializeObject(res.data.resource, FeedbackFiltersValuesResponse);
        const buckets = getBucketsFromAggs(filtersValuesResponse.recommended_values, fieldValue);

        return createValueOptions(buckets) ?? [];
      });
    },
    [
      jobId,
      state.quickFilters,
      state.decisionFilters,
      state.consensusFilters,
      getEndDateAsISO,
      getStartDateAsISO,
      postFiltersValues,
      jsonConvert,
      state.excludeProcessed,
      state.includeProcessed
    ]
  );

  const advancedFilters = AdvancedFilters.useAdvancedFilters({
    fields: state.filtersFields,
    getValueOptions,
    initialFilters: state.advancedFilters,
    onFiltersChange: () => {
      setTablePage(1);
    }
  });

  useEffect(() => {
    setQueryParamsFilters({
      startDate: state.startDate ? state.startDate.toISOString() : null,
      endDate: state.endDate ? state.endDate.toISOString() : null,
      quickFilters: state.quickFilters,
      tablePageSize: state.tablePageSize,
      tablePage: state.tablePage,
      tableSortColumn: state.tableSort.column,
      tableSortOrder: state.tableSort.order,
      advancedFilters: advancedFilters.activeFilters,
      excludeProcessed: `${state.excludeProcessed}`,
      includeProcessed: `${state.includeProcessed}`
    });
  }, [
    state.startDate,
    state.endDate,
    state.quickFilters,
    state.tablePageSize,
    state.tablePage,
    state.tableSort,
    setQueryParamsFilters,
    advancedFilters.activeFilters,
    state.excludeProcessed,
    state.includeProcessed
  ]);

  const flushFeedbackState = useCallback(() => {
    setState(feedbackInitialState);
    setState((current: FeedbackStateType) => ({
      ...current,
      ...feedbackInitialState
    }));
  }, []);

  const setTableSort = useCallback((tableSort: BatchTableSort) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tableSort
    }));
  }, []);

  const setTableFilters = useCallback((tableFilters: Record<string, FilterValue | null>) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tableFilters
    }));
  }, []);

  const setHoveredRowId = useCallback((hoveredRowId: number) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      hoveredRowId
    }));
  }, []);

  const setSelectedRowKeys = useCallback((selectedRowKeys: string[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      selectedRowKeys
    }));
  }, []);

  const setSelectedRows = useCallback((selectedRows: unknown[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      selectedRows
    }));
  }, []);

  const setStructureColumns = useCallback((structureColumns: unknown[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      structureColumns
    }));
  }, []);

  const setBucketAlertsStructureColumns = useCallback((bucketAlertsStructureColumns: unknown[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      bucketAlertsStructureColumns
    }));
  }, []);

  const setExpanded = useCallback((expanded: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      expanded
    }));
  }, []);

  const setLoading = useCallback((loading: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      loading
    }));
  }, []);

  const setFiltersFields = useCallback((filtersFields: FeedbackFiltersFieldsRecord[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      filtersFields
    }));
  }, []);

  const setQuickFilters = useCallback((quickFilters: FeedbackQuickFilters) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      quickFilters,
      reloadFeedbackData: true,
      tablePage: feedbackInitialState.tablePage
    }));
  }, []);

  const clearQuickFilters = useCallback(() => {
    setState((current: FeedbackStateType) => ({
      ...current,
      quickFilters: new FeedbackQuickFilters(),
      reloadFeedbackData: true,
      tablePage: feedbackInitialState.tablePage
    }));
  }, []);

  const setDecisionFilters = useCallback((decisionFilters: string[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      decisionFilters,
      reloadFeedbackData: true,
      tablePage: feedbackInitialState.tablePage
    }));
  }, []);

  const clearDecisionFilters = useCallback(() => {
    setState((current: FeedbackStateType) => ({
      ...current,
      decisionFilters: [],
      reloadFeedbackData: true,
      tablePage: feedbackInitialState.tablePage
    }));
  }, []);

  const setConsensusFilters = useCallback((consensusFilters: string[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      consensusFilters,
      reloadFeedbackData: true,
      tablePage: feedbackInitialState.tablePage
    }));
  }, []);

  const clearConsensusFilters = useCallback(() => {
    setState((current: FeedbackStateType) => ({
      ...current,
      decisionFilters: [],
      reloadFeedbackData: true,
      tablePage: feedbackInitialState.tablePage
    }));
  }, []);

  const setExcludeProcessed = useCallback((excludeProcessed: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      excludeProcessed,
      tablePage: feedbackInitialState.tablePage,
      reloadFeedbackData: true
    }));
  }, []);

  const setIncludeProcessed = useCallback((includeProcessed: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      includeProcessed,
      tablePage: feedbackInitialState.tablePage,
      reloadFeedbackData: true
    }));
  }, []);

  const setDates = useCallback((startDate: Date, endDate: Date) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      startDate,
      endDate,
      structureData: null,
      tableData: [],
      editLabels: new Map<string, string>(),
      editLabelsCount: 0,
      editAll: false,
      dateSubtract: '',
      tablePage: feedbackInitialState.tablePage,
      reloadFeedbackData: true
    }));
  }, []);

  const clearEndDate = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setDates(state.startDate, null);
  }, [setDates, state.startDate]);

  const setDateSubtract = useCallback((startDate: Date, endDate: Date, dateSubtract: DateSubtractType) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      startDate,
      endDate,
      dateSubtract,
      structureData: null,
      tableData: [],
      editLabels: new Map<string, string>(),
      editLabelsCount: 0,
      editAll: false,
      tablePage: feedbackInitialState.tablePage,
      reloadFeedbackData: true
    }));
  }, []);

  const setTableDynamicColumns = useCallback((tableDynamicColumns: string[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tableDynamicColumns
    }));
  }, []);

  const setBucketAlertsTableColumns = useCallback((bucketAlertsTableColumns: string[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      bucketAlertsTableColumns
    }));
  }, []);

  const setTableData = useCallback((tableData: FeedbackBatchBucketRow[]) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tableData,
      editLabels: new Map<string, string>(),
      editLabelsCount: 0,
      editAll: false,
      reloadFeedbackData: false,
      loading: false
    }));
  }, []);

  const setTablePage = useCallback((tablePage: number) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tablePage
    }));
  }, []);

  const setTablePageSize = useCallback((tablePageSize: number) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tablePageSize
    }));
  }, []);

  const setTableTotalSize = useCallback((tableTotalSize: number) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tableTotalSize
    }));
  }, []);

  const setReloadFeedbackData = useCallback((reloadFeedbackData: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      reloadFeedbackData
    }));
  }, []);

  const setReloadBucketAlertsData = useCallback((reloadBucketAlertsData: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      reloadBucketAlertsData
    }));
  }, []);

  const setFlushFeedbackData = useCallback((flushFeedbackData: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      flushFeedbackData
    }));
  }, []);

  const setEditIdAndLabel = useCallback(
    (editId: string, editLabel: string) => {
      const newEditLabels = new Map<string, string>(state.editLabels);
      if (newEditLabels.has(editId)) {
        newEditLabels.delete(editId);
      } else {
        newEditLabels.set(editId, editLabel);
      }

      const editLabelsCount = newEditLabels.size;
      setState((current: FeedbackStateType) => ({
        ...current,
        editLabels: newEditLabels,
        editLabelsCount,
        editAll: false
      }));
    },
    [state.editLabels]
  );

  const setEditLabels = useCallback(
    (editLabel: string, sourceData: FeedbackBatchBucketRow[]) => {
      const newEditLabels = new Map<string, string>(state.editLabels);
      sourceData.forEach((feedback: FeedbackBatchBucketRow) => {
        if (feedback.row_id) {
          newEditLabels.set(feedback.row_id, editLabel);
        }
      });
      const editLabelsCount = newEditLabels.size;
      setState((current: FeedbackStateType) => ({
        ...current,
        editLabels: newEditLabels,
        editLabelsCount,
        editAll: false
      }));
    },
    [state.editLabels]
  );

  const setEditLabelOnSelected = useCallback(
    (editLabel: string) => {
      setEditLabels(editLabel, state.selectedFeedback);
    },
    [setEditLabels, state.selectedFeedback]
  );

  const setAllAsEditOnSelected = useCallback(
    (editLabel: string) => {
      setEditLabels(editLabel, state.tableData);
    },
    [setEditLabels, state.tableData]
  );

  const setEditAll = useCallback((editAll: boolean, reloadTableData: boolean, loading: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      editAll,
      reloadFeedbackData: reloadTableData,
      loading
    }));
  }, []);

  const setHasSelectedFeedback = useCallback((hasSelectedFeedback: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      hasSelectedFeedback
    }));
  }, []);

  const setShowAllBucketsUpdated = useCallback((showAllBucketsUpdated: boolean) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      showAllBucketsUpdated
    }));
  }, []);

  const setSelectedFeedback = useCallback(
    (selectedFeedback: FeedbackBatchBucketRow[]) => {
      const newEditLabels = new Map<string, string>(state.editLabels);

      for (const key of state.editLabels.keys()) {
        const index = selectedFeedback.find((feedback: FeedbackBatchBucketRow) => feedback.row_id === key);
        if (index === undefined) {
          newEditLabels.delete(key);
        }
      }
      const editLabelsCount = newEditLabels.size;

      setState((current: FeedbackStateType) => ({
        ...current,
        selectedFeedback,
        editLabels: newEditLabels,
        editLabelsCount
      }));
    },
    [state.editLabels]
  );

  const setExpandedBucketRow = useCallback((expandedBucketRow: FeedbackBatchBucketRow) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      expandedBucketRow: expandedBucketRow
    }));
  }, []);

  const openExpandedBucketRow = useCallback(
    (expandedBucketRow: FeedbackBatchBucketRow) => {
      setExpandedBucketRow(expandedBucketRow);
    },
    [setExpandedBucketRow]
  );

  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const closeExpandedBucketRow = useCallback(() => setExpandedBucketRow(undefined), [setExpandedBucketRow]);

  const setTableStyle = (style: TableStyle) => {
    setState((current: FeedbackStateType) => ({
      ...current,
      tableStyle: style
    }));
  };

  // @ts-ignore
  const additionalFilters: TAdditionalFilter[] = useMemo(() => {
    const requiringAttentionFilters =
      state.quickFilters.requiring_attention &&
      state.quickFilters.requiring_attention.map((attention) => {
        return mapSingleFilterValue(t(`feedback:quickFilters:${attention}`), t('feedback:quickFilters:requiring_attention'), () =>
          setQuickFilters(
            new FeedbackQuickFilters(
              state.quickFilters.requiring_attention?.filter((contextStateAttention) => contextStateAttention != attention),
              state.quickFilters.feedback,
              state.quickFilters.training_status
            )
          )
        );
      });
    const feedbackFilters =
      state.quickFilters.feedback &&
      mapSingleFilterValue(t(`feedback:quickFilters:${state.quickFilters.feedback}`), t('feedback:quickFilters:feedback'), () =>
        setQuickFilters(
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
          new FeedbackQuickFilters(state.quickFilters.requiring_attention, null, state.quickFilters.training_status)
        )
      );
    const trainingStatusFilters =
      state.quickFilters.training_status &&
      mapSingleFilterValue(
        t(`feedback:quickFilters:${state.quickFilters.training_status}`),
        t('feedback:quickFilters:training_status'),
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
        () => setQuickFilters(new FeedbackQuickFilters(state.quickFilters.requiring_attention, state.quickFilters.feedback, null))
      );

    const decisionFilters = state.decisionFilters.map((filterValue) => {
      const label = jobCustomLabels?.find((customLabel) => customLabel.id === filterValue)?.name || filterValue;

      return mapSingleFilterValue(
        filterValue === ARCANNA_NO_DECISION ? ARCANNA_NO_DECISION_DISPLAY_VALUE : label,
        t('common:arcannaDecision'),
        () => setDecisionFilters(state.decisionFilters.filter((item) => item !== filterValue))
      );
    });

    const consensusFilters = state.consensusFilters.map((filterValue) => {
      const label = jobCustomLabels?.find((customLabel) => customLabel.id === filterValue)?.name || filterValue;

      return mapSingleFilterValue(
        filterValue === FeedbackBucketState.Undecided ? t('feedback:bucketExpand:undecided') : label,
        t('job:jobDashboardFlow:consensus'),
        () => setConsensusFilters(state.consensusFilters.filter((item) => item !== filterValue))
      );
    });

    const filters = [feedbackFilters, trainingStatusFilters, ...decisionFilters, ...consensusFilters].filter(Boolean);

    if (requiringAttentionFilters) {
      filters.push(...requiringAttentionFilters);
    }
    return filters;
  }, [
    t,
    jobCustomLabels,
    state.decisionFilters,
    state.consensusFilters,
    state.quickFilters,
    setQuickFilters,
    setConsensusFilters,
    setDecisionFilters
  ]);

  const clearAdditionalFilters = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setQuickFilters(new FeedbackQuickFilters(null, null, null));
    setDecisionFilters([]);
    setConsensusFilters([]);
  }, [setQuickFilters, setConsensusFilters, setDecisionFilters]);

  const value: FeedbackFlowContextType = {
    state,
    flushFeedbackState,
    setExpanded,
    setLoading,
    setDates,
    getEndDateAsISO,
    getStartDateAsISO,
    clearEndDate,
    setDateSubtract,
    setTableDynamicColumns,
    setReloadFeedbackData,
    setFlushFeedbackData,
    setTableData,
    setTablePage,
    setTablePageSize,
    setTableTotalSize,
    setEditIdAndLabel,
    setEditLabelOnSelected,
    setEditAll,
    setHasSelectedFeedback,
    setSelectedFeedback,
    setHoveredRowId,
    setSelectedRowKeys,
    setSelectedRows,
    setStructureColumns,
    setTableSort,
    setAllAsEditOnSelected,
    setReloadBucketAlertsData,
    setBucketAlertsTableColumns,
    setBucketAlertsStructureColumns,
    setFiltersFields,
    setTableFilters,
    setShowAllBucketsUpdated,
    setExpandedBucketRow,
    openExpandedBucketRow,
    closeExpandedBucketRow,
    setTableStyle,
    advancedFilters,
    setExcludeProcessed,
    setIncludeProcessed,
    setQuickFilters,
    clearQuickFilters,
    setDecisionFilters,
    clearDecisionFilters,
    setConsensusFilters,
    clearConsensusFilters,
    additionalFilters,
    clearAdditionalFilters
  };

  return <FeedbackFlowContext.Provider value={value}>{children}</FeedbackFlowContext.Provider>;
}

export function useFeedbackFlowContext(): FeedbackFlowContextType | FeedbackUnifiedContextType {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(FeedbackFlowContext) || useContext(FeedbackUnifiedContext);

  if (context === undefined) {
    throw new Error('useFeedbackFlowContext must be used within FeedbackFlowProvider');
  }

  return context;
}
