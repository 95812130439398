import { useTranslation } from 'react-i18next';
import { CustomLabel } from '@arcanna/components';
import { EIcon, Icon } from '@arcanna/generic';
import { CustomLabel as ICustomLabel } from 'src/components/shared/models';
import { Autocomplete, Box, InputAdornment, Stack, TextField } from '@mui/material';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { showInfoNotification } from 'src/components/shared/utilities/notification';

type SelectFeedbackProps = {
  jobInfos: JobInfoResponse[];
  disabled: boolean;
  selectedLabel: string;
  onSelect: (id: string) => void;
};

export function getCustomLabelsFromJobs(jobInfos: JobInfoResponse[]) {
  const labelsMap = new Map<string, ICustomLabel>();
  jobInfos.forEach((jobInfo) => {
    jobInfo.info?.advancedSettings.customLabels.forEach((customLabel) => {
      labelsMap.set(customLabel.id, customLabel);
    });
  });
  return Array.from(labelsMap.values());
}

export default function SelectFeedback({ jobInfos, disabled, selectedLabel, onSelect }: SelectFeedbackProps) {
  const { t } = useTranslation(['feedback']);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const customLabels = useMemo(() => getCustomLabelsFromJobs(jobInfos), [jobInfos]);

  useEffect(function updateWindowWidthState() {
    const updateWindowDimensions = () => {
      const currentWidth = window.innerWidth;
      setWindowWidth(currentWidth);
    };

    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const numButtons = useMemo(() => {
    const result = Math.min(Math.floor((windowWidth - 1150) / 168), 5);
    return result;
  }, [windowWidth]);
  const buttonCustomLabels: ICustomLabel[] = useMemo(() => customLabels.slice(0, numButtons), [customLabels, numButtons]);
  const selectCustomLabels: ICustomLabel[] = useMemo(() => customLabels.slice(numButtons), [customLabels, numButtons]);
  const allDecisionsText = t('feedback:allDecisions').replace('NUMBER_OF_LABELS', customLabels.length.toString());

  const convertCustomLabelToObj = useCallback(
    (labelInfo: ICustomLabel | undefined) => {
      if (labelInfo != undefined) {
        return {
          label: labelInfo.name,
          value: labelInfo.id,
          hexColor: labelInfo.hexColor,
          groupName: allDecisionsText
        };
      }
      return undefined;
    },
    [allDecisionsText]
  );

  const selectedLabelCompleteInfo = useMemo(() => {
    const labelInfo: ICustomLabel | undefined = customLabels.find((customLabel) => customLabel.id == selectedLabel);
    return convertCustomLabelToObj(labelInfo);
  }, [customLabels, selectedLabel, convertCustomLabelToObj]);

  const labelSelected = useCallback(
    (customLabel: ICustomLabel | null) => {
      if (customLabel == null) {
        onSelect('');
      } else {
        onSelect(customLabel.id);
        showInfoNotification(t('feedback:decisionSelected'), t('feedback:namedLabel').replace('LABEL', customLabel.name));
      }
    },
    [onSelect, t]
  );

  return (
    <Stack direction="row" gap="8px">
      {buttonCustomLabels.map((customLabel) => (
        <CustomLabel
          key={customLabel.id}
          id={customLabel.id}
          hexColor={customLabel.hexColor}
          name={customLabel.name}
          hasBorder
          disabled={disabled}
          disabledTooltip={false}
          disabledTooltipOnOverflow={true}
          showBorderOnlyOnHover
          className="customLabel"
          onClick={() => {
            labelSelected(customLabel);
          }}
          dataTestId={`${customLabel?.name}-feedback-button`}
          width={160}
        />
      ))}
      {selectCustomLabels.length > 0 && (
        <Autocomplete
          data-test-id="select-feedback"
          onChange={(_, customLabel) => {
            customLabel
              ? labelSelected(new ICustomLabel(customLabel.label, customLabel.hexColor, customLabel.value))
              : labelSelected(null);
          }}
          sx={{ width: '288px' }}
          size="small"
          value={selectedLabelCompleteInfo}
          // eslint-disable-next-line
          options={customLabels.map((customLabel) => convertCustomLabelToObj(customLabel)!)}
          renderOption={(props, option) => (
            <Box padding="16px" component="li" {...props}>
              <CustomLabel name={option.label} hexColor={option.hexColor} />
            </Box>
          )}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }

            return option.label;
          }}
          readOnly={disabled}
          groupBy={(option) => option.groupName}
          autoComplete
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={allDecisionsText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <Icon name={EIcon.ActionSearch} />
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    </Stack>
  );
}
