import { useMemo } from 'react';
import { ExternalLinkIcon, FileIcon, MessageChatIcon } from 'src/themes/icons';

import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { config } from 'src/config';

export const useHelpMenuItems = () => {
  const { t } = useTranslation(['user']);

  return useMemo(
    () => [
      {
        label: (
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
              <FileIcon />
              <span>{t('user:documentation')}</span>
            </Stack>
            <ExternalLinkIcon />
          </Stack>
        ),
        onClick: () => window.open(config.routes.documentation, '_blank')
      },
      {
        label: (
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
              <MessageChatIcon />
              <span>{t('user:support')}</span>
            </Stack>
            <ExternalLinkIcon />
          </Stack>
        ),
        onClick: () => window.open(config.routes.support, '_blank')
      }
    ],
    [t]
  );
};
