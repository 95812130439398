import { StackProps } from '@mui/material';
import StyledSpinner from './StyledSpinner.styles';

type TSpinnerProps = {
  isOverlay?: boolean;
} & StackProps;

function Spinner({ isOverlay, ...otherProps }: TSpinnerProps) {
  return (
    <StyledSpinner className="spinner" isOverlay={isOverlay} {...otherProps}>
      <div className="spinner__content"></div>
    </StyledSpinner>
  );
}

export default Spinner;
