import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AutoRetrainRecord')
export class AutoRetrainRecord {
  @JsonProperty('enabled', Boolean)
  enabled = false;
}

@JsonObject('SchedulersSettings')
export class SchedulersSettings {
  @JsonProperty('auto_retrain', AutoRetrainRecord, true)
  auto_retrain?: AutoRetrainRecord | null;
}
