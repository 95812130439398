import { TablePaginationConfig } from 'antd';
import { useFeedbackUnifiedContext } from './FeedbackUnified.context';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useCallback } from 'react';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { getMappedSortDirection } from '../Flow/helper';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { useParams } from 'react-router-dom';
import { FeedbackUpdateRecord } from 'src/components/shared/models/feedback/FeedbackUpdateRecord';
import { FeedbackUpdateRequest } from 'src/components/shared/models/feedback/FeedbackUpdateRequest';
import useUpdateBucketFeedback from 'src/data-access/feedback/useUpdateBucketFeedback';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';
import { BucketFeedbackRow } from '../../../../../../_srcMUI/pages/Feedback/FeedbackTable/helpers/helpers';
import { extractBucketIdFromRowId } from '../../../../../../_srcMUI/pages/Feedback/FeedbackTable/helpers/FeedbackTableRowId';

export function useFeedbackUnified() {
  const {
    state: feedbackState,
    setLoading: setIsLoading,
    setTablePage,
    setTablePageSize,
    setSelectedRowKeys,
    setSelectedRows,
    setSelectedFeedback,
    setReloadFeedbackData,
    setTableSort,
    setTableFilters,
    setEditAll
  } = useFeedbackUnifiedContext();

  const { ids } = useParams<{ ids: string }>();
  const jobIds = ids.split(',').map((id: string) => Number(id));

  const rowSelection = {
    selectedRowKeys: feedbackState.selectedRowKeys,
    selectedRows: feedbackState.selectedRows,
    // eslint-disable-next-line
    onChange: (selectedRowKeysChanged: any, selectedRowsChanged: any) => {
      setSelectedRowKeys(selectedRowKeysChanged);
      setSelectedRows(selectedRowsChanged);
      setSelectedFeedback(selectedRowsChanged);
    },
    // eslint-disable-next-line
    getCheckboxProps: (record: any) => ({
      disabled: record.enabled !== 'true'
    })
  };

  const getFilterSource = useCallback((name: string, filters: FeedbackFiltersFieldsRecord[]) => {
    const associatedFilter = filters.find((filter) => filter.name === name || filter.source === name);
    if (associatedFilter != null) {
      return associatedFilter.source;
    }
    return '';
  }, []);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<FeedbackBatchBucketRow> | SorterResult<FeedbackBatchBucketRow>[]
    ) => {
      let sortValues: SorterResult<FeedbackBatchBucketRow>;
      setTableFilters(filters);

      if (Array.isArray(sorter)) {
        [sortValues] = sorter;
      } else {
        sortValues = sorter;
      }

      let sortField = sortValues.columnKey?.toString();
      if (sortField != 'timestamp' && sortField !== undefined) {
        sortField = getFilterSource(sortField, feedbackState.filtersFields);
      }

      if (sortField != null && sortValues.order != null) {
        setTableSort({
          column: sortField,
          order: getMappedSortDirection(sortValues.order)
        });
      }

      if (pagination.current != null && pagination.pageSize != null) {
        setTablePage(pagination.current);
        setTablePageSize(pagination.pageSize);
      }
      setReloadFeedbackData(true);
    },
    [
      setTableSort,
      setReloadFeedbackData,
      setTablePage,
      setTablePageSize,
      setTableFilters,
      getFilterSource,
      feedbackState.filtersFields
    ]
  );

  const updateBucketFeedbackMutation = useUpdateBucketFeedback(undefined, jobIds[0]);

  const getFeedbackUpdateRecordsPerJob = useCallback(
    (editLabels: Map<string, BucketFeedbackRow>, confirmResult = false, skipNotRelabeled = false) => {
      // update records split by job_id (number)
      const feedbackUpdateRecords = new Map<number, FeedbackUpdateRecord[]>();
      for (const jobId of jobIds) {
        feedbackUpdateRecords.set(jobId, []);
      }

      for (const [id, feedbackRow] of editLabels) {
        if ((feedbackRow.relabeled == null || feedbackRow.relabeled == '') && skipNotRelabeled) {
          continue;
        }
        const bucketId = extractBucketIdFromRowId(id);
        const newLabel = confirmResult ? feedbackRow.result : feedbackRow.label;
        if (newLabel != null && newLabel != ARCANNA_NO_DECISION) {
          feedbackUpdateRecords.get(feedbackRow.jobId)?.push(new FeedbackUpdateRecord(bucketId, newLabel));
        }
      }
      return feedbackUpdateRecords;
    },
    [jobIds]
  );

  const reloadTable = useCallback(() => {
    setIsLoading(false);
    setEditAll(false);
    setReloadFeedbackData(true);
    setSelectedFeedback([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  }, [setIsLoading, setEditAll, setReloadFeedbackData, setSelectedFeedback, setSelectedRows, setSelectedRowKeys]);

  const allLabelsOnChangeConfirm = useCallback(() => {
    setIsLoading(true);
    const feedbackUpdateRecords = getFeedbackUpdateRecordsPerJob(feedbackState.editLabels);
    Promise.all(
      jobIds.map(async (jobId) => {
        const currentJobFeedbackRecords = feedbackUpdateRecords.get(jobId);
        if (currentJobFeedbackRecords && currentJobFeedbackRecords.length > 0) {
          await updateBucketFeedbackMutation.mutateAsync(new FeedbackUpdateRequest(jobId, currentJobFeedbackRecords));
        }
      })
    ).finally(() => {
      reloadTable();
    });
  }, [setIsLoading, getFeedbackUpdateRecordsPerJob, feedbackState.editLabels, updateBucketFeedbackMutation, jobIds, reloadTable]);

  const allLabelsOnChangeCancel = () => {
    setSelectedFeedback([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setEditAll(false);
    setReloadFeedbackData(true);
    setIsLoading(false);
  };

  return {
    rowSelection,
    handleTableChange,
    allLabelsOnChangeConfirm,
    allLabelsOnChangeCancel
  };
}
