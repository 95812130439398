import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useMemo } from 'react';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { expandColumnRender, labelRender, trainingStatusRender } from '../Flow/FeedbackTableColumns/FeedbackTableColumns';
import { useTranslation } from 'react-i18next';
import { useFeedbackBucketsUnifiedBatch } from 'src/data-access/feedback/useFeedbackBucketsUnifiedBatch';
import { useFeedbackUnifiedContext } from './FeedbackUnified.context';
import { getColumnValue } from 'src/components/shared/utilities/table';
import { FeedbackBatchColumnRecord } from 'src/components/shared/models/feedback/FeedbackBatchColumnRecord';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { DecisionPoint } from './DecisionPoint/DecisionPoint';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { config } from '../../../../../../config';
import { useFeedbackFilterFields } from 'src/data-access/feedback/useFeedbackFilterFields';
import { getTransformedAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { computeRowIdFromBucketIdAndJobId } from '../../../../../../_srcMUI/pages/Feedback/FeedbackTable/helpers/FeedbackTableRowId';

export function useFeedbackUnifiedBuckets() {
  const {
    state: feedbackState,
    setLoading,
    setReloadFeedbackData,
    setTableData,
    setTableTotalSize,
    setExpandedBucketRow,
    setFiltersFields,
    advancedFilters
  } = useFeedbackUnifiedContext();

  const { t } = useTranslation(['common', 'job', 'feedback']);
  const { ids } = useParams<{ ids: string }>();
  const jobIds = useMemo(() => ids.split(',').map((id: string) => Number(id)), [ids]);
  const queryClient = useQueryClient();
  const feedbackFiltersQuery = useFeedbackFilterFields(jobIds[0]);

  const renderJobTitle = (item: FeedbackBatchBucketRow) => (
    <>
      <span>{item.columns?.find((column) => column.name == 'job_title')?.value}</span>
    </>
  );

  const renderDecisionPoints = (item: FeedbackBatchBucketRow) => {
    const decisionPoints = item.columns?.filter((column) => column.is_feature) || [];
    return (
      <>
        {decisionPoints.map((decisionPoint: FeedbackBatchColumnRecord) => (
          <DecisionPoint key={decisionPoint.name} decisionPoint={decisionPoint} />
        ))}
      </>
    );
  };

  const getTableColumns = useCallback(() => {
    const editLabels = new Map<string, string>();
    feedbackState.editLabels.forEach((editLabelInfo) => {
      editLabels.set(editLabelInfo.rowId, editLabelInfo.label);
    });

    const columns: ColumnsType<FeedbackBatchBucketRow> = [
      {
        key: 'expand',
        width: 35,
        sorter: false,
        render: expandColumnRender(feedbackState.expandedBucketRow, setExpandedBucketRow),
        fixed: 'left'
      },
      Table.SELECTION_COLUMN,
      {
        title: t('feedback:arcannasDecisionAndConsensus'),
        key: 'label',
        width: 200,
        fixed: 'left',
        render: labelRender(
          feedbackState.jobInfos,
          editLabels,
          feedbackState.selectedRowKeys,
          feedbackState.prepareConfirm,
          feedbackState.prepareClear
        ),
        sorter: true
      },
      {
        title: t('feedback:trainingStatus'),
        key: 'training_status',
        width: 150,
        render: trainingStatusRender(feedbackState.jobInfos),
        sorter: true
      },
      {
        title: t('job:aiJob'),
        key: 'ai_job',
        width: 150,
        render: renderJobTitle
      },
      {
        title: t('feedback:decisionPoints'),
        key: 'decision_points',
        render: renderDecisionPoints
      }
    ];
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState.jobInfos, feedbackState.editLabels, feedbackState.prepareConfirm, feedbackState.prepareClear]);

  const feedbackBatchUnifiedQuery = useFeedbackBucketsUnifiedBatch({
    jobIds: jobIds,
    includeAllJobs: false,
    tablePageSize: feedbackState.tablePageSize,
    tablePage: (feedbackState.tablePage - 1) * feedbackState.tablePageSize,
    startDate: feedbackState.startDate,
    endDate: feedbackState.endDate,
    excludeProcessed: false,
    includeProcessed: false,
    tableSort: feedbackState.tableSort,
    advancedFilters: getTransformedAdvancedFilters(advancedFilters.activeFilters),
    quickFilters: feedbackState.quickFilters,
    decisionFilters: feedbackState.decisionFilters,
    consensusFilters: feedbackState.consensusFilters
  });

  useEffect(() => {
    if (feedbackBatchUnifiedQuery.isError) {
      showErrorNotification('Batch retrieval error', '', feedbackBatchUnifiedQuery.data?.request?.reason);
      setLoading(false);
    }
  }, [setLoading, feedbackBatchUnifiedQuery.isError, feedbackBatchUnifiedQuery.data]);

  useEffect(() => {
    if (feedbackState.reloadFeedbackData) {
      setReloadFeedbackData(false);
      queryClient.resetQueries([config.api.feedbackBucketsUnifiedBatch]);
    }
  }, [feedbackState.reloadFeedbackData, queryClient, setReloadFeedbackData]);

  useEffect(() => {
    if (feedbackBatchUnifiedQuery.data) {
      const batchResponse = feedbackBatchUnifiedQuery.data;
      const rows: FeedbackBatchBucketRow[] = [];
      batchResponse?.rows?.forEach((value: FeedbackBatchBucketRow) =>
        rows.push({
          ...value,
          row_id: computeRowIdFromBucketIdAndJobId(getColumnValue(value, 'id'), getColumnValue(value, 'job_id')),
          enabled: getColumnValue(value, 'enabled')
        })
      );
      setTableData(rows);
      setTableTotalSize(batchResponse.total || 0);
    }
  }, [feedbackBatchUnifiedQuery.data, setTableData, setTableTotalSize]);

  useEffect(() => {
    if (feedbackFiltersQuery.data) {
      setFiltersFields(feedbackFiltersQuery.data.fields_list || []);
    }
  }, [feedbackFiltersQuery.data, setFiltersFields]);

  return {
    getTableColumns
  };
}
