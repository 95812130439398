import { Box, BoxProps, styled } from '@mui/material';

const StyledDecisionPoint = styled(Box)<BoxProps>(() => ({
  display: 'inline-block',
  marginRight: '10px',
  minWidth: '380px',
  paddingBottom: '4px'
}));

export default StyledDecisionPoint;
