import { ReactElement } from 'react';
import { Layout } from 'antd';
import cx from 'classnames';

import { RouteType } from '../shared/models/route/RouteType';
import s from '../App/app.module.css';
import { Footer } from '../shared/components/Footer';
import routeS from './style.module.css';
import { JobNavigation } from '../pages/Main/Jobs/Navigation/JobNavigation';
import { Header, MigrationInProgressNotification } from '../../_srcMUI/layout';
import { config } from 'src/config';

const { Content } = Layout;

function ContentContainer(props: { children: ReactElement; footer: ReactElement; hasSideNavigation: boolean }) {
  return (
    <Content className={cx([routeS.content, { [routeS.sideNavigation]: props.hasSideNavigation }])}>
      <div className={s.container}>{props.children}</div>
      {props.footer}
    </Content>
  );
}

type PropType = {
  route: RouteType;
};

export function RouteItem({ route }: PropType) {
  let footer = <Footer route={route} />;
  if (route.path === '/login' || route.path === '/change-password' || route.path === config.routes.redirect) {
    footer = <></>;
  }

  const hasHeader = route.path !== '/login' && route.path !== '/change-password' && route.path !== config.routes.redirect;

  return (
    <>
      {hasHeader ? <Header /> : null}
      <Layout.Content className={cx({ [routeS.contentWrapper]: hasHeader })}>
        <Layout>
          {route.hasSideNavigation ? <JobNavigation /> : null}
          {route.sider ? <route.sider /> : null}
          {route.layout ? (
            <route.layout>
              <route.component />
              {footer}
            </route.layout>
          ) : (
            // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty...
            <ContentContainer footer={footer} hasSideNavigation={route.hasSideNavigation || Boolean(route.sider)}>
              <MigrationInProgressNotification />
              <route.component />
            </ContentContainer>
          )}
        </Layout>
      </Layout.Content>
    </>
  );
}
