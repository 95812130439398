import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/components/Button/Button';
import { useJobNavigate } from '../../../../../../shared/hooks/useJobNavigate';
import InlineNotification from '../../../../../../shared/components/InlineNotification';
import useBucketsUpdateInProgress from '../hooks/useBucketsUpdateInProgress';
import { useFeedbackBucketsCountTotal } from '../../../../../../../data-access';
import useIsRetrainAvailable from '../../../../../../../data-access/job/useIsRetrainAvailable';
import { config } from '../../../../../../../config';
import { useQueryClient } from 'react-query';
import { showInfoNotification } from '../../../../../../shared/utilities/notification';
import { useJobNotificationsContext } from '../JobNotifications.context';

type TBucketsUpdateProps = {
  jobId: number;
  reload?: () => void;
  fromPage?: string;
};

export function BucketsUpdateNotification({
  jobId,
  reload = () => {
    return;
  },
  fromPage
}: TBucketsUpdateProps) {
  const { t } = useTranslation(['feedback', 'retrain']);
  const queryClient = useQueryClient();

  const [showAllBucketsUpdated, setShowAllBucketsUpdated] = useState<boolean>(false);
  const bucketsUpdateInProgressStatus = useBucketsUpdateInProgress(jobId);
  const totalNumberOfBucketsQuery = useFeedbackBucketsCountTotal(jobId);
  const [hasUpdateStarted, setHasUpdateStarted] = useState<boolean>(false);
  const isRetrainAvailableQuery = useIsRetrainAvailable(jobId);
  const retrainEnabled = isRetrainAvailableQuery.data ? isRetrainAvailableQuery.data.enabled : false;
  const { setBucketsModelUpdateStarted } = useJobNotificationsContext();

  const { navigateToJobFeedbackFlow, navigateToJobOverview } = useJobNavigate();

  function hideBucketsUpdateNotification() {
    setHasUpdateStarted(false);
    navigateToJobOverview(`${jobId}`);
  }

  const renderFeedbackButton = () => {
    if (fromPage !== t('job:navigation.feedback')) {
      return (
        <Button
          text={t('feedback:goToFeedbackPageLabel')}
          dataTestId="go-to-feedback-btn"
          type="primaryNew"
          size="small"
          className={'p-0'}
          onClick={() => navigateToJobFeedbackFlow(`${jobId}`)}
        />
      );
    }
    return <></>;
  };

  const renderTrainNowButton = () => {
    if (fromPage !== t('job:navigation.overview')) {
      return (
        <Button
          text={t('retrain:trainNow')}
          dataTestId="train-now-btn"
          type="primaryNew"
          size="small"
          className={'p-0'}
          onClick={() => hideBucketsUpdateNotification()}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (bucketsUpdateInProgressStatus?.buckets_update_in_progress && !hasUpdateStarted) {
      setHasUpdateStarted(true);
    }
    // eslint-disable-next-line
  }, [bucketsUpdateInProgressStatus]);

  useEffect(() => {
    if (bucketsUpdateInProgressStatus?.buckets_update_percent === 100 && hasUpdateStarted && !showAllBucketsUpdated) {
      setShowAllBucketsUpdated(true);
      setBucketsModelUpdateStarted(true);
      showInfoNotification(t('job:bucketUpdater.started'), t('job:bucketUpdater.modelUpdateDescription'));
      isRetrainAvailableQuery.refetch({});
      totalNumberOfBucketsQuery.refetch({});
      queryClient.invalidateQueries([config.api.feedbackFiltersFields + jobId]);
      reload();
    } else if (hasUpdateStarted && fromPage === t('job:navigation.feedback')) {
      queryClient.resetQueries([config.api.feedbackBucketsBatch]);
    } else {
      setShowAllBucketsUpdated(false);
    }
    // eslint-disable-next-line
  }, [bucketsUpdateInProgressStatus?.buckets_update_percent]);

  if (hasUpdateStarted) {
    return (
      <>
        {!showAllBucketsUpdated && (
          <InlineNotification
            type={'spinPercent'}
            color={'blue'}
            text={t('job:notifications:creatingBuckets')}
            percent={bucketsUpdateInProgressStatus?.buckets_update_percent}
          >
            <Typography.Text className="color-nb-300">{t('job:notifications:stayTuned')}</Typography.Text>
            {renderFeedbackButton()}
          </InlineNotification>
        )}
        {showAllBucketsUpdated && retrainEnabled && (
          <InlineNotification
            type={'success'}
            color={'green'}
            text={t('job:notifications:decisionPointsUpdated')}
            closable={true}
          >
            <Typography.Text className="color-nb-300">
              {t('job:notifications:bucketsAvailableToTrain', { numberOfBuckets: totalNumberOfBucketsQuery.data })}
            </Typography.Text>
            {renderTrainNowButton()}
          </InlineNotification>
        )}
        {showAllBucketsUpdated && !retrainEnabled && (
          <InlineNotification
            type={'success'}
            color={'green'}
            text={t('job:notifications:decisionPointsUpdated')}
            closable={true}
          >
            {renderFeedbackButton()}
          </InlineNotification>
        )}
      </>
    );
  } else if (fromPage === t('job:navigation.decisionPoints') && !bucketsUpdateInProgressStatus?.has_features_selected) {
    return <InlineNotification type={'info'} color={'blue'} text={t('job:notifications:onceYouSelectDecisionPoints')} />;
  }
  return null;
}
