import { useEffect } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import InlineNotification from '../../../../../../shared/components/InlineNotification';
import { useJobBucketsModelUpdate } from '../../../../../../../data-access/job/useJobBucketsModelUpdate';
import { useJobNotificationsContext } from '../JobNotifications.context';

const UPDATE_FINISHED_PERCENTAGE = 100;

type TBucketsUpdateProps = {
  jobId: number;
  reload?: () => void;
};

export function BucketsModelUpdateNotification({
  jobId,
  reload = () => {
    return;
  }
}: TBucketsUpdateProps) {
  const { t } = useTranslation(['job']);

  const {
    state: jobNotificationState,
    setBucketsModelUpdateStarted,
    setBucketsModelUpdateFinished
  } = useJobNotificationsContext();

  const jobBucketsModelUpdateQuery = useJobBucketsModelUpdate(
    jobId,
    jobNotificationState.bucketsModelUpdateStarted && !jobNotificationState.bucketsModelUpdateFinished
  );

  useEffect(() => {
    if (jobBucketsModelUpdateQuery.data?.buckets_model_update_in_progress && !jobNotificationState.bucketsModelUpdateStarted) {
      setBucketsModelUpdateStarted(true);
    }
    if (
      jobBucketsModelUpdateQuery.data?.buckets_model_update_in_progress === false &&
      jobNotificationState.bucketsModelUpdateStarted
    ) {
      setBucketsModelUpdateFinished(true);
    }
    // eslint-disable-next-line
  }, [jobBucketsModelUpdateQuery.data?.buckets_model_update_in_progress, jobNotificationState.bucketsModelUpdateStarted]);

  useEffect(() => {
    if (
      jobBucketsModelUpdateQuery.data?.buckets_model_update_percent === UPDATE_FINISHED_PERCENTAGE &&
      jobNotificationState.bucketsModelUpdateStarted &&
      !jobNotificationState.bucketsModelUpdateFinished
    ) {
      setBucketsModelUpdateFinished(true);
      reload();
    }
    // eslint-disable-next-line
  }, [jobBucketsModelUpdateQuery.data?.buckets_model_update_percent]);

  if (jobNotificationState.bucketsModelUpdateStarted) {
    return (
      <>
        {jobNotificationState.bucketsModelUpdateFinished && (
          <InlineNotification type={'success'} color={'green'} text={t('job:notifications:bucketsUpdatedModel')} closable={true}>
            <Typography.Text className="color-nb-300">{t('job:notifications:latestDataAvailable')}</Typography.Text>
          </InlineNotification>
        )}
        {!jobNotificationState.bucketsModelUpdateFinished && (
          <InlineNotification
            type={'spinPercent'}
            color={'blue'}
            text={t('job:notifications:updatingBucketsModel')}
            percent={jobBucketsModelUpdateQuery.data?.buckets_model_update_percent}
          >
            <Typography.Text className="color-nb-300">{t('job:notifications:stayTuned')}</Typography.Text>
          </InlineNotification>
        )}
      </>
    );
  }
  return null;
}
