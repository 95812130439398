import { Grid } from '@mui/material';
import { ArcannaPagination, ArcannaTable } from 'src/components/shared/components';
import { useFeedbackUnifiedContext } from './FeedbackUnified.context';
import { useFeedbackUnified } from './FeedbackUnified.hook';
import { useFeedbackUnifiedBuckets } from './FeedbackUnifiedBuckets.hook';
import { FeedbackBatchBucketColumn } from 'src/components/shared/models/feedback/FeedbackBatchBucketColumn';
import { useFeedbackUnifiedFilters } from './FeedbackUnifiedFilters.hook';
import BucketDrawer from '@arcanna/pages/Feedback/BucketDrawer';
import BucketDrawerContextProvider from '@arcanna/pages/Feedback/BucketDrawer/context/BucketDrawerContextProvider';
import { useMemo } from 'react';
import { extractBucketIdFromRowId } from '../../../../../../_srcMUI/pages/Feedback/FeedbackTable/helpers/FeedbackTableRowId';

export function FeedbackUnifiedTable() {
  const { rowSelection, handleTableChange } = useFeedbackUnified();
  const { getTableColumns } = useFeedbackUnifiedBuckets();
  useFeedbackUnifiedFilters();

  const {
    state: feedbackState,
    setHoveredRowId,
    setTablePage,
    setTablePageSize,
    setReloadFeedbackData,
    closeExpandedBucketRow,
    advancedFilters,
    setExpandedBucketByIndex
  } = useFeedbackUnifiedContext();

  const expandedBucketIndex = useMemo(
    () => feedbackState.tableData.findIndex((bucket) => bucket.row_id === feedbackState.expandedBucketRow?.row_id),
    [feedbackState.expandedBucketRow, feedbackState.tableData]
  );

  return (
    <Grid container direction="column">
      <ArcannaTable
        onRow={(_, index) => ({
          onMouseEnter: () => {
            index != undefined && setHoveredRowId(index);
          },
          onMouseLeave: () => {
            setHoveredRowId(-1);
          }
        })}
        rowKey="row_id"
        fixedHeight
        bordered={false}
        dataSource={feedbackState.tableData}
        columns={getTableColumns()}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        onChange={handleTableChange}
        showSorterTooltip={false}
        pagination={false}
        arcannaSize={'middle'}
      />
      <div className="m-t-8">
        <ArcannaPagination
          total={feedbackState.tableTotalSize}
          current={feedbackState.tablePage}
          pageSize={feedbackState.tablePageSize}
          onChange={(page: number, pageSize: number) => {
            setTablePage(page);
            setTablePageSize(pageSize);
            setReloadFeedbackData(true);
          }}
          custom
          showSizeChanger={true}
          onShowSizeChange={(_, pageSize: number) => {
            setTablePage(1);
            setTablePageSize(pageSize);
            setReloadFeedbackData(true);
          }}
        />
      </div>
      <BucketDrawerContextProvider
        bucketContextValue={{
          filterFields: feedbackState.filtersFields,
          advancedFilters: advancedFilters,
          triggerBucketsReload: () => setReloadFeedbackData(true),
          expandedBucketIndex: expandedBucketIndex,
          bucketRowsCount: feedbackState.tableData.length,
          setExpandedBucketByIndex: setExpandedBucketByIndex
        }}
      >
        <BucketDrawer
          jobId={Number(
            feedbackState.expandedBucketRow?.columns?.find(
              (columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'job_id'
            )?.value
          )}
          bucketId={extractBucketIdFromRowId(feedbackState.expandedBucketRow?.row_id || '')}
          shortBucketId={
            feedbackState.expandedBucketRow?.columns?.find(
              (columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'bucket_id'
            )?.value || ''
          }
          expandedBucketRowIndex={expandedBucketIndex}
          handleDrawerClose={closeExpandedBucketRow}
        />
      </BucketDrawerContextProvider>
    </Grid>
  );
}
