import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { Row, Table as TanstackTable } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import { computeTableBodyCellCSSVariableName, computeTableHeaderCellCSSVariableName } from './Table.utils';
import { MemoizedTableBody } from './components/TableBody/TableBody';
import { EmptyState } from '@arcanna/generic';
import { TABLE_SIZES } from './components/TableSizeSelector/TableSizeSelector.constants';
import StyledTable from './StyledTable.styles';

type TTableProps<T> = {
  tableInstance: TanstackTable<T>;
  columnOrder?: string[];
  setColumnOrder?: Dispatch<SetStateAction<string[]>>;
  onRowClick?: (row: Row<T>, index: number) => void;
  isLoading?: boolean;
  noResultsMessageTitle: string;
  noResultsMessageSubtitle: string;
  emphasizedRows?: number[];
  isTableResizable?: boolean;
  customTableSizes?: string[];
  onTableSizeChange?: (tableSize: string) => void;
  isStrippedTable?: boolean;
  defaultTableSize?: TABLE_SIZES;
  getIsRowEmphasized?: (row: Row<T>) => boolean;
};

function Table<T>({
  tableInstance,
  columnOrder,
  setColumnOrder,
  onRowClick,
  isLoading,
  noResultsMessageTitle,
  noResultsMessageSubtitle,
  emphasizedRows,
  isTableResizable = true,
  customTableSizes,
  onTableSizeChange,
  isStrippedTable = false,
  defaultTableSize = TABLE_SIZES.small,
  getIsRowEmphasized
}: TTableProps<T>) {
  const [tableSize, setTableSize] = useState<string>(defaultTableSize);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active && over && active.id !== over.id) {
        setColumnOrder?.((prevColumnOrder: string[]) => {
          const oldIndex = prevColumnOrder.indexOf(active.id as string);
          const newIndex = prevColumnOrder.indexOf(over.id as string);

          return arrayMove(prevColumnOrder, oldIndex, newIndex);
        });
      }
    },
    [setColumnOrder]
  );

  const columnSizeVars = useMemo(() => {
    const headers = tableInstance.getFlatHeaders();

    const colSizes: { [key: string]: string } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[computeTableHeaderCellCSSVariableName(header.id)] = `${header.getSize()}px`;
      colSizes[computeTableBodyCellCSSVariableName(header.column.id)] = `${header.column.getSize()}px`;
    }
    return colSizes;
    // eslint-disable-next-line
  }, [tableInstance.getFlatHeaders(), tableInstance.getState().columnSizingInfo, tableInstance.getState().columnSizing]);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const availableActions = useMemo(
    () =>
      [tableInstance.options.enableMultiRowSelection, tableInstance.options.enableHiding, isTableResizable].filter(
        (item) => item === true
      ),
    [tableInstance.options.enableMultiRowSelection, tableInstance.options.enableHiding, isTableResizable]
  );

  const actionsColumnSize = useMemo(() => availableActions.length * 32, [availableActions]);

  const isActionsColumnVisible = useMemo(() => availableActions.length > 0, [availableActions]);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <StyledTable tableSize={tableSize}>
        <table
          style={{
            ...columnSizeVars,
            width: isLoading ? '100%' : tableInstance.getTotalSize() + actionsColumnSize,
            minWidth: '100%'
          }}
        >
          <TableHead<T>
            isActionsColumnVisible={isActionsColumnVisible}
            tableInstance={tableInstance}
            columnOrder={columnOrder}
            tableSize={tableSize}
            handleTableSizeChange={(newTableSize) => {
              setTableSize(newTableSize);
              onTableSizeChange?.(newTableSize);
            }}
            isTableResizable={isTableResizable}
            customTableSizes={customTableSizes}
            actionsColumnSize={actionsColumnSize}
          />
          {tableInstance.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody<T>
              isActionsColumnVisible={isActionsColumnVisible}
              tableInstance={tableInstance}
              onRowClick={onRowClick}
              isLoading={isLoading}
              emphasizedRows={emphasizedRows}
              tableSize={tableSize}
              actionsColumnSize={actionsColumnSize}
              isStrippedTable={isStrippedTable}
              getIsRowEmphasized={getIsRowEmphasized}
            />
          ) : (
            <TableBody<T>
              isActionsColumnVisible={isActionsColumnVisible}
              isLoading={isLoading}
              tableInstance={tableInstance}
              onRowClick={onRowClick}
              emphasizedRows={emphasizedRows}
              tableSize={tableSize}
              actionsColumnSize={actionsColumnSize}
              isStrippedTable={isStrippedTable}
              getIsRowEmphasized={getIsRowEmphasized}
            />
          )}
        </table>
        {!isLoading && !tableInstance.getRowModel().rows.length && (
          <EmptyState
            sx={{ marginTop: 0, width: tableInstance.getTotalSize() + actionsColumnSize, minWidth: '100%' }}
            title={noResultsMessageTitle}
            subtitle={noResultsMessageSubtitle}
            isCard
          />
        )}
      </StyledTable>
    </DndContext>
  );
}

export default Table;
