import { useJobInfo } from '../../../../data-access';
import InlineNotification from '../InlineNotification';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { EJobTrainingState } from '../../models/job/EJobTrainingState';

function AutomaticRetrainNotification({ jobId }: { jobId: number }) {
  const jobInfoQuery = useJobInfo(+jobId);

  const { t } = useTranslation(['job']);

  if (jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.AUTOMATIC_RETRAIN) {
    return (
      <InlineNotification type="spin" text={t('job:notifications.jobUpgradingToLatestVersion')}>
        <Typography.Text className="color-nb-300">{t('job:notifications.stayTuned')}</Typography.Text>
      </InlineNotification>
    );
  }
  return null;
}

export default AutomaticRetrainNotification;
