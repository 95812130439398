import { Stack, StackProps, styled } from '@mui/material';
import { TABLE_SIZES } from './components/TableSizeSelector/TableSizeSelector.constants';

const StyledTable = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'tableSize';
  }
})<StackProps & { tableSize: string }>(({ tableSize }) => ({
  overflowY: 'auto',
  marginBottom: 1,
  maxHeight: '62vh',

  ...(tableSize === TABLE_SIZES.medium
    ? {
        '.MuiTableCell-root': {
          padding: '10px 8px'
        }
      }
    : {}),
  ...(tableSize === TABLE_SIZES.large
    ? {
        '.MuiTableCell-root': {
          padding: '10px 8px',
          height: '62px'
        }
      }
    : {})
}));

export default StyledTable;
