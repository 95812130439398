import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { FeedbackBatchBucketResponse } from '../../components/shared/models/feedback/FeedbackBatchBucketResponse';
import axios, { AxiosResponse } from 'axios';
import { FeedbackBatchRequest } from '../../components/shared/models/feedback/FeedbackBatchRequest';
import { BatchTableSort } from '../../components/shared/models/event-explorer/EventExplorerBatchRequest';
import { FeedbackQuickFilters } from '../../components/shared/models/feedback/FeedbackQuickFilters';

type UseFeedbackBucketsBatch = {
  jobId: number;
  tablePageSize: number;
  tablePage: number;
  startDate: Date | null;
  endDate: Date | null;
  excludeProcessed: boolean;
  includeProcessed: boolean;
  tableSort: BatchTableSort;
  // eslint-disable-next-line
  advancedFilters: any[];
  quickFilters: FeedbackQuickFilters;
  decisionFilters: string[];
  consensusFilters: string[];
};
export function useFeedbackBucketsBatch({
  jobId,
  tablePageSize,
  tablePage,
  startDate,
  endDate,
  excludeProcessed,
  includeProcessed,
  tableSort,
  quickFilters,
  advancedFilters,
  decisionFilters,
  consensusFilters
}: UseFeedbackBucketsBatch) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const payload = new FeedbackBatchRequest(
    jobId,
    tablePageSize,
    tablePage * tablePageSize,
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable...
    startDate !== null ? startDate.toISOString() : null,
    endDate !== null ? endDate.toISOString() : null,
    excludeProcessed,
    includeProcessed,
    tableSort,
    advancedFilters,
    quickFilters,
    decisionFilters,
    consensusFilters
  );
  return useQuery<FeedbackBatchBucketResponse, Error>([config.api.feedbackBucketsBatch, payload], () =>
    axios
      .post(config.api.feedbackBucketsBatch, payload)
      .then((responseData: AxiosResponse<{ resource: FeedbackBatchBucketResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, FeedbackBatchBucketResponse)
      )
  );
}
