import { Pagination, Table } from '@arcanna/generic';
import { Row, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BucketDrawer from '../BucketDrawer';
import { FeedbackBatchBucketColumn } from 'src/components/shared/models/feedback/FeedbackBatchBucketColumn';
import { useFeedbackContext } from '../FeedbackContext/useFeedbackContext';
import { TFeedbackTableRowData } from './FeedbackTable.types';
import BucketDrawerContextProvider from '../BucketDrawer/context/BucketDrawerContextProvider';
import FeedbackBulkContextProvider from '../FeedbackBulkContext/FeedbackBulkContextProvider';
import { Stack } from '@mui/material';
import { getColumns, getTableRowData } from './FeedbackTable.utils';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import FeedbackFlowFooter from './components/FeedbackFlowFooter';

function FeedbackTable({ jobInfos }: { jobInfos: JobInfoResponse[] }) {
  const { t } = useTranslation(['feedback', 'job']);
  const feedbackContext = useFeedbackContext();
  const [editLabelOnSelected, setEditLabelOnSelected] = useState<string | undefined>();
  const [prepareConfirm, setPrepareConfirm] = useState<boolean>(false);
  const [prepareClear, setPrepareClear] = useState<boolean>(false);
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const expandedBucketIndex = feedbackContext.expandedBucketSlice.expandedBucket.index;
  const [activeTableSize, setActiveTableSize] = useState<string>('');

  const customTableSizes = useMemo(() => [t('feedback:minimized')], [t]);

  const getFilterSourceByName = useCallback(
    (name: string) => {
      if (feedbackContext.filtersSlice.filterFields == null || feedbackContext.filtersSlice.filterFields.length === 0) {
        return '';
      }

      const filterByName = feedbackContext.filtersSlice.filterFields.find(
        (filter) => filter.name === name || filter.source === name
      );

      return filterByName ? filterByName.source : '';
    },
    [feedbackContext.filtersSlice.filterFields]
  );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        batchBucketDynamicColumns: feedbackContext.tableSlice.batchBucketDynamicColumns,
        jobInfoCustomLabels: feedbackContext.jobInfoSlice.jobInfoCustomLabels,
        activeFilters: feedbackContext.filtersSlice.advancedFilters.activeFilters,
        addAdvancedFilter: feedbackContext.filtersSlice.advancedFilters.addAdvancedFilter,
        getFilterSourceByName,
        editLabelOnSelected,
        prepareConfirm,
        prepareClear,
        activeTableSize
      }),
    [
      feedbackContext.tableSlice.batchBucketDynamicColumns,
      t,
      feedbackContext.jobInfoSlice.jobInfoCustomLabels,
      feedbackContext.filtersSlice.advancedFilters.activeFilters,
      feedbackContext.filtersSlice.advancedFilters.addAdvancedFilter,
      getFilterSourceByName,
      editLabelOnSelected,
      activeTableSize,
      prepareConfirm,
      prepareClear
    ]
  );

  const data: TFeedbackTableRowData[] = useMemo(
    () => getTableRowData(feedbackContext.tableSlice.batchBucketRows),
    [feedbackContext.tableSlice.batchBucketRows]
  );

  useEffect(() => {
    // reset the columnOrder if the number of columns has changed
    if (!columnOrder.length || columnOrder.length !== columns.length) {
      setColumnOrder(columns.map((column) => column.id ?? ''));
    }
    // eslint-disable-next-line
  }, [columns]);

  const tableInstance = useReactTable<TFeedbackTableRowData>({
    data: data,
    columns: columns,
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    columnResizeMode: 'onChange',
    defaultColumn: {
      minSize: 120,
      maxSize: 800
    },
    state: {
      columnOrder,
      sorting: feedbackContext.tableSlice.sortingState
    },
    onSortingChange: feedbackContext.tableSlice.setSortingState,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    manualSorting: true
  });

  const onRowClick = useCallback(
    (_: Row<TFeedbackTableRowData>, rowIndex: number) => {
      feedbackContext.expandedBucketSlice.setExpandedBucketByIndex(rowIndex);
    },
    [feedbackContext.expandedBucketSlice]
  );

  const emphasizedRows = useMemo(() => (expandedBucketIndex !== undefined ? [expandedBucketIndex] : []), [expandedBucketIndex]);

  const handleCancelBulkFeedback = useCallback(() => {
    tableInstance.resetRowSelection();
    setEditLabelOnSelected(undefined);
  }, [tableInstance, setEditLabelOnSelected]);

  return (
    <>
      <Table.component<TFeedbackTableRowData>
        onRowClick={onRowClick}
        tableInstance={tableInstance}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        isLoading={feedbackContext.tableSlice.isBucketsLoading}
        emphasizedRows={emphasizedRows}
        noResultsMessageTitle={t('common:noResults')}
        noResultsMessageSubtitle={t('common:adjustSearchCriteria')}
        customTableSizes={customTableSizes}
        onTableSizeChange={setActiveTableSize}
      />
      <Pagination
        page={feedbackContext.tableSlice.paginationState.pageIndex}
        total={feedbackContext.tableSlice.totalRecordsCount}
        pageSize={feedbackContext.tableSlice.paginationState.pageSize}
        onPageChange={(pageIndex) => {
          tableInstance.resetRowSelection();
          feedbackContext.tableSlice.setPageIndex(pageIndex);
        }}
        onPageSizeChange={(pageSize) => {
          tableInstance.resetRowSelection();
          feedbackContext.tableSlice.setPageSize(pageSize);
        }}
      />
      <Stack sx={{ height: '80px' }}>
        <FeedbackBulkContextProvider
          jobId={feedbackContext.jobInfoSlice.jobId}
          selectedLabel={editLabelOnSelected}
          prepareConfirm={prepareConfirm}
          prepareClear={prepareClear}
          feedbackBulkContextValue={{
            totalBucketsCount: feedbackContext.tableSlice.totalRecordsCount,
            selectedBuckets: Object.keys(tableInstance.getState().rowSelection),
            bucketsRows: feedbackContext.tableSlice.batchBucketRows,
            setEditLabelOnSelected: setEditLabelOnSelected,
            handleCancelBulkFeedback,
            triggerBucketsReload: feedbackContext.tableSlice.triggerBucketsReload,
            setPrepareConfirm: setPrepareConfirm,
            setPrepareClear: setPrepareClear
          }}
        >
          <FeedbackFlowFooter
            enabled={Object.keys(tableInstance.getState().rowSelection).length > 0}
            jobInfos={jobInfos}
            prepareConfirm={prepareConfirm}
            prepareClear={prepareClear}
          />
        </FeedbackBulkContextProvider>
      </Stack>
      <BucketDrawerContextProvider
        bucketContextValue={{
          filterFields: feedbackContext.filtersSlice.filterFields,
          advancedFilters: feedbackContext.filtersSlice.advancedFilters,
          triggerBucketsReload: feedbackContext.tableSlice.triggerBucketsReload,
          expandedBucketIndex: feedbackContext.expandedBucketSlice.expandedBucket.index,
          bucketRowsCount: feedbackContext.tableSlice.batchBucketRows.length,
          setExpandedBucketByIndex: feedbackContext.expandedBucketSlice.setExpandedBucketByIndex
        }}
      >
        <BucketDrawer
          jobId={feedbackContext.jobInfoSlice.jobId}
          bucketId={
            feedbackContext.expandedBucketSlice.expandedBucket.data?.columns?.find(
              (columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'id'
            )?.value
          }
          shortBucketId={
            feedbackContext.expandedBucketSlice.expandedBucket.data?.columns?.find(
              (columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'bucket_id'
            )?.value
          }
          handleDrawerClose={feedbackContext.expandedBucketSlice.clearExpandedBucket}
          expandedBucketRowIndex={feedbackContext.expandedBucketSlice.expandedBucket.index}
        />
      </BucketDrawerContextProvider>
    </>
  );
}

export default FeedbackTable;
