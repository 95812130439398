import { Button, Result } from 'antd';
import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props = RouteComponentProps & {
  children: ReactNode;
};

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  static retry() {
    window.location.reload();
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch() {
    // console.error('Uncaught error:', error, errorInfo);
  }

  goHome = () => {
    this.setState({
      hasError: false
    });
    this.props.history.push('/');
  };

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <Result
            status="error"
            title="Something went wrong"
            subTitle="Please contact support to report this issue."
            extra={[
              <Button type="primary" key="home" onClick={this.goHome}>
                Go Home
              </Button>,
              <Button key="buy" onClick={ErrorBoundary.retry}>
                Retry
              </Button>
            ]}
          />
        </>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
