import { Stack } from '@mui/material';
import { TFeedbackTableRowData } from '../../FeedbackTable.types';
import { Spinner } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';
import BucketTrainingStatusTag from 'src/components/pages/Main/Jobs/Feedback/Flow/BucketTrainingStatusTag';
import { BucketTrainingStatus } from 'src/constants/BucketTrainingStatus';
import { enumFromValue } from 'src/components/shared/utilities/enumFromValue';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';
import { memo } from 'react';
import { RequireAttention } from '@arcanna/components';

type TTrainingStatusProps = {
  rowData: TFeedbackTableRowData;
  jobInfoCustomLabels: CustomLabelType[];
};

function TrainingStatus({ rowData, jobInfoCustomLabels }: TTrainingStatusProps) {
  const { t } = useTranslation();

  if (rowData.enabled === 'false') {
    return (
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Spinner />
        {t('common:updating')}
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <BucketTrainingStatusTag
        trainingStatus={rowData.training_status ? enumFromValue(rowData.training_status, BucketTrainingStatus) : null}
        trainedWith={jobInfoCustomLabels.find((customLabel) => customLabel.id === rowData.training_label)?.name ?? ''}
        style={{ marginRight: 0 }}
      />
      {rowData.training_status && enumFromValue(rowData.training_status, BucketTrainingStatus) === BucketTrainingStatus.NEW && (
        <RequireAttention
          confidenceScore={Math.round(Number(rowData.confidence_score))}
          isOutlier={rowData.outlier === 'true'}
          isConsensusScoreLow={rowData.low_consensus_score === 'True'}
          hasConsensusChangedFromLastRetrain={rowData.consensus_changed_from_last_retrain === 'True'}
          isConsensusFlipping={rowData.consensus_flipping === 'True'}
        />
      )}
    </Stack>
  );
}

export default memo(TrainingStatus);
