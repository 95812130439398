import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { Space, Typography } from 'antd';
import { useJobContext } from '../../Job.context';
import { useFeedbackFlowContext } from './FeedbackFlow.context';
import { FeedbackUpdateRecord } from '../../../../../shared/models/feedback/FeedbackUpdateRecord';
import { FeedbackUpdateRequest } from '../../../../../shared/models/feedback/FeedbackUpdateRequest';
import { FeedbackBatchBucketRow } from '../../../../../shared/models/feedback/FeedbackBatchBucketRow';
import { FeedbackFiltersFieldsRecord } from '../../../../../shared/models/feedback/FeedbackFiltersFieldsRecord';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { getMappedSortDirection } from './helper';
import style from './styles.module.css';
import { EmptyFolderIcon } from '../../../../../../themes/icons';
import useUpdateBucketFeedback from '../../../../../../data-access/feedback/useUpdateBucketFeedback';

export enum FeedbackTimeSelectorOption {
  ALL_TIME = 'all_time',
  LAST_30_DAYS = 'last_30_days',
  LAST_7_DAYS = 'last_7_days',
  LAST_24_HOURS = 'last_24_hours'
}

export function useFeedbackFlowHook() {
  const { state: jobState } = useJobContext();

  const {
    state: feedbackState,
    setDates,
    setTablePage,
    setTablePageSize,
    setEditAll,
    setReloadFeedbackData,
    setLoading,
    setSelectedFeedback,
    setSelectedRowKeys,
    setSelectedRows,
    setTableSort,
    setTableFilters,
    advancedFilters
  } = useFeedbackFlowContext();

  const { t } = useTranslation(['common', 'job', 'feedback']);

  const { id: filterJobId } = useParams<{ id: string }>();
  const [jobId, setJobId] = useState(null);

  // eslint-disable-next-line
  const feedbackTimeSelectorRecord: Record<FeedbackTimeSelectorOption, any> = {
    [FeedbackTimeSelectorOption.ALL_TIME]: {
      dateFrom: null,
      dateTo: moment().toDate()
    },
    [FeedbackTimeSelectorOption.LAST_30_DAYS]: {
      dateFrom: moment(Date.now() - 30 * 24 * 3600 * 1000).toDate(),
      dateTo: moment().toDate()
    },
    [FeedbackTimeSelectorOption.LAST_7_DAYS]: {
      dateFrom: moment(Date.now() - 7 * 24 * 3600 * 1000).toDate(),
      dateTo: moment().toDate()
    },
    [FeedbackTimeSelectorOption.LAST_24_HOURS]: {
      dateFrom: moment(Date.now() - 24 * 3600 * 1000).toDate(),
      dateTo: moment().toDate()
    }
  };

  const rowSelection = {
    selectedRowKeys: feedbackState.selectedRowKeys,
    selectedRows: feedbackState.selectedRows,
    // eslint-disable-next-line
    onChange: (selectedRowKeysChanged: any, selectedRowsChanged: any) => {
      setSelectedRowKeys(selectedRowKeysChanged);
      setSelectedRows(selectedRowsChanged);
      setSelectedFeedback(selectedRowsChanged);
    },
    // eslint-disable-next-line
    getCheckboxProps: (record: any) => ({
      disabled: record.enabled !== 'true'
    })
  };

  const handleFeedbackTimeSelection = (option: FeedbackTimeSelectorOption) => {
    const dates = feedbackTimeSelectorRecord[option];
    setDates(dates.dateFrom, dates.dateTo);
  };

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  const updateBucketFeedbackMutation = useUpdateBucketFeedback(null, jobId);

  const allLabelsOnChangeCancel = () => {
    setSelectedFeedback([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setEditAll(false, true, false);
  };

  useEffect(() => {
    if (filterJobId !== null) {
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par...
      setJobId(parseInt(filterJobId, 10));
    }
  }, [filterJobId, setJobId]);

  /*
  const handleRemovePredefinedFilter = useCallback(
    (filter: string | string[], filter_type: string) => {
      if (filter != null && filter_type === 'quickFilter') {
        const reqAttentionQF = filter === 'requiring_attention' ? null : feedbackState.quickFilters.requiring_attention;
        const feedbackQF = filter === 'feedback' ? null : feedbackState.quickFilters.feedback;
        const trainingStatusQF = filter === 'training_status' ? null : feedbackState.quickFilters.training_status;
        setQuickFilters(new FeedbackQuickFilters(reqAttentionQF, feedbackQF, trainingStatusQF));
      }
      if (filter != null && filter?.length > 0 && filter_type === Filters.DECISION) {
        const decisionFilters = feedbackState.decisionFilters.filter((filter_id) => !filter.includes(filter_id));
        setDecisionFilters(decisionFilters);
      }
      if (filter != null && filter?.length > 0 && filter_type === Filters.CONSENSUS) {
        const consensusFilters = feedbackState.consensusFilters.filter((filter_id) => !filter.includes(filter_id));
        setConsensusFilters(consensusFilters);
      }
    },
    [feedbackState, setQuickFilters, setDecisionFilters, setConsensusFilters]
  );
  */

  const allLabelsOnChangeConfirm = useCallback(() => {
    setLoading(true);
    const feedbackUpdateRecords: FeedbackUpdateRecord[] = [];
    for (const [id, label] of feedbackState.editLabels) {
      feedbackUpdateRecords.push(new FeedbackUpdateRecord(id, label as string));
    }
    updateBucketFeedbackMutation
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
      .mutateAsync(new FeedbackUpdateRequest(parseInt(jobId, 10), feedbackUpdateRecords))
      .finally(() => {
        setLoading(false);
        setEditAll(false, true, true);
        setSelectedFeedback([]);
        setSelectedRows([]);
        setSelectedRowKeys([]);
      });
    // eslint-disable-next-line
  }, [feedbackState.editLabels, jobId, setLoading, setSelectedFeedback, setSelectedRows, setSelectedRowKeys]);

  const getNoTableDataMessage = useCallback(() => {
    const hasSearchCriteria =
      advancedFilters.activeFilters.length > 0 ||
      feedbackState.quickFilters ||
      feedbackState.decisionFilters ||
      feedbackState.consensusFilters ||
      feedbackState.endDate;
    if (jobState.selectedJob) {
      if (!jobState.hasFeaturesSelected) {
        return (
          <Space size={0} className={style.noData}>
            <EmptyFolderIcon />
            <Space direction="vertical" className={style.noDataText}>
              <Typography.Text className={style.noDataTitle}>
                {t('feedback:feedbackTableNoDecisionPointsSelected')}
              </Typography.Text>
              <Space size={0}>
                <Typography.Text className={style.noDataSubText}>
                  {t('feedback:feedbackTableClickDecisionPoints')}&nbsp;
                </Typography.Text>
                <Typography.Text className={style.noDataDecisionPoints}>
                  {t('feedback:decisionPointsButtonLabel')}.
                </Typography.Text>
              </Space>
            </Space>
          </Space>
        );
      }
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (jobState.selectedJob.status.processedDocumentsCount !== 0 && jobState.hasFeaturesSelected && !hasSearchCriteria) {
        return <span className="p-l-8">{t('feedback:feedbackTableBucketsGeneratingOrProcessed')}</span>;
      }
    }
    if (hasSearchCriteria) {
      return (
        <span className="p-l-8" data-test-id="feedback-table-search-no-results">
          {t('feedback:feedbackTableSearchNoResults')}
        </span>
      );
    }
    return null;
    // eslint-disable-next-line
  }, [
    feedbackState.quickFilters,
    feedbackState.decisionFilters,
    feedbackState.consensusFilters,
    feedbackState.endDate,
    jobState.selectedJob,
    jobState.hasFeaturesSelected,
    t
  ]);

  const getJobTitle = useCallback(() => {
    if (jobState.selectedJob === null) {
      return null;
    }
    return `${jobState.selectedJob.title}`;
  }, [jobState.selectedJob]);

  const getColumnFilter = useCallback((name: string, filters: FeedbackFiltersFieldsRecord[]) => {
    const associatedFilter = filters.find((filter) => filter.name === name || filter.source === name);

    if (associatedFilter != null) {
      return associatedFilter.source;
    }
    return '';
  }, []);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<FeedbackBatchBucketRow> | SorterResult<FeedbackBatchBucketRow>[]
    ) => {
      let sortValues: SorterResult<FeedbackBatchBucketRow>;
      setTableFilters(filters);

      if (Array.isArray(sorter)) {
        [sortValues] = sorter;
      } else {
        sortValues = sorter;
      }

      let sortField = sortValues.columnKey?.toString();
      if (sortField != 'timestamp') {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        sortField = getColumnFilter(sortField, feedbackState.filtersFields);
      }

      setTableSort({
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
        column: sortField,
        // @ts-expect-error TS(2345): Argument of type 'string | null | undefined' is no...
        order: getMappedSortDirection(sortValues.order)
      });
      if (Object.keys(pagination).length != 0) {
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
        setTablePage(pagination.current);
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
        setTablePageSize(pagination.pageSize);
      }
      setReloadFeedbackData(true);
    },
    // eslint-disable-next-line
    [
      getColumnFilter,
      getMappedSortDirection,
      setTableSort,
      setReloadFeedbackData,
      setTablePage,
      setTablePageSize,
      feedbackState.filtersFields
    ]
  );

  const onReload = useCallback(() => {
    if (jobId != null) {
      setReloadFeedbackData(true);
    }
  }, [setReloadFeedbackData, jobId]);

  return {
    getTitle: getJobTitle,
    rowSelection,
    allLabelsOnChangeCancel,
    allLabelsOnChangeConfirm,
    t,
    handleTableChange,
    jobId,
    onReload,
    getNoTableDataMessage,
    handleFeedbackTimeSelection,

    // ADVANCED_FILTERS_NEW
    advancedFilters
  };
}
