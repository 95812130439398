import { ReactElement } from 'react';
import cx from 'classnames';
import Styles from './styles.module.css';
import { v4 as uuidv4 } from 'uuid';
import { NextAngleRightIcon } from '../../icons/NextAngleRightIcon';
import JobStatusToggle from '../JobStatusToggle/JobStatusToggle';

export interface BreadcrumbsProps {
  items: Array<Breadcrumb>;
  jobStatusToggle?: { jobId: number };
}

interface Breadcrumb {
  title: string;
  // eslint-disable-next-line
  onClick?: Function;
}

export const defaultBreadcrumbs: BreadcrumbsProps = {
  items: [],
  jobStatusToggle: undefined
};

export function ArcannaBreadcrumbs(props: BreadcrumbsProps = defaultBreadcrumbs): ReactElement {
  const moreThanOneItem = props.items.length > 1;
  if (!moreThanOneItem) {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ReactElemen...
    return null;
  }

  const renderBreadcrumbs = () =>
    props.items.map((breadcrumb, index) => {
      const isLastItem = index === props.items.length - 1;
      const hasLink = Object.keys(breadcrumb).includes('onClick');
      const titleCn = cx(Styles.breadcrumb, {
        [Styles.withoutLink]: !hasLink,
        [Styles.lighter]: isLastItem
      });

      return (
        <div id={`breadcrumb-${index}`} key={uuidv4()} className={Styles.breadcrumbContainer}>
          <div className={titleCn} onClick={() => breadcrumb?.onClick && breadcrumb.onClick()}>
            {breadcrumb.title}
          </div>
          <div className={Styles.separator}>{!isLastItem && <NextAngleRightIcon />}</div>
        </div>
      );
    });

  return (
    <div className={Styles.breadcrumbsContainer}>
      <div className={Styles.content}>
        <div className={Styles.items}>{renderBreadcrumbs()}</div>
        {props.jobStatusToggle ? <JobStatusToggle jobId={props.jobStatusToggle.jobId} /> : <></>}
      </div>
    </div>
  );
}

export default ArcannaBreadcrumbs;
