import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { JobFeatureSelectionSummaryRequest } from '../../components/shared/models/job/JobFeatureSelectionSummaryRequest';
import { JobFeatureSelectionSummaryResponse } from '../../components/shared/models/job/JobFeatureSelectionSummaryResponse';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { JobFeatureSelectionSummaryDetails } from '../../components/shared/models/job/JobFeatureSelectionSummaryDetails';

type UseFeatureSelectionSummary = {
  jobId: number;
  skipInactive?: boolean;
  select?: (data: JobFeatureSelectionSummaryDetails[]) => JobFeatureSelectionSummaryDetails[];
};
export function useFeatureSelectionSummary({ jobId, skipInactive, select }: UseFeatureSelectionSummary) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const requestPayload = new JobFeatureSelectionSummaryRequest(jobId, skipInactive);

  return useQuery<JobFeatureSelectionSummaryDetails[], Error>(
    [config.api.jobFeatureSelectionSummary, requestPayload],
    () =>
      // @ts-expect-error TS(2322): Type 'Promise<JobFeatureSelectionSummaryDetails[] ...
      axios
        .post(config.api.jobFeatureSelectionSummary, requestPayload)
        .then((responseData: AxiosResponse<{ resource: JobFeatureSelectionSummaryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobFeatureSelectionSummaryResponse)
        )
        .then((response: JobFeatureSelectionSummaryResponse) => response.features),
    {
      staleTime: Infinity,
      select
    }
  );
}
