import { useMemo } from 'react';
import { Col, Row, Spin } from 'antd';
import { withTransaction } from '@elastic/apm-rum-react';
import { useTranslation } from 'react-i18next';
import style from './JobOverview.module.css';
import { useJobTitle } from '../Retrain/hooks/usePageTitle';
import { useParams } from 'react-router-dom';
import JobOverviewTotalAlerts from './JobOverviewTotalAlerts';
import { useJobOverviewSplitStats } from '../../../../../data-access/job/useJobOverviewSplitStats';
import { useJobOverviewContext } from './JobOverview.context';
import moment from 'moment';
import { useJobAlertsTimeseries, useJobBucketStates, useJobInfo } from '../../../../../data-access';
import { JobAlertsTimeseriesRequest } from '../../../../shared/models';
import { JobCategory } from '../helper';
import { ArcannaBreadcrumbs } from '../../../../shared/components';
import { useJobNavigate } from '../../../../shared/hooks/useJobNavigate';
import { PeriodOptions } from '../../../../shared/models/filters/period';
import JobOverviewLabels from './JobOverviewLabels';
import ArcannaDecisionTimeseries from '@arcanna/pages/Feedback/BucketDrawer/components/DecisionPointsBody/components/ArcannaDecisionTimeseries/ArcannaDecisionTimeseries';
import { RefreshButton } from '@arcanna/components';
import JobNotifications from '../components/JobNotifications';
import { useEstablishStartNotificationType } from '../components/JobNotifications/hooks/useEstablishStartNotificationType';

const JobOverview = () => {
  const { t } = useTranslation(['job', 'common', 'jobOverview']);

  const { id } = useParams<{ id: string }>();
  const jobId = +id;

  const { state: jobOverviewState } = useJobOverviewContext();
  // eslint-disable-next-line
  const now = useMemo(() => moment().toDate(), [jobOverviewState.startDate]);
  const endDate = useMemo(
    () => (jobOverviewState.period === PeriodOptions.ALL_TIME ? null : now),
    [jobOverviewState.period, now]
  );

  useJobTitle(jobId);

  const jobInfoQuery = useJobInfo(+jobId);

  const jobCategory = jobInfoQuery.data?.info?.jobDetails?.category_id;
  const jobOverViewSplitStatsQuery = useJobOverviewSplitStats({ jobId, dateFrom: jobOverviewState.startDate });
  const jobBucketStatesQuery = useJobBucketStates({ jobId, dateFrom: jobOverviewState.startDate });
  const jobAlertsTimeseriesQuery = useJobAlertsTimeseries(
    // @ts-expect-error TS(2345): Argument of type 'Date | null' is not assignable t...
    new JobAlertsTimeseriesRequest(jobId, jobOverviewState.startDate, endDate),
    jobOverviewState.selectedLabels
  );

  const showSpinner =
    jobBucketStatesQuery.isLoading ||
    jobOverViewSplitStatsQuery.isLoading ||
    jobAlertsTimeseriesQuery.isLoading ||
    jobBucketStatesQuery.isRefetching ||
    jobOverViewSplitStatsQuery.isRefetching ||
    jobAlertsTimeseriesQuery.isRefetching;

  const handleRefresh = async () => {
    await Promise.all([
      jobBucketStatesQuery.refetch({ throwOnError: true }),
      jobOverViewSplitStatsQuery.refetch({ throwOnError: true }),
      jobAlertsTimeseriesQuery.refetch({ throwOnError: true })
    ]);
  };

  const { navigateToJobs } = useJobNavigate();

  const { notificationType } = useEstablishStartNotificationType(jobId, t('job:navigation.overview'));

  return (
    <Spin tip={t('common:loading')} spinning={showSpinner}>
      <ArcannaBreadcrumbs
        items={[
          {
            title: t('homepage:AIJobsCardTitle'),
            onClick: navigateToJobs
          },
          {
            title: jobInfoQuery.data?.info?.title ?? ''
          },
          {
            title: t('job:navigation.overview')
          }
        ]}
        jobStatusToggle={{ jobId }}
      />
      <JobNotifications fromPage={t('job:navigation.overview')} startNotificationType={notificationType} />
      <div className={style.container}>
        <Row justify="space-between" className="p-b-12">
          <Col>
            <RefreshButton onRefresh={handleRefresh} />
          </Col>
        </Row>
        <div className={style.statsContainer}>
          <Row>
            <JobOverviewTotalAlerts
              jobId={jobId}
              // @ts-expect-error TS(2322): Type 'JobBucketState[] | undefined' is not assigna...
              bucketStates={jobBucketStatesQuery.data?.states}
              // @ts-expect-error TS(2322): Type 'JobOverviewSplitStatsTotal | undefined' is n...
              splitStatsTotal={jobOverViewSplitStatsQuery.data?.total}
              isRcaJob={jobCategory === JobCategory.RCA}
            />
          </Row>
          <Row className="p-24">
            <Col span={24}>
              <JobOverviewLabels jobId={jobId} />
            </Col>
          </Row>
          <ArcannaDecisionTimeseries jobId={jobId} shortBucketId={undefined} />
        </div>
      </div>
    </Spin>
  );
};
export default withTransaction('JobEventExplorer', 'component')(JobOverview);
