import { Col, Row, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useUserKeyHook } from './UserKey.hook';
import Button from '../../../shared/components/Button/Button';
import { useUserKeyContext } from './UserKey.context';
import { permissions } from '../../../shared/static/ComponentsPermissions';
import PermissionsCheck from '../../../shared/components/Permissions/Permissions';
import style from './UserKey.module.css';
import cx from 'classnames';

export function UserKey() {
  const { t } = useTranslation(['common', 'reporting']);

  const { state: userState } = useUserKeyContext();

  const { navigateToCreate, loadingSummary, getUserKeyColumns } = useUserKeyHook();

  return (
    <div>
      <Row>
        <Col>
          <h2 className={cx([style.headline, 'm-b-40 headline'])}>{t('user:userKey:landingPageTitle')}</h2>
        </Col>
      </Row>
      <Row justify="space-between" className="m-b-10">
        <Col className="flex flex-align-center">
          <PermissionsCheck
            permissions={[permissions.userKeyWrite]}
            action={permissions.action.hiddenAction}
            testId={'create-new-key'}
          >
            <Button
              title={t('user:userKey.createNewKeyTitle')}
              dataTestId="new-api-key"
              type="primaryNew"
              size="small"
              icon={<FontAwesomeIcon icon="plus" className="p-l-8" />}
              iconPosition="right"
              onClick={() => navigateToCreate()}
            >
              <span>{t('user:userKey.createNewKeyTitle')}</span>
            </Button>
          </PermissionsCheck>
        </Col>
      </Row>
      <Table
        rowKey="_id"
        loading={loadingSummary}
        bordered={false}
        className={style.table}
        dataSource={userState.tableData}
        columns={getUserKeyColumns()}
        showSorterTooltip={false}
      />
    </div>
  );
}
