import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { JobStatusChangeRequest } from '../../components/shared/models/job/JobStatusChangeRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { config } from '../../config';

export enum JobStatusAction {
  START = 'start',
  STOP = 'stop'
}

export function useJobChangeStatus(jobId: number) {
  const { t } = useTranslation(['common', 'job']);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const cleanReason = (value?: string) => {
    if (value) {
      return ' ' + value;
    }
    return '';
  };

  const showErrorMessageByAction = React.useCallback(
    (action: JobStatusAction, reason?: string) => {
      if (action == JobStatusAction.START) {
        showErrorNotification(t('common:actionCompleted'), t('job:startedError') + cleanReason(reason));
      }
      if (action == JobStatusAction.STOP) {
        showErrorNotification(t('common:actionCompleted'), t('job:stoppedError') + cleanReason(reason));
      }
    },
    // eslint-disable-next-line
    [showErrorNotification]
  );

  const showSuccessMessageByAction = React.useCallback(
    (action: JobStatusAction, reason?: string) => {
      if (action == JobStatusAction.START) {
        showSuccessNotification(t('common:actionCompleted'), t('job:startedSuccessfully') + cleanReason(reason));
      }
      if (action == JobStatusAction.STOP) {
        showSuccessNotification(t('common:actionCompleted'), t('job:stoppedSuccessfully') + cleanReason(reason));
      }
    },
    // eslint-disable-next-line
    [showErrorNotification]
  );

  return useMutation(
    (action: JobStatusAction) =>
      axios.post<{ resource: { request: CommonResponse } }>(
        config.api.jobChangeStatus.replace(':action', action.toString()),
        new JobStatusChangeRequest(jobId)
      ),
    {
      onSuccess: (responseData: AxiosResponse<{ resource: { request: CommonResponse } }>, usedAction: JobStatusAction) => {
        let isSuccess = false;

        if (!responseData || !responseData.data.resource) {
          showErrorMessageByAction(usedAction);
          return;
        }

        const actionResponse = jsonConvert.deserializeObject(responseData.data.resource.request, CommonResponse);

        if (actionResponse.status === 'OK') {
          showSuccessMessageByAction(usedAction);
          isSuccess = true;
        } else {
          showErrorMessageByAction(usedAction, actionResponse.reason);
        }

        return new Promise((resolve, reject) => {
          if (isSuccess == true) {
            resolve(actionResponse);
          } else {
            reject(actionResponse);
          }
        });
      },
      onError: (error: { response: AxiosResponse<{ resource: { request: CommonResponse } }> }, usedAction: JobStatusAction) => {
        const errorResponse = jsonConvert.deserializeObject(error.response.data.resource.request, CommonResponse);

        showErrorMessageByAction(usedAction, errorResponse.reason);
      }
    }
  );
}
