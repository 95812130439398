import { ReactNode, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { FeedbackBulkContext } from './FeedbackBulkContext';
import { FeedbackUpdateRecord } from 'src/components/shared/models/feedback/FeedbackUpdateRecord';
import useUpdateBucketFeedback from 'src/data-access/feedback/useUpdateBucketFeedback';
import { FeedbackUpdateRequest } from 'src/components/shared/models/feedback/FeedbackUpdateRequest';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';
import { BucketFeedbackRow, splitBucketRowsByJobId } from '@arcanna/pages/Feedback/FeedbackTable/helpers/helpers';
import { extractBucketIdFromRowId } from '../FeedbackTable/helpers/FeedbackTableRowId';
import useClearFeedback from 'src/data-access/feedback/useClearFeedback';

type TFeedbackBulkContextProviderProps = {
  children: ReactNode;
  jobId?: number | undefined;
  selectedLabel: string | undefined;
  prepareConfirm: boolean;
  prepareClear: boolean;
  feedbackBulkContextValue: {
    totalBucketsCount: number;
    selectedBuckets: string[];
    bucketsRows: FeedbackBatchBucketRow[];
    handleCustomOnSave?: () => void;
    setEditLabelOnSelected: (newLabel: string) => void;
    handleCancelBulkFeedback: () => void;
    triggerBucketsReload: () => void;
    setPrepareConfirm: (prepareConfirm: boolean) => void;
    setPrepareClear: (prepareClear: boolean) => void;
  };
};

function FeedbackBulkContextProvider({
  children,
  feedbackBulkContextValue,
  jobId,
  selectedLabel,
  prepareConfirm,
  prepareClear
}: TFeedbackBulkContextProviderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutateAsync: updateBucketFeedback } = useUpdateBucketFeedback(undefined, jobId);
  const { mutateAsync: clearBucketFeedback } = useClearFeedback();
  const selectedBucketsIds = useMemo(
    () =>
      // do this to extract bucketIds only when we have composed table rows ids
      feedbackBulkContextValue.selectedBuckets.map((rowId) => extractBucketIdFromRowId(rowId)),
    [feedbackBulkContextValue.selectedBuckets]
  );

  const checkSelectedBuckets = useCallback(
    (columnName: string, valueNotEqual: string | null) => {
      if (selectedBucketsIds.length == 0) {
        return false;
      }
      const rowsMatchingCriteria = feedbackBulkContextValue.bucketsRows.filter((bucketRow: FeedbackBatchBucketRow) => {
        let columnValue: string | undefined | null = null;
        let bucketId = '';
        for (const column of bucketRow.columns || []) {
          if (column.name == 'id' && column.value !== undefined) {
            bucketId = column.value;
          }
          if (column.name == columnName) {
            columnValue = column.value;
          }
        }

        return selectedBucketsIds.includes(bucketId) && columnValue != valueNotEqual;
      });
      return rowsMatchingCriteria.length > 0;
    },
    [feedbackBulkContextValue.bucketsRows, selectedBucketsIds]
  );

  const getSelectedBucketsRows = useCallback(() => {
    return feedbackBulkContextValue.bucketsRows.filter((bucketRow: FeedbackBatchBucketRow) => {
      const bucketId = bucketRow.columns?.find((column) => column.name == 'id')?.value;
      return selectedBucketsIds.includes(bucketId || '');
    });
  }, [feedbackBulkContextValue.bucketsRows, selectedBucketsIds]);

  const getFeedbackUpdateRecordsPerJob = useCallback(
    (editLabels: Map<string, BucketFeedbackRow>, confirmResult = false, skipNotRelabeled = false) => {
      const feedbackUpdateRecords = new Map<number, FeedbackUpdateRecord[]>();

      for (const [id, feedbackRow] of editLabels) {
        if ((feedbackRow.relabeled == null || feedbackRow.relabeled == '') && skipNotRelabeled) {
          continue;
        }
        const bucketId = extractBucketIdFromRowId(id);
        const newLabel = confirmResult ? feedbackRow.result : feedbackRow.label;
        if (newLabel != null && newLabel != ARCANNA_NO_DECISION) {
          if (feedbackUpdateRecords.get(feedbackRow.jobId) === undefined) {
            feedbackUpdateRecords.set(feedbackRow.jobId, []);
          }
          feedbackUpdateRecords.get(feedbackRow.jobId)?.push(new FeedbackUpdateRecord(bucketId, newLabel));
        }
      }
      return feedbackUpdateRecords;
    },
    []
  );

  const canConfirmLabels = useCallback(() => {
    return checkSelectedBuckets('label', ARCANNA_NO_DECISION);
  }, [checkSelectedBuckets]);

  const canClearLabels = useCallback(() => {
    return checkSelectedBuckets('relabeled', null);
  }, [checkSelectedBuckets]);

  const handleLabelsClear = useCallback(() => {
    if (selectedBucketsIds.length == 0) {
      return;
    }
    feedbackBulkContextValue.setPrepareClear(true);
    feedbackBulkContextValue.setPrepareConfirm(false);
  }, [selectedBucketsIds, feedbackBulkContextValue]);

  const handleLabelsConfirm = useCallback(() => {
    if (selectedBucketsIds.length == 0) {
      return;
    }
    feedbackBulkContextValue.setPrepareConfirm(true);
    feedbackBulkContextValue.setPrepareClear(false);
  }, [selectedBucketsIds, feedbackBulkContextValue]);

  const saveLabelsConfirm = useCallback(() => {
    setIsLoading(true);
    // TODO: Get selectedBucketsRows from react-table once unified view is migrated
    const selectedBucketsRows = getSelectedBucketsRows();
    const feedbackRows = splitBucketRowsByJobId(new Map<string, BucketFeedbackRow>(), '', selectedBucketsRows);
    const feedbackUpdateRecords = getFeedbackUpdateRecordsPerJob(feedbackRows, true, false);
    const jobIds = Array.from(feedbackUpdateRecords.keys());
    Promise.all(
      jobIds.map(async (currentJobId) => {
        const currentJobFeedbackRecords = feedbackUpdateRecords.get(currentJobId);
        if (currentJobFeedbackRecords && currentJobFeedbackRecords.length > 0) {
          await updateBucketFeedback(new FeedbackUpdateRequest(currentJobId, currentJobFeedbackRecords));
        }
      })
    ).finally(() => {
      setIsLoading(false);
      feedbackBulkContextValue.setPrepareConfirm(false);
      feedbackBulkContextValue.triggerBucketsReload();
      feedbackBulkContextValue.handleCancelBulkFeedback();
    });
  }, [setIsLoading, getSelectedBucketsRows, getFeedbackUpdateRecordsPerJob, updateBucketFeedback, feedbackBulkContextValue]);

  const saveLabelsClear = useCallback(() => {
    setIsLoading(true);
    // TODO: Get selectedBucketsRows from react-table once unified view is migrated
    const selectedBucketsRows = getSelectedBucketsRows();
    const feedbackRows = splitBucketRowsByJobId(new Map<string, BucketFeedbackRow>(), '', selectedBucketsRows);
    const feedbackUpdateRecords = getFeedbackUpdateRecordsPerJob(feedbackRows, false, true);
    const jobIds = Array.from(feedbackUpdateRecords.keys());
    Promise.all(
      jobIds.map(async (currentJobId) => {
        const currentJobFeedbackRecords = feedbackUpdateRecords.get(currentJobId);
        if (currentJobFeedbackRecords && currentJobFeedbackRecords.length > 0) {
          await clearBucketFeedback(new FeedbackUpdateRequest(currentJobId, currentJobFeedbackRecords));
        }
      })
    ).finally(() => {
      setIsLoading(false);
      feedbackBulkContextValue.setPrepareClear(false);
      feedbackBulkContextValue.triggerBucketsReload();
      feedbackBulkContextValue.handleCancelBulkFeedback();
    });
  }, [setIsLoading, getSelectedBucketsRows, getFeedbackUpdateRecordsPerJob, clearBucketFeedback, feedbackBulkContextValue]);

  const handleSaveBulkFeedback = useCallback(() => {
    if (prepareClear) {
      return saveLabelsClear();
    }

    if (prepareConfirm) {
      return saveLabelsConfirm();
    }

    if (typeof feedbackBulkContextValue.handleCustomOnSave === 'function') {
      return feedbackBulkContextValue.handleCustomOnSave();
    }

    if (!selectedLabel || !jobId) {
      return;
    }

    setIsLoading(true);
    const feedbackUpdateRecords: FeedbackUpdateRecord[] = feedbackBulkContextValue.selectedBuckets.map(
      (bucketId) => new FeedbackUpdateRecord(bucketId, selectedLabel)
    );

    updateBucketFeedback(new FeedbackUpdateRequest(jobId, feedbackUpdateRecords)).finally(() => {
      setIsLoading(false);
      feedbackBulkContextValue.handleCancelBulkFeedback();
    });
  }, [
    feedbackBulkContextValue,
    jobId,
    selectedLabel,
    updateBucketFeedback,
    prepareClear,
    prepareConfirm,
    saveLabelsClear,
    saveLabelsConfirm
  ]);

  const value = useMemo(
    () => ({
      isReadOnly: false,
      selectedBucketsCount: feedbackBulkContextValue.selectedBuckets.length,
      editLabelsCount: 0,
      handleSaveBulkFeedback,
      isLoading,
      canConfirmLabels,
      canClearLabels,
      handleLabelsClear,
      handleLabelsConfirm,
      ..._.omit(feedbackBulkContextValue, 'selectedBuckets')
    }),
    [
      feedbackBulkContextValue,
      handleSaveBulkFeedback,
      canClearLabels,
      canConfirmLabels,
      handleLabelsClear,
      handleLabelsConfirm,
      isLoading
    ]
  );

  return <FeedbackBulkContext.Provider value={value}>{children}</FeedbackBulkContext.Provider>;
}

export default FeedbackBulkContextProvider;
