import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { FeedbackAdvancedFilterRecord } from './FeedbackAdvancedFilterRecord';
import { FeedbackQuickFilters } from './FeedbackQuickFilters';

@JsonObject('FeedbackFiltersValuesRequest')
export class FeedbackFiltersValuesRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('field_name', String)
  field_name?: string;

  @JsonProperty('start_date', String)
  start_date?: string | null;

  @JsonProperty('end_date', String)
  end_date?: string | null;

  @JsonProperty('exclude_processed', Boolean)
  exclude_processed?: boolean;

  @JsonProperty('include_processed', Boolean)
  include_processed?: boolean;

  @JsonProperty('filters', [FeedbackAdvancedFilterRecord])
  filters?: FeedbackAdvancedFilterRecord[] = [];

  @JsonProperty('quick_filters', FeedbackQuickFilters)
  quick_filters?: FeedbackQuickFilters = new FeedbackQuickFilters();

  @JsonProperty('decision_filters', [String])
  decision_filters?: string[] = [];

  @JsonProperty('consensus_filters', [String])
  consensus_filters?: string[] = [];

  constructor(
    job_id: number,
    field_name: string,
    start_date: string | null,
    end_date: string | null,
    exclude_processed: boolean,
    include_processed: boolean,
    filters: FeedbackAdvancedFilterRecord[],
    quick_filters: FeedbackQuickFilters,
    decision_filters: string[],
    consensus_filters: string[]
  ) {
    super();
    this.job_id = job_id;
    this.field_name = field_name;
    this.start_date = start_date;
    this.end_date = end_date;
    this.exclude_processed = exclude_processed;
    this.include_processed = include_processed;
    this.filters = filters;
    this.quick_filters = quick_filters;
    this.decision_filters = decision_filters;
    this.consensus_filters = consensus_filters;
  }
}
