import FeedbackContextProvider from './FeedbackContext/FeedbackContextProvider';
import FeedbackPageContent from './FeedbackPageContent';

function Feedback() {
  return (
    <FeedbackContextProvider>
      <FeedbackPageContent />
    </FeedbackContextProvider>
  );
}

export default Feedback;
