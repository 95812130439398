import { ArcannaBreadcrumbs } from '../../../../../../shared/components';
import { useJobNavigate } from '../../../../../../shared/hooks/useJobNavigate';
import { useTranslation } from 'react-i18next';
import { useJobInfo } from '../../../../../../../data-access';

export default function JobOverviewBreadcrumb({ jobId }: { jobId: number }) {
  const { navigateToJobs } = useJobNavigate();
  const { t } = useTranslation(['job', 'homepage']);

  const jobInfoQuery = useJobInfo(+jobId);

  return (
    <ArcannaBreadcrumbs
      items={[
        {
          title: t('homepage:AIJobsCardTitle'),
          onClick: navigateToJobs
        },
        {
          title: jobInfoQuery.data?.info?.title ?? ''
        },
        {
          title: t('job:navigation.overview')
        }
      ]}
      jobStatusToggle={{ jobId }}
    />
  );
}
